import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { SubSink } from 'subsink';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { EmploymentHistory } from 'src/app/registration/shared/models/employment-history.model';
import { Employment } from 'src/app/shared/models/employment.model';
import { environment } from 'src/environments/environment.dev';
import { ErrorStateMatcher } from '@angular/material/core';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const TODATE_GREATER_THAN_EQUAL_STARTDATE = 'From Date is greater than or equal to To Date.';

export class DatePickerErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (control.valid) {
      const invalidCtrl = !!(control && control.invalid);
      const invalidParent = !!(control && control.parent && control.parent.invalid);
      return invalidCtrl || invalidParent;
    } else {
      return false;
    }
  }
}

@Component({
  selector: 'app-employment-history',
  templateUrl: './employment-history.component.html',
  styleUrls: ['./employment-history.component.scss']
})
export class EmploymentHistoryComponent implements OnInit, OnDestroy {
  isAddingOrEditing = false;
  employmentHistory: EmploymentHistory = new EmploymentHistory();
  form: FormGroup;
  noExperienceForm: FormGroup;
  loading: boolean;
  errorMessage: string;
  updateEmploymentSubscription: any;
  isUpdating: boolean;
  maxDate: Date = new Date();
  matcher = new DatePickerErrorStateMatcher();

  private subs = new SubSink();
  private noExperienceEmployment: Employment;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe
  ) {
    this.loading = false;
    this.errorMessage = undefined;
    this.form = this.createForm();
    this.noExperienceForm = this.createNoExperienceForm();
  }

  ngOnInit() {
    this.employmentHistory.employmentList = [];
    this.subs.add(
      this.registrationService.selectEmploymentHistory().subscribe((employmentHistory: EmploymentHistory) => {
        this.employmentHistory.employmentList = [];
        this.noExperienceEmployment = undefined;

        this.employmentHistory.employmentList = employmentHistory.employmentList.filter((employment: Employment) => {
          this.noExperienceEmployment = employment && employment.noExperience ? employment : undefined;
          return employment && !employment.noExperience;
        });

        if (this.noExperienceEmployment) {
          this.populateNoExperienceForm(this.noExperienceEmployment);
        }
      })
    );
    this.loadDriver();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onAddNewEmployment() {
    this.isAddingOrEditing = true;
  }

  onDeleteEmploymentPressed($event: Employment) {
    this.subs.add(
      this.registrationService.deleteEmployment($event).subscribe(
        () => {
          this.errorMessage = undefined;
          this.loading = false;
          this.isAddingOrEditing = false;
          this.loadDriver();
        },
        (error: string) => {
          this.errorMessage = environment.errorMessage;
          this.loading = false;
        }
      )
    );
  }

  onUpdateEmploymentPressed($event) {
    this.isAddingOrEditing = true;
    if ($event) {
      this.populateForm($event);
    }
  }

  onEmploymentUpdated($event) {}

  onCancelPressed() {
    this.isAddingOrEditing = false;
  }

  onNavigateToProfile() {
    this.registrationService.navigateToProfile();
  }

  onSubmit() {
    if (!this.form.valid && !this.noExperienceForm.valid) {
      return;
    }
    /*  console.log(this.noExperienceEmployment);
    console.log(this.noExperienceForm.value.noExperience); */
    /*   if (!this.noExperienceEmployment && !this.noExperienceForm.value.noExperience || this.noExperienceEmployment && this.noExperienceForm.value.noExperience){
      console.log("nothing changed");
      return;
    } */

    this.loading = true;

    let model = this.noExperienceForm.dirty ? this.prepareNoExperienceSaveModel() : undefined;
    model = !model && this.form.dirty ? this.prepareSaveModel() : model;

    if (model && model.id && this.removedNoExperience(this.noExperienceEmployment, model)) {
      this.subs.add(
        this.registrationService.deleteEmployment(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.resetNoExperienceForm();
            this.loading = false;
            this.loadDriver();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    } else if (model && model.id) {
      this.subs.add(
        this.registrationService.updateEmployment(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.resetNoExperienceForm();
            this.loading = false;
            this.isAddingOrEditing = false;
            this.isUpdating = false;
            this.loadDriver();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    } else if (model) {
      this.onEmploymentAdded(model);
    } else {
      console.log(`Error: There is no data to save.`);
    }

    this.loading = false;
    this.form.reset();
  }

  get employer() {
    return this.form.get('employer');
  }
  get fromDate() {
    return this.form.get('fromDate');
  }
  get toDate() {
    return this.form.get('toDate');
  }
  get position() {
    return this.form.get('position');
  }

  getEmployerNameErrorMessage() {
    return this.employer.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getFromDateErrorMessage() {
    return this.fromDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getToDateErrorMessage() {
    return this.toDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getDateValidationErrorMessage() {
    return TODATE_GREATER_THAN_EQUAL_STARTDATE;
  }

  getPositionErrorMessage() {
    return this.position.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  private removedNoExperience(noExperienceEmployment: Employment, model: Employment): boolean {
    return noExperienceEmployment && noExperienceEmployment.noExperience && model && !model.noExperience;
  }

  private createForm(): FormGroup {
    const form = this.fb.group(
      {
        employer: ['', Validators.required],
        fromDate: ['', Validators.required],
        toDate: ['', Validators.required],
        position: ['', Validators.required],
        id: ['']
      },
      { validator: this.validateToAndFromDate }
    );
    return form;
  }

  private validateToAndFromDate(group: FormGroup) {
    if (group.controls.toDate.value <= group.controls.fromDate.value) {
      return { toDateLessThanFromDate: true };
    }
    return null;
  }

  private prepareSaveModel(): Employment {
    const formModel = this.form.value;

    const model = {
      employer: formModel.employer as string,
      fromDate: this.datePipe.transform(formModel.fromDate, 'yyyyMMdd') as string,
      toDate: this.datePipe.transform(formModel.toDate, 'yyyyMMdd') as string,
      position: formModel.position as string,
      id: formModel.id as string
    };

    return model as Employment;
  }

  private populateForm(employment: Employment): void {
    this.isUpdating = true;

    this.form.setValue({
      employer: employment.employer,
      fromDate: employment.fromDate,
      toDate: employment.toDate,
      position: employment.position,
      id: employment.id
    });
  }

  private onEmploymentAdded(model: Employment) {
    // TODO: Remove this logic when API ignores these fields.
    model.id = undefined;

    this.subs.add(
      this.registrationService.saveEmployment(model).subscribe(
        () => {
          this.errorMessage = undefined;
          this.resetNoExperienceForm(model);
          this.loading = false;
          this.isAddingOrEditing = false;
          this.loadDriver();
        },
        (error: any) => {
          this.errorMessage = error.message;
          this.loading = false;
        }
      )
    );
  }

  private createNoExperienceForm(): FormGroup {
    const form = this.fb.group({
      id: [''],
      noExperience: [false]
    });

    return form;
  }

  private populateNoExperienceForm(employment: Employment): void {
    this.noExperienceForm.setValue({
      id: employment.id,
      noExperience: employment.noExperience
    });
  }

  private resetNoExperienceForm(employment?: Employment): void {
    this.noExperienceForm.reset({
      id: employment ? employment.id : '',
      noExperience: employment ? employment.noExperience : false
    });
  }

  private prepareNoExperienceSaveModel(): Employment {
    const formModel = this.noExperienceForm.value;

    const model = {
      id: formModel.id as string,
      noExperience: formModel.noExperience as boolean
    } as Employment;

    return model;
  }

  private loadDriver(): void {
    this.subs.add(this.registrationService.loadDriver().subscribe());
  }
}
