<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
    <div class="header-navigation-back">
      <mat-icon (click)="onNavigateToProfile();">arrow_back</mat-icon>
      <span fxFlex class="title">Preferences</span>
      <button mat-button
              [disabled]="!form.dirty || !form.valid && !loading"
              class="header-action-button"
              (click)="onSubmit();">Save</button>
    </div>
    <div class="content">
      <div fxLayout="column" fxLayoutAlign="center stretch" class="component-container">
        <mat-error *ngIf="errorMessage" class="error">
          {{errorMessage}}
        </mat-error>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
          <div fxLayout="column">
            <div class="sectionHeading">
              <label class="margin-right-x2">I am willing to pull</label>
            </div>
            <div class="padding-bottom-x1" fxLayoutAlign="end" fxLayout="row">
              <div class="full-width">

                <mat-checkbox class="block" (change)="onAnyChecked($event);" labelPosition="after">Any</mat-checkbox>
                <mat-checkbox class="block" formControlName="hhg" labelPosition="after">HHF</mat-checkbox>
                <mat-checkbox class="block" formControlName="tanker" labelPosition="after">Tanker</mat-checkbox>
                <mat-checkbox class="block" formControlName="van" labelPosition="after">Van</mat-checkbox>
                <mat-checkbox class="block" formControlName="reefer" labelPosition="after">Reefer</mat-checkbox>
                <mat-checkbox class="block" formControlName="carHauling" labelPosition="after">Car Hauling</mat-checkbox>
                <mat-checkbox class="block" formControlName="specialized" labelPosition="after">Specialized</mat-checkbox>
                <mat-checkbox class="block" formControlName="hazmat" labelPosition="after">Hazmat</mat-checkbox>
                <mat-checkbox class="block" formControlName="flatBed" labelPosition="after">Flatbed</mat-checkbox>
                <mat-checkbox class="block" formControlName="lcv" labelPosition="after">LCV</mat-checkbox>
              </div>
            </div>
            <div class="sectionHeading">
                <label class="margin-right-x2">Preferred days to work</label>
              </div>

            <div fxLayoutAlign="start" fxLayout="column">
              <div class="full-width">
                <!-- <div>
                  <label class="margin-right-x2 sectionHeading">Weekdays</label>
                </div> -->
                <mat-checkbox class="block" (change)="onAllChecked($event);" labelPosition="after">Weekdays</mat-checkbox>
                <mat-checkbox class="block" formControlName="monday" labelPosition="after">Monday</mat-checkbox>
                <mat-checkbox class="block" formControlName="tuesday" labelPosition="after">Tuesday</mat-checkbox>
                <mat-checkbox class="block" formControlName="wednesday" labelPosition="after">Wednesday</mat-checkbox>
                <mat-checkbox class="block" formControlName="thursday" labelPosition="after">Thursday</mat-checkbox>
                <mat-checkbox class="block" formControlName="friday" labelPosition="after">Friday</mat-checkbox>
              </div>
              <div class="full-width">
                <div class="weekends">
                  <label class="margin-right-x2 sectionHeading">Weekends</label>

                </div>
                <mat-checkbox class="block" (change)="onBothChecked($event);" labelPosition="after">Both</mat-checkbox>
                <mat-checkbox class="block" formControlName="saturday" labelPosition="after">Saturday</mat-checkbox>
                <mat-checkbox class="block" formControlName="sunday" labelPosition="after">Sunday</mat-checkbox>
              </div>
            </div>
          </div>
        </form>
      </div>
      <br />
    </div>
  </div>
