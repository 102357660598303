import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-truck-type',
  templateUrl: './truck-type.component.html',
  styleUrls: ['./truck-type.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class TruckTypeComponent implements OnInit {
  @Input() group: FormGroup;
  childForm: any;
  maxDate: Date = new Date();

  constructor() {
  }

  ngOnInit(): void {
  }
}
