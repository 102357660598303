<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family full-height">
  <div class="header-navigation" fxLayout fxLayoutAlign="center">
    <span>New Message</span>
  </div>
  <div fxFlex fxLayout="column" class="margin-sides margin-bottom-x3">
    <div fxFlex class="message-container">
      <p>
        Hey,<br /><br />
        FleetOperate Delivery is a simple, secure and easy way to provide delivery services to shippers while earning
        much more than the regular delivery apps. <br /><br />
        It gives you the best of both worlds:
      </p>
      <ol>
        <li>Access NOT limited to delivery of a certain type of commodity</li>
        <li>Complete control of your schedule</li>
      </ol>
      <span>Join me at</span><br /><br />
      <a href="https://driver.delivery.fleetoperate.com/#/signin" class="link-color"
        >https://driver.delivery.fleetoperate.com/#/signin</a
      >
    </div>
    <div class="full-width padding-top-x1">
      <button class="full-width margin-bottom" mat-raised-button color="primary" type="submit" (click)="onSend()">
        Send
      </button>
    </div>
  </div>
</div>
