<div class="header-navigation-back">
    <mat-icon (click)="onNavigateToProfile()">arrow_back</mat-icon>
    <span fxFlex class="title">Personal Info</span>
    <button mat-button [disabled]="!form.dirty || !form.valid || loading" class="header-action-button"
        (click)="onSubmit()">Save</button>
</div>
<div class="content padding-sides-1x">
    <div fxLayout="column" fxLayoutAlign="center stretch" class="component-container padding-top-md">
        <mat-error *ngIf="errorMessage" class="error">
            {{errorMessage}}
        </mat-error>
        <div>
            <mat-label><u><b>{{name}}</b></u></mat-label>
            <mat-label class="driverId"><b>{{driverID}}</b></mat-label>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
            <mat-form-field>
                <mat-label>License Type</mat-label>
                <mat-select formControlName="licenseType">
                    <mat-option value="Class A">Class A</mat-option>
                    <mat-option value="Class B">Class B</mat-option>
                    <mat-option value="Class 1">Class 1</mat-option>
                    <mat-option value="Class 2">Class 2</mat-option>
                    <mat-option value="Class 3">Class 3</mat-option>
                    <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error *ngIf="licenseType.touched && licenseType.invalid">
                    {{getLicenseTypeErrorMessage()}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Issuing Authority" formControlName="issuingAuthority" required>
                <mat-error *ngIf="issuingAuthority.touched && issuingAuthority.invalid">
                    {{getIssuingAuthorityErrorMessage()}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Date Of Birth" [matDatepicker]="dateOfBirth" formControlName="dateOfBirth"
                    [max]="maxDate" required>
                <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #dateOfBirth></mat-datepicker>
                <mat-error *ngIf="dateOfBirth.touched && dateOfBirth.invalid">
                    {{getDateOfBirthErrorMessage()}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                    <mat-option value="male">Male</mat-option>
                    <mat-option value="female">Female</mat-option>
                </mat-select>
                <mat-error *ngIf="gender.touched && gender.invalid">
                    {{getGenderErrorMessage()}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Address" #addressText formControlName="address" required>
                <mat-error *ngIf="address.touched && address.invalid">
                    {{getAddressErrorMessage()}}
                </mat-error>
            </mat-form-field>
            <mat-label>Email</mat-label>
            <mat-label><b>{{email}}</b></mat-label>
            <br />
            <mat-label>Phone</mat-label>
            <mat-label><b>{{phone}}</b></mat-label>
        </form>
    </div>
</div>