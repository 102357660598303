import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SubSink } from 'subsink';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { TruckType } from 'src/app/registration/shared/models/truck-type.model';
import { environment } from 'src/environments/environment.dev';

const STRAIGHT_TRUCK = 'straightTruck';
const TRACTOR_AND_SEMI_TRAILER = 'tractorAndSemiTrailer';
const TRACTOR_TWO_TRAILERS = 'tractorTwoTrailers';
const OTHERS = 'others';

const RequiredFieldMessage = 'You must enter a value';
const NumbersOnlyMessage = 'Must use numbers only';
const MinimumFieldMessage = (value: number) => `Must be atleast ${value}`;
const MaximumFieldMessage = (value: number) => `Must be atmost ${value}`;

@Component({
  selector: 'app-driving-experience',
  templateUrl: './driving-experience.component.html',
  styleUrls: ['./driving-experience.component.scss']
})
export class DrivingExperienceComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  maxDate: Date = new Date();
  showStraightTruck = true;
  showTractor = false;
  showTractorTwo = false;
  showOthers = false;

  private subs = new SubSink();

  constructor(private readonly fb: FormBuilder,
              private readonly registrationService: RegistrationService,
              private readonly store: Store) {
    this.form = this.createForm();
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit() {
    this.subs.add(
      this.registrationService.loadDriver().subscribe(res => {
        if (res.experience) {
          this.populateForm(res.experience);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    if (!model.id) {
      this.subs.add(this.registrationService.saveDrivingExperience(model).subscribe(() => {
        this.errorMessage = undefined;
        this.loading = false;
        this.onNavigateToProfile();
      }, (error: string) => {
        this.errorMessage = environment.errorMessage;
        this.loading = false;
      }));
    } else {
      this.subs.add(this.registrationService.updateDrivingExperience(model).subscribe(() => {
        this.errorMessage = undefined;
        this.loading = false;
        this.onNavigateToProfile();
      }, (error: string) => {
        this.errorMessage = environment.errorMessage;
        this.loading = false;
      }));
    }
  }

  get drivingGraduate() { return this.form.get('drivingGraduate'); }
  get companyDriver() { return this.form.get('companyDriver'); }
  get studentDriver() { return this.form.get('studentDriver'); }
  get yearsOfExperience() { return this.form.get('yearsOfExperience'); }

  getYearsOfExperienceErrorMessage() {
    const errors = this.yearsOfExperience.errors;

    return errors.required ? RequiredFieldMessage :
      errors.min ? MinimumFieldMessage(errors.min.min) :
      errors.max ? MaximumFieldMessage(errors.max.max) :
      errors.pattern ? NumbersOnlyMessage : '';
  }

  onNavigateToProfile() {
    this.registrationService.navigateToProfile();
  }

  onToggleStraightTruck() {
    this.showStraightTruck = true;
    this.showTractor = false;
    this.showTractorTwo = false;
    this.showOthers = false;
  }

  onToggleTractor() {
    this.showStraightTruck = false;
    this.showTractor = true;
    this.showTractorTwo = false;
    this.showOthers = false;
  }

  onToggleTractorTwo() {
    this.showStraightTruck = false;
    this.showTractor = false;
    this.showTractorTwo = true;
    this.showOthers = false;
  }

  onToggleOthers() {
    this.showStraightTruck = false;
    this.showTractor = false;
    this.showTractorTwo = false;
    this.showOthers = true;
  }

  private prepareSaveModel(): DrivingExperience {
    const formModel = this.form.value;

    const model = {
      drivingGraduate: formModel.drivingGraduate as boolean,
      companyDriver: formModel.companyDriver as boolean,
      studentDriver: formModel.studentDriver as boolean,
      ownerOperator: formModel.ownerOperator as boolean,
      yearsOfExperience: formModel.yearsOfExperience as number,
      truckTypes: this.getTruckTypes(formModel) as TruckType[],
      id: formModel.id || undefined,
    };

    return model as DrivingExperience;
  }

  private populateForm(model: DrivingExperience): void {
    this.form.patchValue({
      drivingGraduate: model.drivingGraduate,
      companyDriver: model.companyDriver,
      studentDriver: model.studentDriver,
      ownerOperator: model.ownerOperator,
      yearsOfExperience: model.yearsOfExperience,
      id: model.id,
      straightTruck: this.setTruckType(model.truckTypes.find((truckType) => truckType.type === STRAIGHT_TRUCK)),
      tractorAndSemiTrailer: this.setTruckType(model.truckTypes.find((truckType) => truckType.type === TRACTOR_AND_SEMI_TRAILER)),
      tractorTwoTrailers: this.setTruckType(model.truckTypes.find((truckType) => truckType.type === TRACTOR_TWO_TRAILERS)),
      others: this.setTruckType(model.truckTypes.find((truckType) => truckType.type === OTHERS))
    });
  }

  private setTruckType(truckType: any): any {
    const milesDriven = truckType &&
                        (truckType.milesDriven !== undefined && truckType.milesDriven !== null) ? truckType.milesDriven : undefined;
    return {
      flat: truckType && truckType.flat || false,
      tank: truckType && truckType.tank || false,
      van: truckType && truckType.van || false,
      milesDriven,
      other: truckType && truckType.other || '',
      fromDate: truckType && truckType.fromDate || '',
      toDate: truckType && truckType.toDate || '',
      type: truckType && truckType.type || '',
      displayType: truckType && truckType.displayType || '',
    } as TruckType;
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      drivingGraduate: [false],
      companyDriver: [false],
      studentDriver: [false],
      ownerOperator: [false],
      yearsOfExperience: [undefined, [Validators.required, Validators.max(50), Validators.min(1)]],
      id: [],
      straightTruck: this.createTruckFormGroup(),
      tractorAndSemiTrailer: this.createTruckFormGroup(),
      tractorTwoTrailers: this.createTruckFormGroup(),
      others: this.createTruckFormGroup()
    });

    return form;
  }

  private createTruckFormGroup(): FormGroup {
    return new FormGroup({
      flat: new FormControl(false),
      tank: new FormControl(false),
      van: new FormControl(false),
      milesDriven: new FormControl(),
      other: new FormControl(''),
      fromDate:  new FormControl(''),
      toDate:  new FormControl(''),
      type: new FormControl(''),
      displayType: new FormControl(''),
    });
  }

  private getTruckTypes(formModel): TruckType[] {
    const straightTruckTruckType = {
      flat: formModel.straightTruck.flat as boolean,
      tank: formModel.straightTruck.tank as boolean,
      van: formModel.straightTruck.van as boolean,
      milesDriven: formModel.straightTruck.milesDriven as number,
      other: formModel.straightTruck.other as string,
      fromDate: formModel.straightTruck.fromDate as string,
      toDate: formModel.straightTruck.toDate as string,
      type: STRAIGHT_TRUCK,
      displayType: 'Straight Truck',
    } as TruckType;

    const tractorAndSemiTrailerTruckType = {
      flat: formModel.tractorAndSemiTrailer.flat as boolean,
      tank: formModel.tractorAndSemiTrailer.tank as boolean,
      van: formModel.tractorAndSemiTrailer.van as boolean,
      milesDriven: formModel.tractorAndSemiTrailer.milesDriven as number,
      other: formModel.tractorAndSemiTrailer.other as string,
      fromDate: formModel.tractorAndSemiTrailer.fromDate as string,
      toDate: formModel.tractorAndSemiTrailer.toDate as string,
      type: TRACTOR_AND_SEMI_TRAILER,
      displayType: 'Tractor and Semi-Trailer',
    } as TruckType;

    const tractorTwoTrailersTruckType = {
      flat: formModel.tractorTwoTrailers.flat as boolean,
      tank: formModel.tractorTwoTrailers.tank as boolean,
      van: formModel.tractorTwoTrailers.van as boolean,
      milesDriven: formModel.tractorTwoTrailers.milesDriven as number,
      other: formModel.tractorTwoTrailers.other as string,
      fromDate: formModel.tractorTwoTrailers.fromDate as string,
      toDate: formModel.tractorTwoTrailers.toDate as string,
      type: TRACTOR_TWO_TRAILERS,
      displayType: 'Tractor Two Trailers',
    } as TruckType;

    const othersTruckType = {
      flat: formModel.others.flat as boolean,
      tank: formModel.others.tank as boolean,
      van: formModel.others.van as boolean,
      milesDriven: formModel.others.milesDriven as number,
      other: formModel.others.other as string,
      fromDate: formModel.others.fromDate as string,
      toDate: formModel.others.toDate as string,
      type: OTHERS,
      displayType: 'Others',
    } as TruckType;

    return [straightTruckTruckType, tractorAndSemiTrailerTruckType, tractorTwoTrailersTruckType, othersTruckType];
  }
}
