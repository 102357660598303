import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PROFILE } from 'src/app/shared/routes';

@Component({
  selector: 'app-sign-up-completed',
  templateUrl: './sign-up-completed.component.html',
  styleUrls: ['./sign-up-completed.component.scss']
})
export class SignUpCompletedComponent implements OnInit {
  message: string;

  constructor(private readonly router: Router) {
    this.message = `Hey, thanks for signing up to become a FleetOperate driver.
     Complete your profile so that you can hit the road and start earning cash!`;
  }

  ngOnInit() {
  }

  onCompleteProfile(): void {
    this.router.navigate([`/${ROUTE_PROFILE}`]);
  }
}
