import { Component, OnInit, OnDestroy } from '@angular/core';
import { Action } from 'src/app/loads-shared/shipment-data/models/action';
import { RegistrationService } from 'src/app/registration/shared/registration.service';

@Component({
  selector: 'app-license-allset',
  templateUrl: './license-allset.component.html',
  styleUrls: ['./license-allset.component.scss']
})
export class LicenseAllsetComponent implements OnInit {
  actions: Action[];
  enabled = true;

  constructor(private readonly registrationService: RegistrationService) {
    this.actions = [];
  }

  ngOnInit() {
    this.actions.push(this.nagivateActionToProfile());
  }

  navigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  private nagivateActionToProfile(): Action {
    return {
      name: 'OK',
      action: (data: any) => this.navigateToProfile(),
      disabled: !this.enabled,
      color: 'take_picture'
    } as Action;
  }
}
