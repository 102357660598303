import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
const ɵ0 = environment.apiUrl, ɵ1 = environment;
export class AppModule {
    constructor(iconRegistry, sanitizer) {
        iconRegistry.addSvgIcon('car', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/car.svg'));
        iconRegistry.addSvgIcon('alert', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert.svg'));
        iconRegistry.addSvgIcon('alert-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert-circle.svg'));
        iconRegistry.addSvgIcon('check-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-circle.svg'));
    }
}
export { ɵ0, ɵ1 };
