import { Router } from '@angular/router';
import { FleetoperateApiService } from '../shared/services/fleetoperate-api.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../core/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../shared/services/fleetoperate-api.service";
import * as i3 from "../core/services/auth.service";
const PATH_FORGOT_PASSWORD = '/auth/forgotpassword';
const PATH_CONFIRM_FORGOT_PASSWORD = '/auth/confirmforgotpassword';
export class ForgotPasswordService {
    constructor(router, api, authService) {
        this.router = router;
        this.api = api;
        this.authService = authService;
        this.apiUrl = environment.apiUrl;
    }
    sendForgotPasswordNotice(model) {
        return this.api.post(this.apiUrl + PATH_FORGOT_PASSWORD, model);
    }
    changePassword(model) {
        return this.api.post(this.apiUrl + PATH_CONFIRM_FORGOT_PASSWORD, model);
    }
}
ForgotPasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ForgotPasswordService_Factory() { return new ForgotPasswordService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.FleetoperateApiService), i0.ɵɵinject(i3.AuthService)); }, token: ForgotPasswordService, providedIn: "root" });
