import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_LOADS } from 'src/app/shared/routes';

const PATH_DRIVER_DASHBOARD = '';
const PATH_PROFILE = 'profile';
const PATH_PROFILE_PICTURE = 'profilePicture';
const PATH_NOTIFICATIONS = 'notifications';
const PATH_ACCOUNT_SETTINGS = 'accountSettings';
const PATH_TELL_FRIEND = 'tellFriend';
const PATH_DRIVER_HISTORY = 'driverHistory';

@Injectable({
  providedIn: 'root'
})
export class FrameNavigationService {

  constructor(private readonly router: Router) { }

  navigateToDriverDashboard(): Promise<boolean> {
    return this.router.navigate([PATH_DRIVER_DASHBOARD]);
  }

  navigateToProfile(): Promise<boolean>  {
    return this.router.navigate([PATH_PROFILE]);
  }

  navigateToProfilePicture(): Promise<boolean> {
    return this.router.navigate([PATH_PROFILE_PICTURE]);
  }

  navigateToLoads(): Promise<boolean>  {
    return this.router.navigate([ROUTE_LOADS]);
  }

  navigateToNotifications(): Promise<boolean>  {
    return this.router.navigate([PATH_NOTIFICATIONS]);
  }

  navigateToAccountSettings(): Promise<boolean>  {
    return this.router.navigate([PATH_ACCOUNT_SETTINGS]);
  }

  navigateToTellFriend(): Promise<boolean>  {
    return this.router.navigate([PATH_TELL_FRIEND]);
  }

  navigateToDriverHistory(): Promise<boolean>  {
    return this.router.navigate([PATH_DRIVER_HISTORY]);
  }
}
