/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/material/form-field";
import * as i7 from "@angular/common";
import * as i8 from "./status-banner.component";
var styles_StatusBannerComponent = [i0.styles];
var RenderType_StatusBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusBannerComponent, data: {} });
export { RenderType_StatusBannerComponent as RenderType_StatusBannerComponent };
function View_StatusBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "margin-right-sm mat-icon notranslate"], ["fxFlex", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null), i1.ɵdid(2, 671744, null, 0, i4.DefaultFlexDirective, [i1.ElementRef, i5.StyleUtils, i5.LAYOUT_CONFIG, i4.FlexStyleBuilder, i5.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.status.svgIcon; _ck(_v, 1, 0, currVal_2); var currVal_3 = ""; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_StatusBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center stretch"]], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "mat-label", [["class", "margin-bottom-sm margin-top-sm"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i6.MatLabel, [], null, null), i1.ɵdid(5, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusBannerComponent_1)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "row"; _ck(_v, 1, 0, currVal_1); var currVal_2 = "center stretch"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "center center"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.status.svgIcon; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "status-banner ", _co.status.color, ""); _ck(_v, 0, 0, currVal_0); var currVal_5 = _co.status.message; _ck(_v, 9, 0, currVal_5); }); }
export function View_StatusBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-banner", [], null, null, null, View_StatusBannerComponent_0, RenderType_StatusBannerComponent)), i1.ɵdid(1, 114688, null, 0, i8.StatusBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusBannerComponentNgFactory = i1.ɵccf("app-status-banner", i8.StatusBannerComponent, View_StatusBannerComponent_Host_0, { status: "status" }, {}, []);
export { StatusBannerComponentNgFactory as StatusBannerComponentNgFactory };
