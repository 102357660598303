<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
<div fxFlexAlign="center"
       fxLayout="column"
       fxFlex="none">
    <img class="logo padding-top-x4"
          src="/assets/img/fleetoperate_delivery_white_logo.png" width="300px">
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex class="padding-top-x2">
    <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
      {{errorMessage}}
    </mat-error>
    <form [formGroup]="form" #signinForm="ngForm" (ngSubmit)="onSubmit(signinForm)" fxFlex="none" fxLayout="column">
      <mat-form-field>
        <input type="email" matInput formControlName="email">
        <mat-placeholder>Email</mat-placeholder>
        <mat-error *ngIf="email.touched && email.invalid">
          {{getEmailErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-hint align="start">at least 8 characters</mat-hint>
        <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
        <mat-placeholder>Password</mat-placeholder>
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="password.touched && password.invalid">
          {{getPasswordErrorMessage()}}
        </mat-error>
      </mat-form-field>

      <span fxLayout class="padding-top-x4 padding-bottom-x3">
        <a fxFlex [routerLink]="" (click)="onNavigateToForgotPassword()">Forgot password?</a>
      </span>

      <div class="full-width padding-top-x4">
        <button class="full-width margin-bottom"
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="form.pristine || !form.valid || loading">{{ "Sign In" | uppercase }}</button>
      </div>
    </form>
  </div>
  <div fxFlexAlign="center full-width" fxLayout="column" fxFlex="none">
    <div class="center full-width signInPageLink">
      <br />
      Don't have an account? <a [routerLink]="" (click)="onNavigateToSignUp()">Sign up</a>
    </div>
  </div>
</div>
