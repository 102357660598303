import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Action, State } from '@ngxs/store';
export class Navigate {
    constructor(payload, activeRoute) {
        this.payload = payload;
        this.activeRoute = activeRoute;
    }
}
Navigate.type = '[registrationRouter] navigate';
let RegistrationRouterState = class RegistrationRouterState {
    constructor(ngZone, router, activeRoute) {
        this.ngZone = ngZone;
        this.router = router;
        this.activeRoute = activeRoute;
    }
    changeRoute(context, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const path = action.payload;
            if (action.activeRoute) {
                yield this.ngZone.run(() => this.router.navigate([path], { relativeTo: action.activeRoute.parent })).then();
            }
            else {
                yield this.ngZone.run(() => this.router.navigate([path])).then();
            }
            context.setState(path);
        });
    }
};
tslib_1.__decorate([
    Action(Navigate),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Navigate]),
    tslib_1.__metadata("design:returntype", Promise)
], RegistrationRouterState.prototype, "changeRoute", null);
RegistrationRouterState = tslib_1.__decorate([
    State({
        name: 'registrationRouter',
        defaults: ''
    }),
    tslib_1.__metadata("design:paramtypes", [NgZone,
        Router,
        ActivatedRoute])
], RegistrationRouterState);
export { RegistrationRouterState };
