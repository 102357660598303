<div fxLayout="column">
  <div class="employment-info">
    <div class="action-icons">
      <mat-icon (click)="onUpdateEmp();">edit</mat-icon>
      <mat-icon (click)="onDeleteEmp();">delete</mat-icon>
    </div>
    <div class="full-width field-container">
      <span class="employer-value">{{model.employer}}</span>
    </div>
    <div class="full-width field-container">
      <span class="field-title">From&nbsp;</span>
      <span class="field-value">{{model.fromDate | date: 'dd-MM-yyyy'}}</span>
    <!-- </div>
    <div class="full-width field-container"> -->
      <span class="field-title">&nbsp;To&nbsp;</span>
      <span class="field-value">{{model.toDate | date: 'dd-MM-yyyy'}}</span>
    </div>
    <div class="full-width field-container">
      <span class="field-title">Position:&nbsp;</span>
      <span class="field-value">{{model.position}}</span>
    </div>
  </div>
</div>
