import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { IsLoadingModule } from '@service-work/is-loading';
import { DriverApiService } from './services/driver-api.service';
import { FleetoperateApiService } from './services/fleetoperate-api.service';
import { NumberOnlyDirective } from './directives/number-only/number-only.directive';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToastModule } from './toast/toast.module';

@NgModule({
  imports: [MaterialModule, IsLoadingModule, ToastModule],
  declarations: [NumberOnlyDirective],
  providers: [DriverApiService, FleetoperateApiService, CommonService],
  exports: [NumberOnlyDirective]
})
export class SharedModule { }
