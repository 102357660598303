import { LoggerService } from './logger.service';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { environment } from 'src/environments/environment';
import { throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/storage/storage.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/fleetoperate-api.service";
import * as i2 from "../../shared/storage/storage.service";
import * as i3 from "./logger.service";
const PATH_REFRESH_TOKEN = '/auth/token/refresh';
const KEY_ACCESS_TOKEN = 'access-token';
const KEY_USERNAME = 'username';
const tokenVerificationTolerance = 5;
export class AuthService {
    constructor(api, storageService, logger, appName) {
        this.api = api;
        this.storageService = storageService;
        this.logger = logger;
        this.appName = appName;
        this.apiUrl = environment.apiUrl;
        this.returnUrl = undefined;
        this.accessTokenKey = `${this.appName}-${KEY_ACCESS_TOKEN}`;
        this.usernameKey = `${this.appName}-${KEY_USERNAME}`;
        const accessToken = this.storageService.find(this.accessTokenKey);
        const username = this.storageService.find(this.usernameKey);
        if (accessToken && username) {
            this.token = accessToken;
            this.username = username;
        }
    }
    get driverID() {
        return this.token ? this.token.id : '';
    }
    isAuthenticated() {
        if (!this.verifyToken(this.token)) {
            return of(false);
        }
        if (this.verifyExpiration(this.token.expiration)) {
            return of(true);
        }
        return this.refreshToken(this.token, this.username).pipe(map((refreshedToken) => {
            if (this.verifyExpiration(refreshedToken.expiration)) {
                this.storageService.store(this.accessTokenKey, refreshedToken);
                this.storageService.store(this.usernameKey, this.username);
                this.token = refreshedToken;
                return true;
            }
            this.clearToken();
            return false;
        }));
    }
    authenticate(token, username) {
        if (!this.verifyToken(token)) {
            return false;
        }
        if (this.verifyExpiration(token.expiration)) {
            this.storageService.store(this.accessTokenKey, token);
            this.storageService.store(this.usernameKey, username);
            this.token = token;
            this.username = username;
            return true;
        }
        this.clearToken();
        return false;
    }
    unauthenticate() {
        this.clearToken();
    }
    clearReturnUrl() {
        this.returnUrl = undefined;
    }
    verifyToken(token) {
        if (!token || !token.accessToken || !token.expiration) {
            return false;
        }
        return true;
    }
    verifyExpiration(expiration) {
        const expirationDate = new Date(expiration * 1000);
        const now = new Date();
        now.setMinutes(now.getMinutes() + tokenVerificationTolerance);
        const valid = expirationDate >= now;
        return valid;
    }
    refreshToken(token, username) {
        const model = { username: username, refreshToken: token.refreshToken };
        // TODO: Need to verify the failure scenarios work. Does the user get taken to the login screen?
        return this.api.post(this.apiUrl + PATH_REFRESH_TOKEN, model).pipe(map((response) => {
            const tokenResponse = response.data;
            const validToken = this.authenticate(tokenResponse, model.username);
            validToken
                ? this.logger.log(`Refreshed token is valid`)
                : this.logger.log(`Refreshed token is invalid: ${tokenResponse}`);
            return tokenResponse;
        }), catchError(err => {
            this.logger.error('Caught error while refreshing token, will logout and rethrow error.', err);
            this.unauthenticate();
            return throwError(err);
        }));
    }
    clearToken() {
        this.storageService.remove(this.accessTokenKey);
        this.storageService.remove(this.usernameKey);
        this.token = undefined;
        this.username = undefined;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.FleetoperateApiService), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.LoggerService), i0.ɵɵinject("appName")); }, token: AuthService, providedIn: "root" });
