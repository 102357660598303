import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { WebcamImage } from 'ngx-webcam';
import { DocumentImage } from 'src/app/registration/shared/models/document-image.model';
import { Status } from 'src/app/shared/status-banner/models/status.model';

const ReviewPictureMessage = 'Review the back picture';

@Component({
  selector: 'app-license-back-image',
  templateUrl: './license-back-image.component.html',
  styleUrls: ['./license-back-image.component.scss']
})
export class LicenseBackImageComponent implements OnInit {
  @ViewChild('backImageInput', { static: false }) backImageInput: ElementRef<HTMLElement>;
  loading = false;
  backImage: any;
  previewImage: any;
  actions: Action[];
  status: Status;
  enabled = true;
  private uploadedImage: any;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly sanitizer: DomSanitizer,
    private readonly logger: LoggerService
  ) {
    this.actions = [];
  }

  ngOnInit() {
    const data = this.registrationService.getKYCVerificationData();
    if (data.documentBackImage && data.documentBackImage.imageAsDataUrl) {
      this.backImage = data.documentBackImage ? data.documentBackImage.imageAsDataUrl : undefined;
    }

    if (this.backImage) {
      this.reviewBackImage();
    } else {
      this.actions.push(this.createTakePictureAction());
    }
  }

  navigateToLicenseFrontmage(): void {
    this.registrationService.navigateToLicenseFrontmage();
  }

  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }
    this.backImageInput.nativeElement.nodeValue = '';
    const files = $event.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = event => {
      this.reviewBackImage();
      this.backImage = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.uploadedImage = files[0];
      this.loading = false;
    };
  }

  private onRetakeClicked(): void {
    this.backImage = undefined;
    this.actions = [];
    this.actions.push(this.createTakePictureAction());
  }

  private saveImage(): void {
    if (this.backImage) {
      const image = new WebcamImage(this.backImage, '', this.uploadedImage);
      const documentImage = {
        image
      } as DocumentImage;
      this.registrationService.saveDocumentBackImage(documentImage);
    }
  }

  private onBackImageInputClicked(): void {
    if (!this.backImageInput || !this.backImageInput.nativeElement) {
      this.logger.error('input file element not found');
    }
    this.backImageInput.nativeElement.click();
    this.loading = true;
  }

  private createTakePictureAction(): Action {
    return {
      name: 'TAKE PICTURE',
      action: (data: any) => this.onBackImageInputClicked(),
      disabled: !this.enabled,
      color: 'take_picture'
    } as Action;
  }

  private createRetakePictureAction(): Action {
    return {
      name: 'RETAKE',
      action: (data: any) => this.onRetakeClicked(),
      disabled: !this.enabled,
      color: 'retake_picture'
    } as Action;
  }

  private createConfirmAction(): Action {
    return {
      name: 'CONFIRM',
      action: (data: any) => this.saveImage(),
      disabled: !this.enabled,
      color: 'confirm_picture'
    } as Action;
  }

  private createReviewPictureMessage(): Status {
    return {
      message: ReviewPictureMessage,
      color: 'review_picture'
    } as Status;
  }

  private reviewBackImage(): void {
    this.actions = [];
    this.status = this.createReviewPictureMessage();
    this.actions.push(this.createRetakePictureAction());
    this.actions.push(this.createConfirmAction());
  }
}
