import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { SubSink } from 'subsink';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { EmploymentHistory } from 'src/app/registration/shared/models/employment-history.model';
import { Education } from 'src/app/shared/models/education.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import {
  RegistrationState,
  NotificationStateModel,
  DriverStateModel
} from 'src/app/registration/state/registration.state';
import { tap, map, onErrorResumeNext } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Notification } from 'src/app/shared/models/notification.model';
import {
  DRIVER_ABSTRACT,
  UPLOAD_STATUS,
  MEDICAL_CERTIFICATE,
  DRIVE_TEST,
  IDENTITY_CHECK,
  IDENTITY_CHECK_VERIFIED_STATUS,
  CRIMINALBACKGROUNDCHECK
} from 'src/app/shared/services/driver-api.service';
import { ProfileService } from '../profile.service';
const SKIP_LABEL = 'Skip';
const NEXT_LABEL = 'Next';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  employmentHistory: EmploymentHistory = new EmploymentHistory();
  education: Education = new Education();
  drivingExperience: DrivingExperience = new DrivingExperience();
  preferences: Preferences = new Preferences();
  profilePicture: ProfilePicture = new ProfilePicture();
  profileCompleted = false;
  personalInfoCompleted = false;
  nextButtonLabel: string = SKIP_LABEL;
  loading: boolean;
  errorMessage: string;

  private subs = new SubSink();
  driver: DriverStateModel;
  notifications: Notification[];
  identityCheck: Notification;
  criminalBackgroundCheck: Notification;
  driverAbstract: Notification;
  medicalExamination: Notification;
  driveTest: Notification;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService
  ) {
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit() {
    this.employmentHistory.employmentList = [];

    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(
          tap(() => (this.driver = this.store.selectSnapshot(RegistrationState.driver))),
          tap(() => (this.preferences = this.store.selectSnapshot(RegistrationState.preferences))),
          onErrorResumeNext(this.registrationService.loadProfilePicture()),
          tap(() => (this.profilePicture = this.store.selectSnapshot(RegistrationState.profilePicture))),
          tap(() => (this.personalInfoCompleted = this.isPersonalInfoCompleted(this.driver))),
          map(() => this.store.selectSnapshot(RegistrationState.notification))
        )
        .subscribe((notificationStateModel: NotificationStateModel) => {
          if (notificationStateModel && notificationStateModel.notifications) {
            this.notifications = [...notificationStateModel.notifications];
            this.identityCheck = this.profileService.findNotification(
              this.notifications,
              IDENTITY_CHECK,
              IDENTITY_CHECK_VERIFIED_STATUS
            );
            this.criminalBackgroundCheck = this.profileService.findNotification(
              this.notifications,
              CRIMINALBACKGROUNDCHECK,
              UPLOAD_STATUS
            );
          }
        })
    );

    this.profileCompleted = this.profileService.isProfileCompleted();
    this.nextButtonLabel = this.profileCompleted ? NEXT_LABEL : SKIP_LABEL;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onNavigateToIdentityCheck(): void {
    const kyc = this.profileService.checkIfNotificationTypeIsPresent(this.notifications, IDENTITY_CHECK);
    if (!kyc) {
      this.registrationService.navigateToIdentityCheck();
    } else {
      this.registrationService.navigateToDriverLicenseStatus();
    }
  }

  onNavigateToPreferences(): void {
    this.registrationService.navigateToPreferences(this.activatedRoute);
  }

  onNavigateToProfilePicture(): void {
    this.registrationService.navigateToProfilePicture(this.activatedRoute);
  }

  onNavigateToPersonalInformation(): void {
    this.registrationService.navigateToPersonalInformation(this.activatedRoute);
  }

  onNavigateToCriminalBackgroundCheck(): void {
    this.registrationService.nagivateToCriminalBackgroundCheck(this.activatedRoute);
  }

  isPersonalInfoCompleted(driver: DriverStateModel): boolean {
    return this.profileService.isPersonalInfoCompleted(driver);
  }
}
