<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-front-img">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateToProfile()">arrow_back</mat-icon>
    <span fxFlex class="title">Identity Check</span>
  </div>

  <app-status-banner *ngIf="frontImage" [status]="status"></app-status-banner>

  <div class="content" fxLayout="column">
    <span *ngIf="!frontImage" class="header-title center padding-top-lg"
      >Take picture of the <br /><span>FRONT</span><br />
      <span>of your Drivers License</span></span
    >
    <div *ngIf="!frontImage" fxFlex class="center padding-top-xlg">
      <img class="driver-license-img" src="assets/images/license-front.png" />
    </div>
    <div *ngIf="frontImage" fxFlex class="center padding-top-xlg img-taken-container">
      <img class="imagePreview" [src]="frontImage" />
    </div>
    <app-actions [actions]="actions"></app-actions>
  </div>
</div>

<div class="inputs-container">
  <input
    #frontImageInput
    type="file"
    name="image"
    capture="environment"
    accept="image/*"
    (click)="frontImageInput.value = null"
    (change)="onFileChange(frontImageInput)"
  />
</div>
