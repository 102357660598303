import { NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Action, State, StateContext } from '@ngxs/store';

export class Navigate {
    static readonly type = '[registrationRouter] navigate';
    constructor(public payload: string, public activeRoute?: ActivatedRoute) {}
}

@State<string>({
    name: 'registrationRouter',
    defaults: ''
})
export class RegistrationRouterState {
    constructor(private ngZone: NgZone,
                private router: Router,
                private readonly activeRoute: ActivatedRoute) {}

    @Action(Navigate)
    async changeRoute(context: StateContext<string>, action: Navigate) {
        const path = action.payload;
        if (action.activeRoute) {
          await this.ngZone.run(() => this.router.navigate([path], {relativeTo: action.activeRoute.parent})).then();
        } else {
          await this.ngZone.run(() => this.router.navigate([path])).then();
        }
        context.setState( path );
    }
}
