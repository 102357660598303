import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import * as moment from 'moment';
import { ClearKYCVerificationData, SetKYCVerificationData, UpdateKYCVerificationData, SaveDriverLicenseFrontImage, SaveDriverLicenseBackImage, SaveDriverLicenseSelfieImage, SaveEducation, SaveDrivingExperience, UpdateEducation, SetEducation, SetEmploymentHistory, SetPreferences, SetProfilePicture, SetDrivingExperience, SetDriver, UpdateDriver, ClearDriver, ClearRegistration, SetNotification } from './registration.actions';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
const driverStatsDefault = {
    shipmentsCompleted: undefined,
    shipmentUpcoming: undefined,
    shipmentResponseRate: undefined,
    shipmentEarnedThisMonth: undefined
};
const ɵ0 = undefined;
const driverDefault = {
    id: ɵ0,
    driverID: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    identityStatus: undefined,
    phoneNumber: undefined,
    birthday: undefined,
    gender: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    country: undefined,
    licenseType: undefined,
    issuingAuthority: undefined,
    createdDate: undefined,
    modifiedDate: undefined,
    stats: driverStatsDefault
};
const kycVerificationDataDefault = {
    documentFrontImage: undefined,
    documentBackImage: undefined,
    livePhoto: undefined,
    documentType: ''
};
const employmentHistoryDefault = {
    employmentList: [],
    noExperience: false
};
const notificationsDefault = {
    notifications: []
};
const ɵ1 = undefined;
const educationDefault = {
    id: ɵ1,
    highestGradeCompleted: undefined,
    lastSchoolAttended: undefined,
    certification: undefined,
    specialCourses: undefined
};
const ɵ2 = undefined;
const drivingExperienceDefault = {
    id: ɵ2,
    drivingGraduate: false,
    companyDriver: false,
    studentDriver: false,
    ownerOperator: false,
    yearsOfExperience: undefined,
    truckTypes: []
};
const preferencesDefault = {
    id: '',
    truckType: {
        any: false,
        hhg: false,
        reefer: false,
        hazmat: false,
        tanker: false,
        carHauling: false,
        flatBed: false,
        van: false,
        specialized: false,
        lcv: false
    },
    day: {
        weekdays: false,
        weekend: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    }
};
const ɵ3 = undefined;
const profilePictureDefault = {
    id: ɵ3,
    type: undefined,
    createdDate: undefined,
    fileUrl: undefined,
    status: undefined
};
let RegistrationState = class RegistrationState {
    constructor(driverApi) {
        this.driverApi = driverApi;
    }
    static driver(state) {
        return state.driver;
    }
    static notification(state) {
        return state.notification;
    }
    static kycVerificationData(state) {
        return state.kycVerificationData;
    }
    static employmentHistory(state) {
        return state.employmentHistory;
    }
    static education(state) {
        return state.education;
    }
    static drivingExperience(state) {
        return state.drivingExperience;
    }
    static preferences(state) {
        return state.preferences;
    }
    static profilePicture(state) {
        return state.profilePicture;
    }
    clearRegistration(ctx, action) {
        ctx.setState({
            driver: driverDefault,
            notification: notificationsDefault,
            kycVerificationData: kycVerificationDataDefault,
            employmentHistory: employmentHistoryDefault,
            education: educationDefault,
            drivingExperience: drivingExperienceDefault,
            preferences: preferencesDefault,
            profilePicture: profilePictureDefault
        });
    }
    setDriver(ctx, { payload }) {
        ctx.patchState({ driver: payload });
    }
    updateDriver(ctx, { payload }) {
        ctx.patchState({ driver: payload });
    }
    clearDriver(ctx, action) {
        ctx.patchState({ driver: undefined });
    }
    setNotification(ctx, { payload }) {
        ctx.patchState({ notification: { notifications: [...payload] } });
    }
    setKYCVerificationData(ctx, { payload }) {
        ctx.patchState({ kycVerificationData: payload });
    }
    updateKYCVerificationData(ctx, { payload }) {
        ctx.patchState({ kycVerificationData: payload });
    }
    clearKYCVerificationData(ctx, action) {
        ctx.patchState({ kycVerificationData: undefined });
    }
    saveDriverLicenseFrontImage(ctx, { payload }) {
        const data = ctx.getState().kycVerificationData;
        const kycVerificationData = {
            documentFrontImage: payload,
            documentBackImage: data.documentBackImage,
            livePhoto: data.livePhoto,
            documentType: ''
        };
        ctx.patchState({ kycVerificationData });
    }
    saveDriverLicenseBackImage(ctx, { payload }) {
        const data = ctx.getState().kycVerificationData;
        const kycVerificationData = {
            documentFrontImage: data.documentFrontImage,
            documentBackImage: payload,
            livePhoto: data.livePhoto,
            documentType: ''
        };
        ctx.patchState({ kycVerificationData });
    }
    saveDriverLicenseSelfieImage(ctx, { payload }) {
        const data = ctx.getState().kycVerificationData;
        const kycVerificationData = {
            documentFrontImage: data.documentFrontImage,
            documentBackImage: data.documentBackImage,
            livePhoto: payload,
            documentType: ''
        };
        ctx.patchState({ kycVerificationData });
    }
    setEducation(ctx, { payload }) {
        const education = payload ? payload : educationDefault;
        ctx.patchState({ education });
    }
    saveEducation(ctx, { payload }) {
        return this.driverApi.createEducation(payload).subscribe((result) => {
            const education = result.data;
            ctx.patchState({ education });
        });
    }
    updateEducation(ctx, { payload }) {
        return this.driverApi.updateEducation(payload).subscribe((result) => {
            const education = result.data;
            ctx.patchState({ education });
        });
    }
    setDrivingExperience(ctx, { payload }) {
        const drivingExperience = payload ? payload : drivingExperienceDefault;
        drivingExperience.truckTypes = drivingExperience.truckTypes || [];
        drivingExperience.truckTypes.forEach((element) => {
            element.fromDate = element.fromDate ? moment(element.fromDate, 'YYYYMMDD').toDate() : undefined;
            element.toDate = element.toDate ? moment(element.toDate, 'YYYYMMDD').toDate() : undefined;
        });
        ctx.patchState({ drivingExperience });
    }
    saveDrivingExperience(ctx, { payload }) {
        return this.driverApi.createDrivingExperience(payload).subscribe((result) => {
            const drivingExperience = result.data;
            ctx.patchState({ drivingExperience });
        });
    }
    setProfilePicture(ctx, { payload }) {
        const profilePicture = payload ? payload : profilePictureDefault;
        ctx.patchState({
            profilePicture
        });
    }
    setEmploymentHistory(ctx, { payload }) {
        let employmentList = payload;
        employmentList.forEach((element) => {
            element.fromDate = moment(element.fromDate, 'YYYYMMDD').toDate();
            element.toDate = moment(element.toDate, 'YYYYMMDD').toDate();
        });
        employmentList = employmentList.sort((employment1, employment2) => {
            return employment2.fromDate.getTime() - employment1.fromDate.getTime();
        });
        const newEmploymentHistory = { employmentList };
        ctx.patchState({
            employmentHistory: newEmploymentHistory
        });
    }
    setPreferences(ctx, { payload }) {
        const preferences = payload ? payload : preferencesDefault;
        ctx.patchState({ preferences });
    }
};
tslib_1.__decorate([
    Action(ClearRegistration),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ClearRegistration]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "clearRegistration", null);
tslib_1.__decorate([
    Action(SetDriver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetDriver]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setDriver", null);
tslib_1.__decorate([
    Action(UpdateDriver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateDriver]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "updateDriver", null);
tslib_1.__decorate([
    Action(ClearDriver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ClearDriver]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "clearDriver", null);
tslib_1.__decorate([
    Action(SetNotification),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetNotification]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setNotification", null);
tslib_1.__decorate([
    Action(SetKYCVerificationData),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetKYCVerificationData]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setKYCVerificationData", null);
tslib_1.__decorate([
    Action(UpdateKYCVerificationData),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateKYCVerificationData]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "updateKYCVerificationData", null);
tslib_1.__decorate([
    Action(ClearKYCVerificationData),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ClearKYCVerificationData]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "clearKYCVerificationData", null);
tslib_1.__decorate([
    Action(SaveDriverLicenseFrontImage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SaveDriverLicenseFrontImage]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "saveDriverLicenseFrontImage", null);
tslib_1.__decorate([
    Action(SaveDriverLicenseBackImage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SaveDriverLicenseBackImage]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "saveDriverLicenseBackImage", null);
tslib_1.__decorate([
    Action(SaveDriverLicenseSelfieImage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SaveDriverLicenseSelfieImage]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "saveDriverLicenseSelfieImage", null);
tslib_1.__decorate([
    Action(SetEducation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetEducation]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setEducation", null);
tslib_1.__decorate([
    Action(SaveEducation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SaveEducation]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "saveEducation", null);
tslib_1.__decorate([
    Action(UpdateEducation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateEducation]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "updateEducation", null);
tslib_1.__decorate([
    Action(SetDrivingExperience),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetDrivingExperience]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setDrivingExperience", null);
tslib_1.__decorate([
    Action(SaveDrivingExperience),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SaveDrivingExperience]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "saveDrivingExperience", null);
tslib_1.__decorate([
    Action(SetProfilePicture),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetProfilePicture]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setProfilePicture", null);
tslib_1.__decorate([
    Action(SetEmploymentHistory),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetEmploymentHistory]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setEmploymentHistory", null);
tslib_1.__decorate([
    Action(SetPreferences),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetPreferences]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState.prototype, "setPreferences", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "driver", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "notification", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "kycVerificationData", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "employmentHistory", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "education", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "drivingExperience", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "preferences", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], RegistrationState, "profilePicture", null);
RegistrationState = tslib_1.__decorate([
    State({
        name: 'registration',
        defaults: {
            driver: driverDefault,
            notification: notificationsDefault,
            kycVerificationData: kycVerificationDataDefault,
            employmentHistory: employmentHistoryDefault,
            education: educationDefault,
            drivingExperience: drivingExperienceDefault,
            preferences: preferencesDefault,
            profilePicture: profilePictureDefault
        }
    }),
    tslib_1.__metadata("design:paramtypes", [DriverApiService])
], RegistrationState);
export { RegistrationState };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
