<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
<div fxFlex
     fxLayout="column"
     class="padding-sides-2x">
  <img fxFlexAlign="center"
       class="logo padding-top-x4"
       src="/assets/img/fleetoperate_delivery_white_logo.png" width="300px">
  <div fxLayout="column"
       fxLayoutAlign="end center"
       class="padding-top-x2">
    <span>Signup Error</span>
  </div>
  <span fxFlexAlign="center"
        class="padding-top-x4">{{message}}</span>
  <div fxLayout
       fxLayoutAlign="center end"
       class="padding-top-x4">
    <button fxFlex.gt-sm="20%"
            fxFlex.lt-md="100%"
            class="margin-bottom margin-top-x4"
            mat-raised-button
            color="primary"
            type="submit"
            (click)="onNavigateToSignUp()">Signup</button>
    </div>
</div>
</div>
