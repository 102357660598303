import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';

import { RegistrationComponent } from './registration/registration.component';
import { WebcamSnapshotComponent } from 'src/app/registration/webcam-snapshot/webcam-snapshot.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { KYCVerificationService } from 'src/app/registration/shared/kyc-verification.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { RegistrationRouterState } from 'src/app/registration/state/registration-router.state';
import { EmploymentFormComponent } from './employment-form/employment-form.component';
import { EmploymentViewComponent } from './employment-view/employment-view.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { EmploymentHistoryComponent } from './employment-history/employment-history.component';
import { EducationComponent } from './education/education.component';
import { DrivingExperienceComponent } from './driving-experience/driving-experience.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { TruckTypeComponent } from './truck-type/truck-type.component';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProfileModule } from '../profile/profile.module';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { DriverAbstractComponent } from './driver-abstract/driver-abstract.component';
import { DriveTestComponent } from './drive-test/drive-test.component';
import { MedicalExaminationComponent } from './medical-examination/medical-examination.component';
import { CriminalBackgroundCheckComponent } from './criminal-background-check/criminal-background-check.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    WebcamSnapshotComponent,
    EmploymentFormComponent,
    EmploymentViewComponent,
    ProfileComponent,
    EmploymentHistoryComponent,
    EducationComponent,
    DrivingExperienceComponent,
    PreferencesComponent,
    ProfilePictureComponent,
    TruckTypeComponent,
    PersonalInformationComponent,
    DriverAbstractComponent,
    DriveTestComponent,
    MedicalExaminationComponent,
    CriminalBackgroundCheckComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    WebcamModule,
    MaterialModule,
    NgxsModule.forFeature([RegistrationState, RegistrationRouterState]),
    ImageCropperModule,
    ProfileModule
  ],
  providers: [KYCVerificationService, RegistrationService, DriverApiService, DatePipe, CommonService]
})
export class RegistrationModule {}

export { RegistrationComponent } from './registration/registration.component';
