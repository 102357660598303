import { Action, Selector, State, StateContext } from '@ngxs/store';
import { WebcamImage } from 'ngx-webcam';
import * as moment from 'moment';

import {
  ClearKYCVerificationData,
  SetKYCVerificationData,
  UpdateKYCVerificationData,
  SaveDriverLicenseFrontImage,
  SaveDriverLicenseBackImage,
  SaveDriverLicenseSelfieImage,
  SaveEducation,
  SaveDrivingExperience,
  SavePreferences,
  UpdateEducation,
  SetEducation,
  SetEmploymentHistory,
  SetPreferences,
  SetProfilePicture,
  SetDrivingExperience,
  SetDriver,
  UpdateDriver,
  ClearDriver,
  ClearRegistration,
  SetNotification
} from './registration.actions';
import { Employment } from 'src/app/shared/models/employment.model';
import { TruckType } from 'src/app/registration/shared/models/truck-type.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Education } from 'src/app/shared/models/education.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Notification } from 'src/app/shared/models/notification.model';
import { EmploymentHistory } from '../shared/models/employment-history.model';
import { Image } from 'src/app/registration/shared/models/image.model';

export interface DriverStateModel {
  id: string;
  driverID: string;
  firstName: string;
  lastName: string;
  email: string;
  identityStatus: string;
  phoneNumber: string;
  birthday: string | Date;
  gender: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  licenseType: string;
  issuingAuthority: string;
  createdDate: string | Date;
  modifiedDate: string | Date;
  stats: DriverStatsModel;
}

export interface DriverStatsModel {
  shipmentsCompleted: number;
  shipmentUpcoming: number;
  shipmentResponseRate: number;
  shipmentEarnedThisMonth: number;
}

export interface RegistrationKYCVerificationDataStateModel {
  documentFrontImage: Image;
  documentBackImage: Image;
  livePhoto: Image;
  documentType: string;
}

export interface RegistrationEmploymentHistoryStateModel {
  employmentList: Employment[];
  noExperience: boolean;
}

export interface NotificationStateModel {
  notifications: Notification[];
}

export interface RegistrationEducationStateModel {
  id: string;
  highestGradeCompleted: string;
  lastSchoolAttended: string;
  certification: string;
  specialCourses: string;
}

export interface RegistrationDrivingExperienceStateModel {
  id: string;
  drivingGraduate: boolean;
  companyDriver: boolean;
  studentDriver: boolean;
  ownerOperator: boolean;
  yearsOfExperience: number;
  truckTypes: TruckType[];
}

export interface RegistrationPreferencesStateModel {
  id: string;
  truckType: RegistrationPreferenceStateTruckTypeModel;
  day: RegistrationPreferenceStateDayModel;
}

export interface RegistrationPreferenceStateTruckTypeModel {
  any: boolean;
  hhg: boolean;
  reefer: boolean;
  hazmat: boolean;
  tanker: boolean;
  carHauling: boolean;
  flatBed: boolean;
  van: boolean;
  specialized: boolean;
  lcv: boolean;
}

export interface RegistrationPreferenceStateDayModel {
  weekdays: boolean;
  weekend: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface RegistrationProfilePictureStateModel {
  id: string;
  type: string;
  createdDate: Date;
  fileUrl: string;
  status: string;
}

const driverStatsDefault = {
  shipmentsCompleted: undefined,
  shipmentUpcoming: undefined,
  shipmentResponseRate: undefined,
  shipmentEarnedThisMonth: undefined
} as DriverStatsModel;

const driverDefault = {
  id: undefined,
  driverID: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  identityStatus: undefined,
  phoneNumber: undefined,
  birthday: undefined,
  gender: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  country: undefined,
  licenseType: undefined,
  issuingAuthority: undefined,
  createdDate: undefined,
  modifiedDate: undefined,
  stats: driverStatsDefault
} as DriverStateModel;

const kycVerificationDataDefault = {
  documentFrontImage: undefined,
  documentBackImage: undefined,
  livePhoto: undefined,
  documentType: ''
} as RegistrationKYCVerificationDataStateModel;

const employmentHistoryDefault = {
  employmentList: [],
  noExperience: false
} as RegistrationEmploymentHistoryStateModel;

const notificationsDefault = {
  notifications: []
} as NotificationStateModel;

const educationDefault = {
  id: undefined,
  highestGradeCompleted: undefined,
  lastSchoolAttended: undefined,
  certification: undefined,
  specialCourses: undefined
} as RegistrationEducationStateModel;

const drivingExperienceDefault = {
  id: undefined,
  drivingGraduate: false,
  companyDriver: false,
  studentDriver: false,
  ownerOperator: false,
  yearsOfExperience: undefined,
  truckTypes: []
} as RegistrationDrivingExperienceStateModel;

const preferencesDefault = {
  id: '',
  truckType: {
    any: false,
    hhg: false,
    reefer: false,
    hazmat: false,
    tanker: false,
    carHauling: false,
    flatBed: false,
    van: false,
    specialized: false,
    lcv: false
  },
  day: {
    weekdays: false,
    weekend: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  }
} as RegistrationPreferencesStateModel;

const profilePictureDefault = {
  id: undefined,
  type: undefined,
  createdDate: undefined,
  fileUrl: undefined,
  status: undefined
};

export interface RegistrationStateModel {
  driver: DriverStateModel;
  notification: NotificationStateModel;
  kycVerificationData: RegistrationKYCVerificationDataStateModel;
  employmentHistory: RegistrationEmploymentHistoryStateModel;
  education: RegistrationEducationStateModel;
  drivingExperience: RegistrationDrivingExperienceStateModel;
  preferences: RegistrationPreferencesStateModel;
  profilePicture: RegistrationProfilePictureStateModel;
}

export interface ImageModel {
  imageAsDataUrl: string;
  file: any;
}

export interface DocumentFrontImageModel extends ImageModel {}
export interface DocumentBackImageModel extends ImageModel {}
export interface DocumentSelfieImageModel extends ImageModel {}

@State<RegistrationStateModel>({
  name: 'registration',
  defaults: {
    driver: driverDefault,
    notification: notificationsDefault,
    kycVerificationData: kycVerificationDataDefault,
    employmentHistory: employmentHistoryDefault,
    education: educationDefault,
    drivingExperience: drivingExperienceDefault,
    preferences: preferencesDefault,
    profilePicture: profilePictureDefault
  }
})
export class RegistrationState {
  constructor(private readonly driverApi: DriverApiService) {}

  @Selector() static driver(state: RegistrationStateModel) {
    return state.driver;
  }

  @Selector() static notification(state: RegistrationStateModel) {
    return state.notification;
  }

  @Selector() static kycVerificationData(state: RegistrationStateModel) {
    return state.kycVerificationData;
  }

  @Selector() static employmentHistory(state: RegistrationStateModel) {
    return state.employmentHistory;
  }

  @Selector() static education(state: RegistrationStateModel) {
    return state.education;
  }

  @Selector() static drivingExperience(state: RegistrationStateModel) {
    return state.drivingExperience;
  }

  @Selector() static preferences(state: RegistrationStateModel) {
    return state.preferences;
  }

  @Selector() static profilePicture(state: RegistrationStateModel) {
    return state.profilePicture;
  }

  @Action(ClearRegistration)
  clearRegistration(ctx: StateContext<RegistrationStateModel>, action: ClearRegistration) {
    ctx.setState({
      driver: driverDefault,
      notification: notificationsDefault,
      kycVerificationData: kycVerificationDataDefault,
      employmentHistory: employmentHistoryDefault,
      education: educationDefault,
      drivingExperience: drivingExperienceDefault,
      preferences: preferencesDefault,
      profilePicture: profilePictureDefault
    });
  }

  @Action(SetDriver)
  setDriver(ctx: StateContext<RegistrationStateModel>, { payload }: SetDriver) {
    ctx.patchState({ driver: payload });
  }

  @Action(UpdateDriver)
  updateDriver(ctx: StateContext<RegistrationStateModel>, { payload }: UpdateDriver) {
    ctx.patchState({ driver: payload });
  }

  @Action(ClearDriver)
  clearDriver(ctx: StateContext<RegistrationStateModel>, action: ClearDriver) {
    ctx.patchState({ driver: undefined });
  }

  @Action(SetNotification)
  setNotification(ctx: StateContext<RegistrationStateModel>, { payload }: SetNotification) {
    ctx.patchState({ notification: { notifications: [...payload] } });
  }

  @Action(SetKYCVerificationData)
  setKYCVerificationData(ctx: StateContext<RegistrationStateModel>, { payload }: SetKYCVerificationData) {
    ctx.patchState({ kycVerificationData: payload });
  }

  @Action(UpdateKYCVerificationData)
  updateKYCVerificationData(ctx: StateContext<RegistrationStateModel>, { payload }: UpdateKYCVerificationData) {
    ctx.patchState({ kycVerificationData: payload });
  }

  @Action(ClearKYCVerificationData)
  clearKYCVerificationData(ctx: StateContext<RegistrationStateModel>, action: ClearKYCVerificationData) {
    ctx.patchState({ kycVerificationData: undefined });
  }

  @Action(SaveDriverLicenseFrontImage)
  saveDriverLicenseFrontImage(ctx: StateContext<RegistrationStateModel>, { payload }: SaveDriverLicenseFrontImage) {
    const data = ctx.getState().kycVerificationData;
    const kycVerificationData = {
      documentFrontImage: payload,
      documentBackImage: data.documentBackImage,
      livePhoto: data.livePhoto,
      documentType: ''
    };
    ctx.patchState({ kycVerificationData });
  }

  @Action(SaveDriverLicenseBackImage)
  saveDriverLicenseBackImage(ctx: StateContext<RegistrationStateModel>, { payload }: SaveDriverLicenseBackImage) {
    const data = ctx.getState().kycVerificationData;
    const kycVerificationData = {
      documentFrontImage: data.documentFrontImage,
      documentBackImage: payload,
      livePhoto: data.livePhoto,
      documentType: ''
    };
    ctx.patchState({ kycVerificationData });
  }

  @Action(SaveDriverLicenseSelfieImage)
  saveDriverLicenseSelfieImage(ctx: StateContext<RegistrationStateModel>, { payload }: SaveDriverLicenseSelfieImage) {
    const data = ctx.getState().kycVerificationData;
    const kycVerificationData = {
      documentFrontImage: data.documentFrontImage,
      documentBackImage: data.documentBackImage,
      livePhoto: payload,
      documentType: ''
    };
    ctx.patchState({ kycVerificationData });
  }

  @Action(SetEducation)
  setEducation(ctx: StateContext<RegistrationStateModel>, { payload }: SetEducation) {
    const education: Education = payload ? payload : educationDefault;
    ctx.patchState({ education });
  }

  @Action(SaveEducation)
  saveEducation(ctx: StateContext<RegistrationStateModel>, { payload }: SaveEducation) {
    return this.driverApi.createEducation(payload).subscribe((result: any) => {
      const education: Education = result.data;
      ctx.patchState({ education });
    });
  }

  @Action(UpdateEducation)
  updateEducation(ctx: StateContext<RegistrationStateModel>, { payload }: UpdateEducation) {
    return this.driverApi.updateEducation(payload).subscribe((result: any) => {
      const education: Education = result.data;
      ctx.patchState({ education });
    });
  }

  @Action(SetDrivingExperience)
  setDrivingExperience(ctx: StateContext<RegistrationStateModel>, { payload }: SetDrivingExperience) {
    const drivingExperience: DrivingExperience = payload ? payload : drivingExperienceDefault;

    drivingExperience.truckTypes = drivingExperience.truckTypes || [];
    drivingExperience.truckTypes.forEach((element: TruckType) => {
      element.fromDate = element.fromDate ? moment(element.fromDate, 'YYYYMMDD').toDate() : undefined;
      element.toDate = element.toDate ? moment(element.toDate, 'YYYYMMDD').toDate() : undefined;
    });
    ctx.patchState({ drivingExperience });
  }

  @Action(SaveDrivingExperience)
  saveDrivingExperience(ctx: StateContext<RegistrationStateModel>, { payload }: SaveDrivingExperience) {
    return this.driverApi.createDrivingExperience(payload).subscribe((result: any) => {
      const drivingExperience: DrivingExperience = result.data;
      ctx.patchState({ drivingExperience });
    });
  }

  @Action(SetProfilePicture)
  setProfilePicture(ctx: StateContext<RegistrationStateModel>, { payload }: SetProfilePicture) {
    const profilePicture = payload ? (payload as RegistrationProfilePictureStateModel) : profilePictureDefault;
    ctx.patchState({
      profilePicture
    });
  }

  @Action(SetEmploymentHistory)
  setEmploymentHistory(ctx: StateContext<RegistrationStateModel>, { payload }: SetEmploymentHistory) {
    let employmentList: Employment[] = payload;
    employmentList.forEach((element: Employment) => {
      element.fromDate = moment(element.fromDate, 'YYYYMMDD').toDate();
      element.toDate = moment(element.toDate, 'YYYYMMDD').toDate();
    });

    employmentList = employmentList.sort((employment1: Employment, employment2: Employment) => {
      return (employment2.fromDate as Date).getTime() - (employment1.fromDate as Date).getTime();
    });

    const newEmploymentHistory = { employmentList } as EmploymentHistory;

    ctx.patchState({
      employmentHistory: newEmploymentHistory
    });
  }

  @Action(SetPreferences)
  setPreferences(ctx: StateContext<RegistrationStateModel>, { payload }: SetPreferences) {
    const preferences: Preferences = payload ? payload : preferencesDefault;
    ctx.patchState({ preferences });
  }
}
