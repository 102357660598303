import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, forkJoin } from 'rxjs';
import { tap, map, mergeMap, switchMap } from 'rxjs/operators';
import * as mime from 'mime';

import { RegistrationState, DriverStateModel } from 'src/app/registration/state/registration.state';
import { KYCVerificationData } from 'src/app/registration/shared/models/kyc-verification-data.model';
import {
  UpdateKYCVerificationData,
  SaveDriverLicenseFrontImage,
  SaveDriverLicenseBackImage,
  SaveDriverLicenseSelfieImage,
  SaveEducation,
  UpdateEducation,
  SetEmploymentHistory,
  SetEducation,
  SetDrivingExperience,
  SetPreferences,
  SetProfilePicture,
  SetDriver,
  ClearRegistration,
  SetNotification,
  ClearDriver,
  UpdateDriver
} from 'src/app/registration/state/registration.actions';
import { Navigate } from '../state/registration-router.state';
import { DocumentImage } from 'src/app/registration/shared/models/document-image.model';
import { Employment } from 'src/app/shared/models/employment.model';
import { EmploymentHistory } from 'src/app/registration/shared/models/employment-history.model';
import { Education } from 'src/app/shared/models/education.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Driver } from 'src/app/shared/models/driver.model';
import { ROUTE_DRIVER_DASHBOARD, ROUTE_REGISTRATION, ROUTE_PROFILE } from 'src/app/shared/routes';
import { License } from 'src/app/shared/models/license.model';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { DriverStats } from 'src/app/shared/models/driver-stats.model';
import { ProfilePictureReponse } from 'src/app/shared/models/profie-picture-response.model';
import { ProfilePictureRequest } from 'src/app/shared/models/profile-picture-request.model';
import { Response } from '../../shared/services/response';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import { Image } from 'src/app/registration/shared/models/image.model';
import { ImagesUploadRequest } from 'src/app/identity-check/models/images-upload-request';
import { ImagesUploadResponse } from 'src/app/identity-check/models/images-upload-response';

const PATH_PROFILE = 'profile/';
const PATH_IDENTITYCHECK = 'identityCheck/';
const PATH_EMPLOYMENT_HISTORY = 'employmentHistory';
const PATH_EDUCATION = 'education';
const PATH_DRIVING_EXPERIENCE = 'drivingExperience';
const PATH_PREFERENCES = 'preferences';
const PATH_PROFILE_PICTURE = 'profilePicture';
const PATH_PERSONAL_INFORMATION = 'personalInfo';
const PATH_DRIVER_ABSTRACT = 'driverAbstract';
const PATH_MEDICAL_EXAMINATION = 'medicalExamination';
const PATH_CRIMINAL_BACKGROUND_CHECK = 'criminalBackgroundCheck';
const PATH_DRIVE_TEST = 'driveTest';
const KEY_DRIVER = 'driver';
const PATH_LICENSE_STATUS = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseStatus';
const PATH_DRIVER_LICENSE_FRONT_IMAGE = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseFrontImage';
const PATH_DRIVER_LICENSE_BACK_IMAGE = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseBackImage';
const PATH_DRIVER_LICENSE_SELFIE_IMAGE = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseSelfieImage';
const PATH_DRIVER_LICENSE_ALLSET = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseAllset';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(
    private readonly route: Router,
    private readonly store: Store,
    private readonly driverApi: DriverApiService,
    private readonly storageService: StorageService,
    private readonly logger: LoggerService,
    private readonly fileDataService: FileDataService
  ) {
    this.store.dispatch([]);

    const driver = this.storageService.find(KEY_DRIVER) as Driver;
    if (driver) {
      const driverStateModel = this.createDriverStateModel(driver);
      this.store.dispatch([new SetDriver(driverStateModel)]);
    }
  }

  clearRegistration(): void {
    this.store.dispatch([new ClearRegistration()]);
  }

  loadDriver(): Observable<any> {
    return this.driverApi.getDriver().pipe(
      map((response: any) => response.data),
      tap((driver: Driver) => {
        this.storageService.store(KEY_DRIVER, driver);
        const driverStateModel = this.createDriverStateModel(driver);
        this.store.dispatch([new SetDriver(driverStateModel)]);
        this.store.dispatch([new SetNotification(driver.notification)]);
        // const mockNotification1 = {name: 'Test Name', date: 'Apr 12 2019', acknowledged: true} as Notification;
        // const mockNotification2 = {name: 'Test Name2', date: 'Apr 13 2019', acknowledged: false} as Notification;
        // const mockNotifications = [mockNotification1, mockNotification2];
        // this.store.dispatch([new SetNotification(mockNotifications)]);
        this.store.dispatch([new SetEmploymentHistory(driver.employment)]);
        this.store.dispatch([new SetEducation(driver.education)]);
        this.store.dispatch([new SetDrivingExperience(driver.experience)]);
        this.store.dispatch([new SetPreferences(driver.preference)]);
      })
    );
  }

  getDriver(): Driver {
    const driverStateModel = this.store.selectSnapshot(RegistrationState.driver);
    if (!driverStateModel || !driverStateModel.id) {
      return;
    }

    const driver = this.createDriver(driverStateModel);
    return driver;
  }

  clearDriver(): void {
    this.store.dispatch([new ClearDriver()]);
    this.storageService.remove(KEY_DRIVER);
  }

  loadProfilePicture(): Observable<ProfilePicture> {
    return this.driverApi.getProfilePicture().pipe(
      map((response: any) => {
        return response.data as ProfilePicture;
      }),
      mergeMap((picture: ProfilePicture) => {
        return this.setProfilePicture(picture);
      })
    );
  }

  setProfilePicture(profilePicture: ProfilePicture): Observable<ProfilePicture> {
    return this.store.dispatch([new SetProfilePicture(profilePicture)]);
  }

  saveProfilePicture(profilePicture: any, extension: string): Observable<Response> {
    return this.createProfilePicture(extension).pipe(
      switchMap((profilePictureReponse: ProfilePictureReponse) => {
        const url = profilePictureReponse.url;
        const newFileName = profilePictureReponse.file;

        const file = profilePicture;
        const renamedFile: File = new File([file], newFileName, { type: file.type });
        const contentType = mime.getType(extension);

        return this.fileDataService.uploadFile(renamedFile, url, contentType);
      }),
      mergeMap(() => this.store.dispatch([new SetProfilePicture(profilePicture as ProfilePicture)]))
    );
  }

  private createProfilePicture(extension: string): Observable<ProfilePictureReponse> {
    const sendProfilePictureRequest = { extension } as ProfilePictureRequest;
    return this.driverApi.createProfilePicture(sendProfilePictureRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as ProfilePictureReponse;
        }

        return undefined;
      })
    );
  }
  getKYCVerificationData(): KYCVerificationData {
    return this.store.selectSnapshot(RegistrationState.kycVerificationData);
  }

  submitKYCVerification(): Observable<Response> {
    const driver = this.store.selectSnapshot(RegistrationState.driver);
    const kycVerificationData = this.store.selectSnapshot(RegistrationState.kycVerificationData) as KYCVerificationData;

    if (!driver || !driver.firstName || !driver.lastName) {
      this.logger.error('No driver information available to send KYC Verification.');
      return of(undefined);
    }

    if (
      !kycVerificationData ||
      !kycVerificationData.documentFrontImage ||
      !kycVerificationData.documentBackImage ||
      !kycVerificationData.livePhoto
    ) {
      this.logger.error('No images available to send KYC Verification.');
      return of(undefined);
    }

    let imageFiles: ImagesUploadRequest[] = [];
    imageFiles.push(this.createDocumentFront(kycVerificationData.documentFrontImage.file));
    imageFiles.push(this.createDocumentBack(kycVerificationData.documentBackImage.file));
    imageFiles.push(this.createDocumentSelfie(kycVerificationData.livePhoto.file));

    const license: License = {
      type: 'KYC',
      countryCode: 'CA',
      givenName: driver.firstName,
      lastName: driver.lastName,
      documentType: 'DrivingLicence',
      images: imageFiles
    } as License;

    return this.uploadImages(license, kycVerificationData);
  }

  saveDocumentFrontImage(model: DocumentImage): void {
    const imageModel = {
      imageAsDataUrl: model.image.imageAsDataUrl,
      file: model.image.imageData
    } as Image;
    this.store.dispatch([new SaveDriverLicenseFrontImage(imageModel), new Navigate(PATH_DRIVER_LICENSE_BACK_IMAGE)]);
  }

  saveDocumentBackImage(model: DocumentImage): void {
    const imageModel = {
      imageAsDataUrl: model.image.imageAsDataUrl,
      file: model.image.imageData
    } as Image;
    this.store.dispatch([new SaveDriverLicenseBackImage(imageModel), new Navigate(PATH_DRIVER_LICENSE_SELFIE_IMAGE)]);
  }

  saveDocumentSelfieImage(model: DocumentImage): void {
    const imageModel = {
      imageAsDataUrl: model.image.imageAsDataUrl,
      file: model.image.imageData
    } as Image;
    this.store.dispatch([new SaveDriverLicenseSelfieImage(imageModel)]);
  }

  saveEmployment(model: Employment): Observable<any> {
    return this.driverApi.createEmployment(model);
  }

  updateEmployment(model: Employment): Observable<any> {
    return this.driverApi.updateEmployment(model);
  }

  deleteEmployment(model: Employment): Observable<any> {
    return this.driverApi.deleteEmployment(model.id);
  }

  saveEducation(model: Education): Observable<any> {
    return this.store.dispatch([new SaveEducation(model)]);
  }

  updateEducation(model: Education): Observable<any> {
    return this.store.dispatch([new UpdateEducation(model)]);
  }

  updateDriver(driver: Driver): Observable<any> {
    return this.driverApi.updateDriver(driver).pipe(
      map((response: any) => {
        return response.data as Driver;
      }),
      tap((driver: Driver) => {
        const model = this.createDriverStateModel(driver);
        return this.store.dispatch([new UpdateDriver(model)]);
      })
    );
  }

  selectDrivingExperience(): Observable<DrivingExperience> {
    return this.store.select(RegistrationState.drivingExperience);
  }

  saveDrivingExperience(model: DrivingExperience): Observable<any> {
    return this.driverApi.createDrivingExperience(model);
  }

  updateDrivingExperience(model: DrivingExperience): Observable<any> {
    return this.driverApi.updateDrivingExperience(model);
  }

  selectPreferences(): Observable<Preferences> {
    return this.store.select(RegistrationState.preferences);
  }

  savePreferences(model: Preferences): Observable<any> {
    return this.driverApi.createPreferences(model).pipe(
      map((response: any) => {
        return response.data as Preferences;
      }),
      mergeMap((preferences: Preferences) => {
        return this.setPreferences(preferences);
      })
    );
  }

  updatePreferences(model: Preferences): Observable<any> {
    return this.driverApi.updatePreferences(model).pipe(
      map((response: any) => {
        return response.data as Preferences;
      }),
      mergeMap((preferences: Preferences) => {
        return this.setPreferences(preferences);
      })
    );
  }

  selectEmploymentHistory(): Observable<EmploymentHistory> {
    return this.store.select(RegistrationState.employmentHistory);
  }

  setEmploymentHistory(employments: Employment[]): Observable<any> {
    console.log(`setEmploymentHistory`);
    return this.store.dispatch([new SetEmploymentHistory(employments)]);
  }

  selectEducation(): Observable<Education> {
    return this.store.select(RegistrationState.education);
  }

  setEducation(education: Education): Observable<any> {
    return this.store.dispatch([new SetEducation(education)]);
  }

  setDrivingExperience(drivingExperience: DrivingExperience): Observable<any> {
    return this.store.dispatch([new SetDrivingExperience(drivingExperience)]);
  }

  setPreferences(preferences: Preferences): Observable<any> {
    return this.store.dispatch([new SetPreferences(preferences)]);
  }

  navigateToLicenseFrontmage() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_FRONT_IMAGE)]);
  }

  navigateToLicenseBackImage() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_BACK_IMAGE)]);
  }

  navigateToLicenseSelfieImage() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_SELFIE_IMAGE)]);
  }

  navigateToLicenseAllset() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_ALLSET)]);
  }

  navigateToProfile() {
    this.store.dispatch([new Navigate(ROUTE_PROFILE)]);
  }

  navigateToIdentityCheck() {
    this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_FRONT_IMAGE)]);
  }

  navigateToDriverLicenseStatus() {
    this.store.dispatch([new Navigate(PATH_LICENSE_STATUS)]);
  }

  navigateToEmploymentHistory(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_EMPLOYMENT_HISTORY, activatedRoute)]);
  }

  navigateToEducation(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_EDUCATION, activatedRoute)]);
  }

  navigateToDrivingExperience(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DRIVING_EXPERIENCE, activatedRoute)]);
  }

  navigateToPreferences(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_PREFERENCES, activatedRoute)]);
  }

  navigateToProfilePicture(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_PROFILE_PICTURE, activatedRoute)]);
  }
  navigateToPersonalInformation(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_PERSONAL_INFORMATION, activatedRoute)]);
  }
  navigateToDriverDashboard() {
    this.store.dispatch([new Navigate(ROUTE_DRIVER_DASHBOARD)]);
  }
  nagivateToDriverAbstract(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DRIVER_ABSTRACT, activatedRoute)]);
  }
  nagivateToCriminalBackgroundCheck(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_CRIMINAL_BACKGROUND_CHECK, activatedRoute)]);
  }
  nagivateToMedicalExamination(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_MEDICAL_EXAMINATION, activatedRoute)]);
  }
  nagivateToDriveTest(activatedRoute: ActivatedRoute) {
    this.store.dispatch([new Navigate(PATH_DRIVE_TEST, activatedRoute)]);
  }

  checkInRegistration(): any {
    console.log(`Path: ${this.route.url}`);
    console.log(`In registration: ${this.route.url.indexOf(ROUTE_REGISTRATION) !== -1}`);
    return this.route.url.indexOf(ROUTE_REGISTRATION) !== -1;
  }

  private createDriverStateModel(driver: Driver): DriverStateModel {
    const driverStateModel = {
      id: driver.id,
      driverID: driver.driverID,
      firstName: driver.firstName,
      lastName: driver.lastName,
      email: driver.email,
      identityStatus: driver.identityStatus,
      phoneNumber: driver.phoneNumber,
      birthday: driver.birthday,
      gender: driver.gender,
      address: driver.address,
      city: driver.city,
      state: driver.state,
      zip: driver.zip,
      country: driver.country,
      licenseType: driver.licenseType,
      issuingAuthority: driver.issuingAuthority,
      createdDate: driver.createdDate,
      modifiedDate: driver.modifiedDate,
      stats: driver.stats
    } as DriverStateModel;
    return driverStateModel;
  }

  private createDriver(driverStateModel: DriverStateModel): Driver {
    const driver = {
      id: driverStateModel.id,
      driverID: driverStateModel.driverID,
      firstName: driverStateModel.firstName,
      lastName: driverStateModel.lastName,
      email: driverStateModel.email,
      identityStatus: driverStateModel.identityStatus,
      phoneNumber: driverStateModel.phoneNumber,
      birthday: driverStateModel.birthday,
      gender: driverStateModel.gender,
      address: driverStateModel.address,
      city: driverStateModel.city,
      state: driverStateModel.state,
      zip: driverStateModel.zip,
      country: driverStateModel.country,
      licenseType: driverStateModel.licenseType,
      issuingAuthority: driverStateModel.issuingAuthority,
      createdDate: driverStateModel.createdDate,
      modifiedDate: driverStateModel.modifiedDate
    } as Driver;
    return driver;
  }

  private uploadImages(license: License, kycData: KYCVerificationData): Observable<Response> {
    return this.submitLicense(license).pipe(
      mergeMap((images: ImagesUploadResponse[]) => {
        const array = images.map(image => {
          const url = image.signedURL;
          const newFileName = image.fileName;
          const file = this.getImageFile(image.type, kycData);
          const renamedFile: File = new File([file], newFileName, { type: file.type });
          const contentType = mime.getType(image.extension);
          return this.fileDataService.uploadFile(renamedFile, url, contentType);
        });
        return forkJoin(...array);
      })
    );
  }

  private submitLicense(license: License): Observable<ImagesUploadResponse[]> {
    return this.driverApi.submitLicense(license).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data.images as ImagesUploadResponse[];
        }
        return undefined;
      })
    );
  }

  private createDocumentFront(file: any): ImagesUploadRequest {
    return { type: 'documentFront', extension: file.name.split('.')[1].toLowerCase() } as ImagesUploadRequest;
  }

  private createDocumentBack(file: any): ImagesUploadRequest {
    return { type: 'documentBack', extension: file.name.split('.')[1].toLowerCase() } as ImagesUploadRequest;
  }

  private createDocumentSelfie(file: any): ImagesUploadRequest {
    return { type: 'documentSelfie', extension: file.name.split('.')[1].toLowerCase() } as ImagesUploadRequest;
  }

  private getImageFile(type: string, kycData: KYCVerificationData): any {
    if (type === 'documentFront') {
      return kycData.documentFrontImage.file;
    } else if (type === 'documentBack') {
      return kycData.documentBackImage.file;
    } else {
      return kycData.livePhoto.file;
    }
  }
}
