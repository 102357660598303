import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { IsLoadingService } from '@service-work/is-loading';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@service-work/is-loading";
export class FleetoperateApiService {
    constructor(http, isLoadingService) {
        this.http = http;
        this.isLoadingService = isLoadingService;
    }
    post(path, object, token) {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            Object.assign(headers, { Authorization: token.accessToken });
        }
        const httpOptions = {
            headers: new HttpHeaders(headers)
        };
        const loadingSubject = new Subject();
        const call = this.http.post(path, object, httpOptions)
            .pipe(catchError(this.handleError), finalize(() => loadingSubject.complete()));
        this.isLoadingService.add(loadingSubject.asObservable());
        return call;
    }
    put(path, object, token, httpOptions) {
        const defaultContentType = {
            'Content-Type': 'application/json'
        };
        if (!httpOptions) {
            httpOptions = {
                headers: new HttpHeaders(defaultContentType)
            };
        }
        else if (httpOptions && !httpOptions.headers) {
            httpOptions.headers = new HttpHeaders(defaultContentType);
        }
        else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
            httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
        }
        if (token) {
            httpOptions.headers = httpOptions.headers.append('Authorization', token.accessToken);
        }
        const loadingSubject = new Subject();
        const call = this.http.put(path, object, httpOptions)
            .pipe(catchError(this.handleError), finalize(() => loadingSubject.complete()));
        this.isLoadingService.add(loadingSubject.asObservable());
        return call;
    }
    delete(path, token) {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            Object.assign(headers, { Authorization: token.accessToken });
        }
        const httpOptions = {
            headers: new HttpHeaders(headers)
        };
        const loadingSubject = new Subject();
        const call = this.http.delete(path, httpOptions)
            .pipe(catchError(this.handleError), finalize(() => loadingSubject.complete()));
        this.isLoadingService.add(loadingSubject.asObservable());
        return call;
    }
    get(path, object, token) {
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            Object.assign(headers, { Authorization: token.accessToken });
        }
        const httpOptions = {
            headers: new HttpHeaders(headers)
        };
        const loadingSubject = new Subject();
        const call = this.http.get(path, httpOptions)
            .pipe(catchError(this.handleError), finalize(() => loadingSubject.complete()));
        this.isLoadingService.add(loadingSubject.asObservable());
        return call;
    }
    handleError(error) {
        let message = 'Something bad happened; please try again later.';
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            message = error.error.message;
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
            message = error.error;
        }
        // return an observable with a user-facing error message
        return throwError(message);
    }
}
FleetoperateApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FleetoperateApiService_Factory() { return new FleetoperateApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.IsLoadingService)); }, token: FleetoperateApiService, providedIn: "root" });
