import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { tap, onErrorResumeNext } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Education } from 'src/app/shared/models/education.model';
import { EmploymentHistory } from 'src/app/registration/shared/models/employment-history.model';
import { RegistrationState, DriverStateModel, DriverStatsModel } from 'src/app/registration/state/registration.state';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-driver-dashboard',
  templateUrl: './driver-dashboard.component.html',
  styleUrls: ['./driver-dashboard.component.scss']
})
export class DriverDashboardComponent implements OnInit, OnDestroy {
  driverPicture: string;
  driverName: string;
  profileCompleted = false;

  completed: string;
  upcoming: string;
  responseRate: string;
  earnedThisMonth: string;

  private employmentHistory: EmploymentHistory = new EmploymentHistory();
  private education: Education = new Education();
  private drivingExperience: DrivingExperience = new DrivingExperience();
  private driver: DriverStateModel;
  private preferences: Preferences = new Preferences();
  private profilePicture: ProfilePicture = new ProfilePicture();
  private subs = new SubSink();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly currencyService: CurrencyService,
    private readonly profileService: ProfileService
  ) {
    this.driverName = '';

    this.completed = undefined;
    this.upcoming = undefined;
    this.responseRate = undefined;
    this.earnedThisMonth = undefined;
  }

  ngOnInit() {
    this.employmentHistory.employmentList = [];

    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(
          tap(() => (this.driver = this.store.selectSnapshot(RegistrationState.driver))),
          tap(() => (this.preferences = this.store.selectSnapshot(RegistrationState.preferences))),
          tap(() => (this.employmentHistory = this.store.selectSnapshot(RegistrationState.employmentHistory))),
          tap(() => (this.education = this.store.selectSnapshot(RegistrationState.education))),
          tap(() => (this.drivingExperience = this.store.selectSnapshot(RegistrationState.drivingExperience))),
          onErrorResumeNext(this.registrationService.loadProfilePicture()),
          tap(() => {
            this.profilePicture = this.store.selectSnapshot(RegistrationState.profilePicture);

            if (this.profilePicture && this.profilePicture.fileUrl) {
              this.driverPicture = this.profilePicture.fileUrl;
            }
          }),
          tap(() => {
            if (this.driver && this.driver.firstName && this.driver.lastName) {
              this.driverName = `${this.driver.firstName} ${this.driver.lastName}`;
            } else {
              this.driverName = 'Driver Name';
            }
          }),
          tap(() => {
            if (!this.driver.stats) {
              return;
            }
            this.completed = this.determineCompleted(this.driver.stats);
            this.upcoming = this.determineUpcoming(this.driver.stats);
            this.responseRate = this.determineResponseRate(this.driver.stats);
            this.earnedThisMonth = this.currencyService.getLocaleCurrency(this.driver.stats.shipmentEarnedThisMonth);
          })
        )
        .subscribe(() => {
          this.profileCompleted = this.profileService.isProfileCompleted();
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onNavigateToProfile() {
    this.registrationService.navigateToProfile();
  }

  private determineCompleted(stats: DriverStatsModel): string {
    let result: string;
    result = stats.shipmentsCompleted ? this.leftPad(stats.shipmentsCompleted, 2) : undefined;
    return result;
  }

  private determineUpcoming(stats: DriverStatsModel): string {
    let result: string;
    result = stats.shipmentUpcoming ? this.leftPad(stats.shipmentUpcoming, 2) : undefined;
    return result;
  }

  private determineResponseRate(stats: DriverStatsModel): string {
    let result: string;
    result = stats.shipmentResponseRate ? `${stats.shipmentResponseRate}%` : undefined;
    return result;
  }

  private leftPad(value: number, size: number): string {
    let valueAsString = `${value}`;
    while (valueAsString.length < size) {
      valueAsString = '0' + valueAsString;
    }
    return valueAsString;
  }
}
