import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProfileOutletComponent } from './profile-outlet/profile-outlet.component';

@NgModule({
  declarations: [ProfileOutletComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class ProfileModule { }
