<router-outlet></router-outlet>
<nav mat-tab-nav-bar class="fixed-bottom">
  <a
    mat-tab-link
    fxFlex
    *ngFor="let link of links"
    [routerLink]=""
    [active]="link.active"
    (click)="link.clickHandler()"
  >
    <mat-icon *ngIf="!link.svgIcon">{{ link.icon }}</mat-icon>
    <mat-icon fxFlex class="car-icon" *ngIf="link.svgIcon" svgIcon="car"></mat-icon>
  </a>
  <button mat-button fxFlex (click)="onOpenOverflowMenu()">
    <mat-icon>menu</mat-icon>
  </button>
</nav>
