import { Store } from '@ngxs/store';
import { of, forkJoin } from 'rxjs';
import { tap, map, mergeMap, switchMap } from 'rxjs/operators';
import * as mime from 'mime';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { SaveDriverLicenseFrontImage, SaveDriverLicenseBackImage, SaveDriverLicenseSelfieImage, SaveEducation, UpdateEducation, SetEmploymentHistory, SetEducation, SetDrivingExperience, SetPreferences, SetProfilePicture, SetDriver, ClearRegistration, SetNotification, ClearDriver, UpdateDriver } from 'src/app/registration/state/registration.actions';
import { Navigate } from '../state/registration-router.state';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ROUTE_DRIVER_DASHBOARD, ROUTE_REGISTRATION, ROUTE_PROFILE } from 'src/app/shared/routes';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngxs/store";
import * as i3 from "../../shared/services/driver-api.service";
import * as i4 from "../../shared/storage/storage.service";
import * as i5 from "../../core/services/logger.service";
import * as i6 from "../../shared/file-data/file-data.service";
const PATH_PROFILE = 'profile/';
const PATH_IDENTITYCHECK = 'identityCheck/';
const PATH_EMPLOYMENT_HISTORY = 'employmentHistory';
const PATH_EDUCATION = 'education';
const PATH_DRIVING_EXPERIENCE = 'drivingExperience';
const PATH_PREFERENCES = 'preferences';
const PATH_PROFILE_PICTURE = 'profilePicture';
const PATH_PERSONAL_INFORMATION = 'personalInfo';
const PATH_DRIVER_ABSTRACT = 'driverAbstract';
const PATH_MEDICAL_EXAMINATION = 'medicalExamination';
const PATH_CRIMINAL_BACKGROUND_CHECK = 'criminalBackgroundCheck';
const PATH_DRIVE_TEST = 'driveTest';
const KEY_DRIVER = 'driver';
const PATH_LICENSE_STATUS = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseStatus';
const PATH_DRIVER_LICENSE_FRONT_IMAGE = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseFrontImage';
const PATH_DRIVER_LICENSE_BACK_IMAGE = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseBackImage';
const PATH_DRIVER_LICENSE_SELFIE_IMAGE = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseSelfieImage';
const PATH_DRIVER_LICENSE_ALLSET = PATH_PROFILE + PATH_IDENTITYCHECK + 'licenseAllset';
export class RegistrationService {
    constructor(route, store, driverApi, storageService, logger, fileDataService) {
        this.route = route;
        this.store = store;
        this.driverApi = driverApi;
        this.storageService = storageService;
        this.logger = logger;
        this.fileDataService = fileDataService;
        this.store.dispatch([]);
        const driver = this.storageService.find(KEY_DRIVER);
        if (driver) {
            const driverStateModel = this.createDriverStateModel(driver);
            this.store.dispatch([new SetDriver(driverStateModel)]);
        }
    }
    clearRegistration() {
        this.store.dispatch([new ClearRegistration()]);
    }
    loadDriver() {
        return this.driverApi.getDriver().pipe(map((response) => response.data), tap((driver) => {
            this.storageService.store(KEY_DRIVER, driver);
            const driverStateModel = this.createDriverStateModel(driver);
            this.store.dispatch([new SetDriver(driverStateModel)]);
            this.store.dispatch([new SetNotification(driver.notification)]);
            // const mockNotification1 = {name: 'Test Name', date: 'Apr 12 2019', acknowledged: true} as Notification;
            // const mockNotification2 = {name: 'Test Name2', date: 'Apr 13 2019', acknowledged: false} as Notification;
            // const mockNotifications = [mockNotification1, mockNotification2];
            // this.store.dispatch([new SetNotification(mockNotifications)]);
            this.store.dispatch([new SetEmploymentHistory(driver.employment)]);
            this.store.dispatch([new SetEducation(driver.education)]);
            this.store.dispatch([new SetDrivingExperience(driver.experience)]);
            this.store.dispatch([new SetPreferences(driver.preference)]);
        }));
    }
    getDriver() {
        const driverStateModel = this.store.selectSnapshot(RegistrationState.driver);
        if (!driverStateModel || !driverStateModel.id) {
            return;
        }
        const driver = this.createDriver(driverStateModel);
        return driver;
    }
    clearDriver() {
        this.store.dispatch([new ClearDriver()]);
        this.storageService.remove(KEY_DRIVER);
    }
    loadProfilePicture() {
        return this.driverApi.getProfilePicture().pipe(map((response) => {
            return response.data;
        }), mergeMap((picture) => {
            return this.setProfilePicture(picture);
        }));
    }
    setProfilePicture(profilePicture) {
        return this.store.dispatch([new SetProfilePicture(profilePicture)]);
    }
    saveProfilePicture(profilePicture, extension) {
        return this.createProfilePicture(extension).pipe(switchMap((profilePictureReponse) => {
            const url = profilePictureReponse.url;
            const newFileName = profilePictureReponse.file;
            const file = profilePicture;
            const renamedFile = new File([file], newFileName, { type: file.type });
            const contentType = mime.getType(extension);
            return this.fileDataService.uploadFile(renamedFile, url, contentType);
        }), mergeMap(() => this.store.dispatch([new SetProfilePicture(profilePicture)])));
    }
    createProfilePicture(extension) {
        const sendProfilePictureRequest = { extension };
        return this.driverApi.createProfilePicture(sendProfilePictureRequest).pipe(map((response) => {
            if (response && response.data) {
                return response.data;
            }
            return undefined;
        }));
    }
    getKYCVerificationData() {
        return this.store.selectSnapshot(RegistrationState.kycVerificationData);
    }
    submitKYCVerification() {
        const driver = this.store.selectSnapshot(RegistrationState.driver);
        const kycVerificationData = this.store.selectSnapshot(RegistrationState.kycVerificationData);
        if (!driver || !driver.firstName || !driver.lastName) {
            this.logger.error('No driver information available to send KYC Verification.');
            return of(undefined);
        }
        if (!kycVerificationData ||
            !kycVerificationData.documentFrontImage ||
            !kycVerificationData.documentBackImage ||
            !kycVerificationData.livePhoto) {
            this.logger.error('No images available to send KYC Verification.');
            return of(undefined);
        }
        let imageFiles = [];
        imageFiles.push(this.createDocumentFront(kycVerificationData.documentFrontImage.file));
        imageFiles.push(this.createDocumentBack(kycVerificationData.documentBackImage.file));
        imageFiles.push(this.createDocumentSelfie(kycVerificationData.livePhoto.file));
        const license = {
            type: 'KYC',
            countryCode: 'CA',
            givenName: driver.firstName,
            lastName: driver.lastName,
            documentType: 'DrivingLicence',
            images: imageFiles
        };
        return this.uploadImages(license, kycVerificationData);
    }
    saveDocumentFrontImage(model) {
        const imageModel = {
            imageAsDataUrl: model.image.imageAsDataUrl,
            file: model.image.imageData
        };
        this.store.dispatch([new SaveDriverLicenseFrontImage(imageModel), new Navigate(PATH_DRIVER_LICENSE_BACK_IMAGE)]);
    }
    saveDocumentBackImage(model) {
        const imageModel = {
            imageAsDataUrl: model.image.imageAsDataUrl,
            file: model.image.imageData
        };
        this.store.dispatch([new SaveDriverLicenseBackImage(imageModel), new Navigate(PATH_DRIVER_LICENSE_SELFIE_IMAGE)]);
    }
    saveDocumentSelfieImage(model) {
        const imageModel = {
            imageAsDataUrl: model.image.imageAsDataUrl,
            file: model.image.imageData
        };
        this.store.dispatch([new SaveDriverLicenseSelfieImage(imageModel)]);
    }
    saveEmployment(model) {
        return this.driverApi.createEmployment(model);
    }
    updateEmployment(model) {
        return this.driverApi.updateEmployment(model);
    }
    deleteEmployment(model) {
        return this.driverApi.deleteEmployment(model.id);
    }
    saveEducation(model) {
        return this.store.dispatch([new SaveEducation(model)]);
    }
    updateEducation(model) {
        return this.store.dispatch([new UpdateEducation(model)]);
    }
    updateDriver(driver) {
        return this.driverApi.updateDriver(driver).pipe(map((response) => {
            return response.data;
        }), tap((driver) => {
            const model = this.createDriverStateModel(driver);
            return this.store.dispatch([new UpdateDriver(model)]);
        }));
    }
    selectDrivingExperience() {
        return this.store.select(RegistrationState.drivingExperience);
    }
    saveDrivingExperience(model) {
        return this.driverApi.createDrivingExperience(model);
    }
    updateDrivingExperience(model) {
        return this.driverApi.updateDrivingExperience(model);
    }
    selectPreferences() {
        return this.store.select(RegistrationState.preferences);
    }
    savePreferences(model) {
        return this.driverApi.createPreferences(model).pipe(map((response) => {
            return response.data;
        }), mergeMap((preferences) => {
            return this.setPreferences(preferences);
        }));
    }
    updatePreferences(model) {
        return this.driverApi.updatePreferences(model).pipe(map((response) => {
            return response.data;
        }), mergeMap((preferences) => {
            return this.setPreferences(preferences);
        }));
    }
    selectEmploymentHistory() {
        return this.store.select(RegistrationState.employmentHistory);
    }
    setEmploymentHistory(employments) {
        console.log(`setEmploymentHistory`);
        return this.store.dispatch([new SetEmploymentHistory(employments)]);
    }
    selectEducation() {
        return this.store.select(RegistrationState.education);
    }
    setEducation(education) {
        return this.store.dispatch([new SetEducation(education)]);
    }
    setDrivingExperience(drivingExperience) {
        return this.store.dispatch([new SetDrivingExperience(drivingExperience)]);
    }
    setPreferences(preferences) {
        return this.store.dispatch([new SetPreferences(preferences)]);
    }
    navigateToLicenseFrontmage() {
        this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_FRONT_IMAGE)]);
    }
    navigateToLicenseBackImage() {
        this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_BACK_IMAGE)]);
    }
    navigateToLicenseSelfieImage() {
        this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_SELFIE_IMAGE)]);
    }
    navigateToLicenseAllset() {
        this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_ALLSET)]);
    }
    navigateToProfile() {
        this.store.dispatch([new Navigate(ROUTE_PROFILE)]);
    }
    navigateToIdentityCheck() {
        this.store.dispatch([new Navigate(PATH_DRIVER_LICENSE_FRONT_IMAGE)]);
    }
    navigateToDriverLicenseStatus() {
        this.store.dispatch([new Navigate(PATH_LICENSE_STATUS)]);
    }
    navigateToEmploymentHistory(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_EMPLOYMENT_HISTORY, activatedRoute)]);
    }
    navigateToEducation(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_EDUCATION, activatedRoute)]);
    }
    navigateToDrivingExperience(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_DRIVING_EXPERIENCE, activatedRoute)]);
    }
    navigateToPreferences(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_PREFERENCES, activatedRoute)]);
    }
    navigateToProfilePicture(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_PROFILE_PICTURE, activatedRoute)]);
    }
    navigateToPersonalInformation(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_PERSONAL_INFORMATION, activatedRoute)]);
    }
    navigateToDriverDashboard() {
        this.store.dispatch([new Navigate(ROUTE_DRIVER_DASHBOARD)]);
    }
    nagivateToDriverAbstract(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_DRIVER_ABSTRACT, activatedRoute)]);
    }
    nagivateToCriminalBackgroundCheck(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_CRIMINAL_BACKGROUND_CHECK, activatedRoute)]);
    }
    nagivateToMedicalExamination(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_MEDICAL_EXAMINATION, activatedRoute)]);
    }
    nagivateToDriveTest(activatedRoute) {
        this.store.dispatch([new Navigate(PATH_DRIVE_TEST, activatedRoute)]);
    }
    checkInRegistration() {
        console.log(`Path: ${this.route.url}`);
        console.log(`In registration: ${this.route.url.indexOf(ROUTE_REGISTRATION) !== -1}`);
        return this.route.url.indexOf(ROUTE_REGISTRATION) !== -1;
    }
    createDriverStateModel(driver) {
        const driverStateModel = {
            id: driver.id,
            driverID: driver.driverID,
            firstName: driver.firstName,
            lastName: driver.lastName,
            email: driver.email,
            identityStatus: driver.identityStatus,
            phoneNumber: driver.phoneNumber,
            birthday: driver.birthday,
            gender: driver.gender,
            address: driver.address,
            city: driver.city,
            state: driver.state,
            zip: driver.zip,
            country: driver.country,
            licenseType: driver.licenseType,
            issuingAuthority: driver.issuingAuthority,
            createdDate: driver.createdDate,
            modifiedDate: driver.modifiedDate,
            stats: driver.stats
        };
        return driverStateModel;
    }
    createDriver(driverStateModel) {
        const driver = {
            id: driverStateModel.id,
            driverID: driverStateModel.driverID,
            firstName: driverStateModel.firstName,
            lastName: driverStateModel.lastName,
            email: driverStateModel.email,
            identityStatus: driverStateModel.identityStatus,
            phoneNumber: driverStateModel.phoneNumber,
            birthday: driverStateModel.birthday,
            gender: driverStateModel.gender,
            address: driverStateModel.address,
            city: driverStateModel.city,
            state: driverStateModel.state,
            zip: driverStateModel.zip,
            country: driverStateModel.country,
            licenseType: driverStateModel.licenseType,
            issuingAuthority: driverStateModel.issuingAuthority,
            createdDate: driverStateModel.createdDate,
            modifiedDate: driverStateModel.modifiedDate
        };
        return driver;
    }
    uploadImages(license, kycData) {
        return this.submitLicense(license).pipe(mergeMap((images) => {
            const array = images.map(image => {
                const url = image.signedURL;
                const newFileName = image.fileName;
                const file = this.getImageFile(image.type, kycData);
                const renamedFile = new File([file], newFileName, { type: file.type });
                const contentType = mime.getType(image.extension);
                return this.fileDataService.uploadFile(renamedFile, url, contentType);
            });
            return forkJoin(...array);
        }));
    }
    submitLicense(license) {
        return this.driverApi.submitLicense(license).pipe(map((response) => {
            if (response && response.data) {
                return response.data.images;
            }
            return undefined;
        }));
    }
    createDocumentFront(file) {
        return { type: 'documentFront', extension: file.name.split('.')[1].toLowerCase() };
    }
    createDocumentBack(file) {
        return { type: 'documentBack', extension: file.name.split('.')[1].toLowerCase() };
    }
    createDocumentSelfie(file) {
        return { type: 'documentSelfie', extension: file.name.split('.')[1].toLowerCase() };
    }
    getImageFile(type, kycData) {
        if (type === 'documentFront') {
            return kycData.documentFrontImage.file;
        }
        else if (type === 'documentBack') {
            return kycData.documentBackImage.file;
        }
        else {
            return kycData.livePhoto.file;
        }
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.DriverApiService), i0.ɵɵinject(i4.StorageService), i0.ɵɵinject(i5.LoggerService), i0.ɵɵinject(i6.FileDataService)); }, token: RegistrationService, providedIn: "root" });
