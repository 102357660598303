import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tell-friend',
  templateUrl: './tell-friend.component.html',
  styleUrls: ['./tell-friend.component.scss']
})
export class TellFriendComponent implements OnInit {
  mailTo: string;

  constructor() {
    const urlSpace = '%20';
    const htmlNewLine = '%0D%0A';
    const subject = `FleetOperate${urlSpace}Join${urlSpace}Us`;
    let body = `Hey,${htmlNewLine}${htmlNewLine}FleetOperate Delivery is a simple, secure and easy way to provide delivery services to shippers while earning much more than the regular delivery apps.${htmlNewLine}${htmlNewLine}It gives you the best of both the worlds:${htmlNewLine}${htmlNewLine}
    1. Access NOT limited to delivery of a certain type of commodity.${htmlNewLine}${htmlNewLine}
    2. Complete control of your schedule.`;
    body += `${htmlNewLine}${htmlNewLine}`;
    body += `Join me at https://driver.delivery.fleetoperate.com/#/signin`;
    this.mailTo = `mailto:?subject=${subject}&body=${body}`;
  }

  ngOnInit() {}

  onSend(): void {
    location.href = this.mailTo;
  }
}
