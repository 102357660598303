import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TellAFriendRoutingModule } from './tell-a-friend-routing.module';
import { MaterialModule } from '../shared/material/material.module';
import { TellFriendComponent } from './tell-friend/tell-friend.component';

@NgModule({
  declarations: [TellFriendComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TellAFriendRoutingModule
  ]
})
export class TellAFriendModule { }

export { TellFriendComponent } from './tell-friend/tell-friend.component';
