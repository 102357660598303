import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { ResetPasswordModel } from '../shared/reset-password.model';
import { Response } from 'src/app/shared/services/response';
import { ResetPasswordService } from '../reset-password.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { ROUTE_ACCOUNT_SETTINGS } from 'src/app/shared/routes';

const RequiredFieldMessage = 'You must enter a value';
const PasswordsDoNotMatchMessage = 'Passwords do not match';
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  hideOldPassword: boolean;
  hideNewPassword: boolean;
  errorMessage: string;

  private subs = new SubSink();

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              private readonly resetPasswordService: ResetPasswordService,
              private readonly logger: LoggerService) {
    this.loading = false;
    this.errorMessage = undefined;
    this.hideOldPassword = true;
    this.hideNewPassword = true;
    this.form = this.createForm();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit(forgotPasswordForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.subs.add(this.resetPasswordService.resetPassword(model).subscribe((result: ResetPasswordModel) => {
      this.errorMessage = undefined;
      this.logger.log('Reset form');
      this.form.reset();
      forgotPasswordForm.resetForm();
      this.loading = false;
    }, (error: Response) => {
      this.errorMessage = error.message;
      this.loading = false;
    }));
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

  onNavigateToAccountSettings(): void {
    this.router.navigate([`/${ROUTE_ACCOUNT_SETTINGS}`]);
  }

  get oldPassword() { return this.form.get('oldPassword'); }
  get newPassword() { return this.form.get('newPassword'); }

  getOldPasswordErrorMessage() {
    const errors = this.oldPassword.errors;

    return errors.required ? RequiredFieldMessage :
      errors.minlength ? MinimumLengthFieldMessage(errors.minlength.requiredLength) : '';
  }

  getNewPasswordErrorMessage(): string {
    const errors = this.newPassword.errors;

    return errors.required ? RequiredFieldMessage :
      errors.minlength ? MinimumLengthFieldMessage(errors.minlength.requiredLength) : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]]
    });

    return form;
  }

  private prepareSaveModel(): ResetPasswordModel {
    const formModel = this.form.value;

    const model = {
      username: this.resetPasswordService.getDriver().userName,
      oldPassword: formModel.oldPassword as string,
      newPassword: formModel.newPassword as string
    } as ResetPasswordModel;

    return model;
  }
}
