import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Employment } from 'src/app/shared/models/employment.model';

const DELETE_MESSAGE = 'Are you sure you want to delete the employment?';

@Component({
  selector: 'app-employment-view',
  templateUrl: './employment-view.component.html',
  styleUrls: ['./employment-view.component.scss']
})
export class EmploymentViewComponent implements OnInit {
  @Input() model: Employment;
  @Output() updateEmploymentPressed: EventEmitter<Employment> = new EventEmitter<Employment>();
  @Output() deleteEmploymentPressed: EventEmitter<Employment> = new EventEmitter<Employment>();

  constructor() {
  }

  ngOnInit() {

  }

  onUpdateEmp() {
    this.updateEmploymentPressed.emit(this.model);
  }

  onDeleteEmp() {
    if (confirm(DELETE_MESSAGE)) {
      this.deleteEmploymentPressed.emit(this.model);
    }
  }
}
