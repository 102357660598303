import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_RESET_PASSWORD } from './shared/routes';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: ROUTE_RESET_PASSWORD, component: ResetPasswordComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResetPasswordRoutingModule { }
