import { HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { FleetoperateApiService } from '../services/fleetoperate-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/fleetoperate-api.service";
import * as i2 from "../../core/services/auth.service";
import * as i3 from "./file-data.module";
export class FileApiService {
    constructor(api, authService) {
        this.api = api;
        this.authService = authService;
    }
    uploadFile(file, url, httpOptions) {
        const defaultContentType = {
            'Content-Type': 'application/json'
        };
        if (!httpOptions) {
            httpOptions = {
                headers: new HttpHeaders(defaultContentType)
            };
        }
        else if (httpOptions && !httpOptions.headers) {
            httpOptions.headers = new HttpHeaders(defaultContentType);
        }
        else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
            httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
        }
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.put(`${url}`, file, undefined, httpOptions)));
    }
}
FileApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileApiService_Factory() { return new FileApiService(i0.ɵɵinject(i1.FleetoperateApiService), i0.ɵɵinject(i2.AuthService)); }, token: FileApiService, providedIn: i3.FileDataModule });
