<div fxLayoutAlign="start" fxLayout="column">
     <div class="header-navigation-back">
          <mat-icon (click)="onNavigateToSignUp();">arrow_back</mat-icon>
          <span fxFlex class="title">Privacy Statement</span>
     </div>

     <div fxLayout="column" fxLayoutAlign="center stretch">
       <div fxFlex
         fxLayout="column"
         class="padding-sides-2x">
          <span fxFlexAlign="center"></span>
          <p>These Terms of Use set out the terms and conditions under which individuals may use the website or mobile application and access the services offered by FleetOperate Inc. ("<strong>FleetOperate</strong>"). These Terms of Use comprise a legal agreement between FleetOperate and you, the person accessing and using the website or mobile application ("<strong>You</strong>" or "<strong>User</strong>"). FleetOperate owns, operates and maintains the FleetOperate.com website at FleetOperate.com, other subsidiary websites, including sitter.com (collectively referred to as "<strong>Website</strong>"), and the Sitter.com mobile application ("<strong>App</strong>"), Website and App collectively referred to as the "<strong>Platform</strong>". By downloading, accessing and/or using the Platform, you are deemed to have read Terms of Use and you agree to be bound by the Terms of Use which shall govern your access and use of the Platform and all applicable laws and regulations.</p>
          <p><strong>IF YOU DO NOT AGREE TO ALL OF THE TERMS OF USE SET OUT BELOW, YOU MAY NOT ACCESS OR USE THE WEBSITE OR APP, SHOULD NOT CLICK TO ACCEPT THESE TERMS AND SHOULD NOT PROCEED ANY FURTHER.</strong></p>
          <h1>Description of Services</h1>
          <p>FleetOperate's Services on FleetOperate allows individuals and/or entities providing their services ("<strong>Service Providers</strong>") to post information profiles on the Website to facilitate matching with those Users who are seeking services ("<strong>Service Seekers</strong>").</p>
          <p>FleetOperate's Services facilitate the matching of Service Seekers and Service Providers by providing search functions to allow Service Seekers to find the appropriate Service Provider based on their preferences and needs. Users can engage with one another without disclosure of their e-mail or phone number. FleetOperate reserves the right to re-post a User's Service advertisement on external third-party websites for the purposes of ensuring exposure of the User's request and/or provision of Services. Any such re-posting on an external third-party websites will not include Personal Information (as defined below) of the Users.</p>
          <p>FleetOperate is not a party to the relationship between the Service Seeker and Service Provider. FleetOperate shall not, in any way, be liable to a Service Seeker or Service Provider or any other third party, arising, either directly or indirectly from such employment or independent contractor agreement between the parties.</p>
          <p>FleetOperate does verify, review, evaluate, interview, screen or perform any background checks on some of its service providers or the content posted by the User on the Platform. You assume all risks associated with dealing with other persons whom you come in contact with through the Services of FleetOperate's Platform.</p>
          <p>Please refer to the Website regularly for further information about the Services provided.</p>
          <h1>Application Use</h1>
          <p>FleetOperate grants you the right to use the App for your personal use.</p>
          <p>You must comply with all applicable laws and third party terms of agreement when using the App (e.g. your wireless data service agreement). Your use of the App is at your own discretion and risk, and you are solely responsible for any damages to your hardware device(s) or loss of data that results from the download or use of the App.</p>
          <p>You acknowledge and agree that the App may not contain the same functionality available on the Website. We cannot guarantee the continuous, uninterrupted or error-free operation of the App or that the App will perform at a certain speed, as this depends on a number of factors beyond our control. You agree that downloading, accessing and use of the App is made available to you on an "as is" and "as available" basis for use at your sole risk.</p>
          <h1>Eligibility Criteria</h1>
          <p>To be eligible to use the Services offered by FleetOperate, you represent and warrant as follows:</p>
          <ol>
          <li>that you are eighteen (18) years of age or older on the date of creation of your User Account;</li>
          <li>if you are registering as Service Provider, that you are legally permitted to work in the jurisdiction where you are seeking employment; and</li>
          <li>neither you nor any member of your household may have ever been the subject of a complaint, restraining order or any other legal action or criminal offence involving violence, abuse, neglect, fraud, or any other offence that involves endangering the safety of others, and that you are not registered or currently required to register as a sex offender with any government agency in any jurisdiction.</li>
          </ol>
          <h1>User Accounts and Passwords</h1>
          <p>To access and use certain features of the Website, you will need to create a profile through the Website ("<strong>User Account</strong>"). As part of the process to create, register and maintain your User Account you must provide FleetOperate with certain current, complete, and accurate registration information, including your name, e-mail address, age, address, postal code/ZIP code, and phone number, and other data or information that has been requested by FleetOperate during the client account registration or renewal process ("<strong>Registration</strong>").</p>
          <p>In these Terms of Use, "<strong><em>Personal Information</em></strong>" means any information about an identifiable individual, such as your name, e-mail address, mailing addresses, gender, date of birth, any data about you that you elect to provide electronically through the Platform and any other information that identifies who you are. Registration Data and Personal Information will be used by FleetOperate solely in accordance with these Terms of Use and Company's&nbsp;<a href="https://careguide.com/privacy">Privacy Policy</a>&nbsp;(the "<strong>Privacy Policy</strong>"). In addition, if you are accessing FleetOperate's Services through the App, you may be asked permission to access to your location through your mobile device. Access to and use of your location information is consistent with the terms of our Privacy Policy.</p>
          <p>You agree that you will:</p>
          <ol>
          <li>maintain and update such Registration Data as necessary in order to keep such information current, complete, and accurate;</li>
          <li>maintain the confidentiality of any passwords or other account identifiers which you choose or that are assigned to you as a result of any registration or account creation with the Platform; and</li>
          <li>be responsible for all activities that occur under such password or account.</li>
          </ol>
          <p>Further, you agree to immediately notify FleetOperate of any unauthorized use of your password or account in the event that the confidentiality of your password or your account is compromised.</p>
          <p>Failure to comply with this paragraph may result in immediate termination of your account.</p>
          <h1>User Account Fees, Term and Payment</h1>
          <p>You understand that use of the Services may result in charges to you for the services or goods you receive ("Charges"). FleetOperate will receive and/or enable your payment of the applicable Charges for services or goods obtained through your use of the Services. Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges including a booking fee, municipal tolls, airport surcharges or processing fees for split payments.</p>
          <p>All Charges and payments will be enabled by FleetOperate using the preferred payment method designated in your Account, after which you will receive a receipt by email. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that FleetOperate may use a secondary payment method in your Account, if available. Charges paid by you are final and non-refundable, unless otherwise determined by FleetOperate.</p>
          <h1>Text Messaging and Telephone Calls.</h1>
          <p>You agree that FleetOperate may contact you by telephone or text messages (including by an automatic telephone dialing system) at any of the phone numbers provided by you or on your behalf in connection with an FleetOperate account, including for marketing purposes. You understand that you are not required to provide this consent as a condition of purchasing any property, goods or services. You also understand that you may opt out of receiving text messages from FleetOperate at any time contacting helpdesk from FleetOperate.com. If you do not choose to opt out, FleetOperate may contact you as outlined in its User Privacy Statement.&nbsp;</p>
          <h1>Personal Information</h1>
          <p>You agree that your use of the Website and the Services is subject to the terms of our&nbsp;<u>Privacy Policy</u>&nbsp;, which is hereby incorporated by reference into, and forms an integral part of, the Terms of Use or by using the Platform, you expressly consent to the collection, storage, use and disclosure of your information, including your Personal Information, according to the Privacy Policy.</p>
          <p>You represent and warrant to FleetOperate that you are at least 18 years of age and are fully authorized to provide to FleetOperate any and all Registration Data (including any Personal Information) for the purposes of these Terms of Use and that FleetOperate is authorized and permitted to use such data as and to the extent provided in these Terms of Use and the Privacy Policy.</p>
          <h1>Content and Use of Services</h1>
          <p>As a Service Seeker, Service Provider or any other User of the Services, you agree that the content of information and materials posted on the Website or App by You is accurate, current and complete. In addition, you represent and warrant as follows:</p>
          <ol>
          <li>You will use the Services provided by FleetOperate solely for yourself and are responsible for all activity and use of your account and may not assign or transfer your account to any other person.</li>
          <li>You have the right and authority to post all information you post about yourself or others.</li>
          <li>You will not provide inaccurate, misleading, defamatory or false information to FleetOperate or to any other User of the Website or App.</li>
          <li>You will not post any content (including, but not limited to, written material, text, photos, or graphical content) on the Website, or transmit to other users, that is defamatory, inaccurate, inappropriate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, defamatory, racially offensive, or illegal material, or any material that infringes or violates another party's rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity), or advocate, promote or assist any unlawful act.</li>
          <li>Your use of the Website, App and Services provided by FleetOperate are in accordance with any and all applicable laws and regulations.</li>
          </ol>
          <h1>Use License</h1>
          <p>FleetOperate hereby grants you a limited, non-exclusive, personal, non-transferable, non-sub-licensable and revocable right and license to</p>
          <p>access the Platform for the purpose of receiving the Services in accordance with these Terms of Use and</p>
          <ol>
          <li>access, view and print any information and documentation made available on the Website or App, for your personal, non-commercial and informational use only to assist you in the access and use of the Website or App and the Services.</li>
          <li>These Terms of Use do not grant you any other right or license, whether express or implied, by estoppel, or otherwise in or under any patent, trademark, copyright, or other intellectual property or proprietary right of FleetOperate or any third party.</li>
          </ol>
          <p>FleetOperate may terminate this license at any time for any reason whatsoever.</p>
          <h1>Intellectual Property</h1>
          <p>The Platform and all intellectual property rights in the Platform are owned by FleetOperate.</p>
          <p>FleetOperate reserves all intellectual property rights, including but not limited to, copyright, trade-marks, domain names, design rights, brand elements, database rights, patents, and all other intellectual property rights of any kind, whether registered or unregistered.</p>
          <h1>Trade-marks</h1>
          <p>All brand elements, company name, website names, logos, registered and unregistered trade-marks displayed on the Platform or used in connection with the Services are the trade-marks of FleetOperate (or its suppliers, partner businesses or third party licensors).</p>
          <p>Any use or misuse of any trade-marks or brand elements of FleetOperate or any other content appearing on the Platform or in connection with the Services except as provided in these Terms of Use or with the express written consent of FleetOperate is strictly prohibited.</p>
          <p>Nothing contained on the Platform shall be construed as an express or implied grant of any license or right to use any trade-mark or brand element without prior written consent of FleetOperate.</p>
          <h1>Use Restrictions</h1>
          <p>In addition to complying with these Terms of Use, you agree to use the Platform and Services for lawful purposes only and in a manner consistent with all applicable local, national or international laws and regulations, including all privacy and personal information laws.</p>
          <p>The Website and any Services shall not be used where, and to any extent, such use is prohibited by law.</p>
          <p>FleetOperate encourages all Users to report any misconduct, misuse or unlawful activities of other Users, including, but not limited to activities on the Platform and in connection with the Services provided.</p>
          <p style="text-align: left;">Please contact FleetOperate at support@FleetOperate.com.</p>
          <h1>Termination of Registration</h1>
          <p>FleetOperate reserves the right, in its sole discretion, to terminate your account or access to part or all of FleetOperate&rsquo;s Website , App and/or Services in the event that FleetOperate determines you are not eligible to use the Services or have violated any of the provisions of the Terms of Use, or have misused the Website, App or Services in any way.</p>
          <h1>Limitation of Liability</h1>
          <p>YOU ACKNOWLEDGE THAT ANY USE OF OR RELIANCE ON THIS WEBSITE OR THE SERVICES IS COMPLETELY AT YOUR OWN RISK.</p>
          <p>UNDER NO CIRCUMSTANCES SHALL FLEETOPERATE BE LIABLE TO YOU OR TO ANY OTHER PARTY, FOR ANY LOSSES, COSTS OR DAMAGES OF ANY KIND OR NATURE WHATSOEVER THAT ARE SUFFERED OR INCURRED IN ANY CONNECTION WITH THE USE OF (OR THE INABILITY TO USE) THE PLATFORM, SERVICES, OR ANY FLEETOPERATE CONTENT OR THIRD PARTY CONTENT (REGARDLESS OF THE FORM OF ACTION OR THEORY OF LIABILITY, INCLUDING FOR BREACH OF CONTRACT, TORT, NEGLIGENCE, EQUITY, STRICT LIABILITY, BY STATUTE OR OTHERWISE AND REGARDLESS OF THE OCCURRENCE OF A FUNDAMENTAL BREACH OR FAILURE OF ESSENTIAL PURPOSE).</p>
          <p>FleetOperate's Services facilitate the matching of Service Seekers and Service Providers by providing search functions to allow Service Seekers to find the appropriate Service Provider based on their preferences, needs, and location.</p>
          <p>FleetOperate is not a party to THE RELATIONSHIP BETWEEN SERVICE SEEKER AND SERVICE PROVIDER, and shall not, in any way, be liable to a Service Seeker or Service Provider or any other third party, arising, either directly or indirectly from such employment or independent contractor agreement between the parties.</p>
          <p>IN NO EVENT WHATSOEVER SHALL ANY OF FLEETOPERATE, ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AFFILIATES, AGENTS AND THIRD-PARTY CONTRACTORS, SUPPLIERS AND LICENSORS (COLLECTIVELY THE "COMPANY PARTIES") BE LIABLE FOR ANY SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL, INCIDENTAL OR INDIRECT DAMAGES OF ANY KIND OR NATURE WHATSOEVER THAT ARE SUFFERED OR INCURRED IN ANY CONNECTION WITH THE USE OF PLATFORM OR THE PROVISION OF THE SERVICES (INCLUDING IN ANY CONNECTION WITH THE TRANSMISSION OR DOWNLOADING OR STORAGE OF ANY DATA OR SUBMISSIONS TO OR FROM THE PLATFORM OR THE USE OF, OR RELIANCE ON, ANY FLEETOPERATE CONTENT OR OTHER INFORMATION OR DATA CONTAINED ON OR PROVIDED THROUGH THE PLATFORM, OR SERVICES, OR LOSS OF OR DAMAGE TO FILES OR DATA OR ANY COSTS OF RECOVERING OR REPRODUCING ANY FILES OR DATA OR LOSS OF USE OR LACK OF AVAILABILITY OF SERVICES OR ANY BUSINESS INTERRUPTION OR LOSS OF REVENUE OR PROFIT OR ANY OTHER ECONOMIC LOSS WHATSOEVER) HOWEVER CAUSED AND REGARDLESS OF THE FORM OR CAUSE OF ACTION AND WHETHER OR NOT FORESEEABLE, EVEN IF THE FLEETOPERATE PARTIES OR ANY OF THEM HAS BEEN INFORMED IN ADVANCE OR OUGHT REASONABLY TO HAVE KNOWN OF THE POTENTIAL FOR SUCH DAMAGES.</p>
          <h1>Indemnification</h1>
          <p>You agree to indemnify and hold FleetOperate, its parents, subsidiaries, affiliates, officers and employees, harmless from any claim, demand, or damage, including reasonable legal fees, asserted by any third party due to or arising out of the Users use of or conduct respecting the Services, and Platform.</p>
          <h1>Miscellaneous</h1>
          <h3>Governing Law</h3>
          <p>The Platform is administered by FleetOperate from a site that is located in Ontario, Canada. You acknowledge and agree that your use of the Platform and all of the communications, transmissions and transactions associated with the Website and/or App and the provision of the Services shall be deemed to have occurred in the Province of Ontario, Canada. You agree that these Terms of Use shall be exclusively governed by, construed and interpreted in accordance with the laws of the Province of Ontario, Canada and the federal laws of Canada applicable therein. In the event of a dispute under these Terms of Use, such dispute shall be resolved in the Courts of the Province of Manitoba.</p>
          <h3>Language</h3>
          <p>It is the express will of FleetOperate that these Terms of Use and all related documents on the Platform are in English.</p>
          <h3>Non-assignment</h3>
          <p>These Terms of Use are not assignable, transferable, or to be sub-licensed by you except with FleetOperate's prior written consent. FleetOperate may assign, transfer, or delegate any of its rights and obligations hereunder without your consent.</p>
          <h3>Modifications of Terms of Use</h3>
          <p>FleetOperate may revise these Terms of Use at any time without notice. By using the Platform, you are agreeing to be bound by the current version of the Terms of Use and Privacy Policy (last updated date listed at the top of the Terms of Use).</p>
          <p>The content and material appearing on the Platform could include technical, typographical or photographic errors. FleetOperate does not warrant that any of the materials provided are accurate or current.</p>
          <h3>Links to External Websites</h3>
          <p>The links on the Platform to other websites or resources, including those operated by parties other than FleetOperate are provided for your convenience. FleetOperate is not responsible for the availability of such websites or resources and does not endorse or accept responsibility for the content of such external websites or resources and has no responsibility for or control over the terms of service or privacy policy (if any) of the operators of the external websites or resources.</p>
          <p>Your access and viewing of any third party websites or resources is conducted at your own risk.</p>
          <h3>Website, App and Services Provided "As Is"</h3>
          <p>YOU UNDERSTAND AND AGREE THAT THE PLATFORM, THE SERVICES, ANY CONTENT AND OTHER INFORMATION, DATA AND MATERIALS AVAILABLE ON THE WEBSITE, APP OR PROVIDED AS PART OF THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.</p>

         
     </div>
</div>
