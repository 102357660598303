import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_SIGNUP } from '../shared/routes';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor(private readonly router: Router) { 
  }

  ngOnInit() {
  }
  onNavigateToSignUp(): void {
    this.router.navigate([`/${ROUTE_SIGNUP}`]);
  }
}
