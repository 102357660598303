<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateToProfile();">arrow_back</mat-icon>
    <span fxFlex class="title">Education</span>
    <button mat-button
            [disabled]="!form.dirty || !form.valid && !loading"
            class="header-action-button"
            (click)="onSubmit();">Save</button>
  </div>
  <div class="content padding-sides-1x">
    <div fxLayout="column" fxLayoutAlign="center stretch" class="component-container padding-top-x2">
      <mat-error *ngIf="errorMessage" class="error">
        {{errorMessage}}
      </mat-error>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
        <mat-form-field>
          <mat-label>Highest school grade completed</mat-label>
          <mat-select formControlName="highestGradeCompleted">
            <mat-option *ngFor="let grade of schoolGrades" [value]="grade">
              {{grade}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="highestGradeCompleted.touched && highestGradeCompleted.invalid">
            {{getHighestGradeCompletedErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Last school attended" formControlName="lastSchoolAttended">
          <mat-error *ngIf="lastSchoolAttended.touched && lastSchoolAttended.invalid">
            {{getLastSchoolAttendedErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Type of certificate/diploma" formControlName="certification">
          <mat-error *ngIf="certification.touched && certification.invalid">
            {{getCertificationErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Special courses or training" formControlName="specialCourses">
          <mat-error *ngIf="specialCourses.touched && specialCourses.invalid">
            {{getSpecialCoursesErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
