import { environment } from './../../../environments/environment';
import { FleetoperateApiService } from './fleetoperate-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./fleetoperate-api.service";
import * as i2 from "../../core/services/auth.service";
const PATH_DRIVERS = '/drivers';
const PATH_GET_DRIVER = PATH_DRIVERS + '/{id}';
const PATH_EDUCATION_CREATE = PATH_DRIVERS + '/{id}/education';
const PATH_EDUCATION_UPDATE = PATH_DRIVERS + '/{id}/education/{education_id}';
const PATH_EMPLOYMENT_CREATE = PATH_DRIVERS + '/{id}/employment';
const PATH_EMPLOYMENT_UPDATE = PATH_DRIVERS + '/{id}/employment/{employment_id}';
const PATH_EMPLOYMENT_DELETE = PATH_DRIVERS + '/{id}/employment';
const PATH_PREFERENCES_CREATE = PATH_DRIVERS + '/{id}/preference';
const PATH_PREFERENCES_UPDATE = PATH_DRIVERS + '/{id}/preference/{driver_preference_id}';
const PATH_PROFILE_PICTURE = PATH_DRIVERS + '/{id}/picture';
const PATH_DRIVING_EXPERIENCE_CREATE = PATH_DRIVERS + '/{id}/experience';
const PATH_DRIVING_EXPERIENCE_UPDATE = PATH_DRIVERS + '/{id}/experience/{driver_experience_id}';
const PATH_LICENSE = PATH_DRIVERS + '/{id}/verifications';
const PATH_HISTORY = PATH_DRIVERS + '/{id}/history';
export const DRIVER_ABSTRACT = 'DriverAbstract';
export const MEDICAL_CERTIFICATE = 'MedicalCertificate';
export const CRIMINALBACKGROUNDCHECK = 'CriminalBackgroundCheck';
export const DRIVE_TEST = 'RoadTestCertification';
export const IDENTITY_CHECK = 'KYC';
export const UPLOAD_STATUS = 'uploaded';
export const IDENTITY_CHECK_VERIFIED_STATUS = 'verified';
export const PROFILE_COMPLETE_STATUS = 'complete';
export const IDENTITY_CHECK_PENDING_STATUS = 'pending';
export class DriverApiService {
    constructor(api, authService) {
        this.api = api;
        this.authService = authService;
        this.apiUrl = environment.apiUrl;
    }
    getDriver() {
        const path = PATH_GET_DRIVER.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
    }
    updateDriver(driver) {
        let path = PATH_GET_DRIVER.replace('{id}', this.authService.driverID);
        path = path.replace('{driverid}', driver.id);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, driver, this.authService.token)));
    }
    createDriver(driver) {
        const path = PATH_DRIVERS.replace('{id}', this.authService.driverID);
        return this.api.post(`${this.apiUrl}${path}`, driver);
    }
    createEducation(education) {
        const path = PATH_EDUCATION_CREATE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, education, this.authService.token)));
    }
    updateEducation(education) {
        let path = PATH_EDUCATION_UPDATE.replace('{id}', this.authService.driverID);
        path = path.replace('{education_id}', education.id);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, education, this.authService.token)));
    }
    createEmployment(employment) {
        const path = PATH_EMPLOYMENT_CREATE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, employment, this.authService.token)));
    }
    updateEmployment(employment) {
        let path = PATH_EMPLOYMENT_UPDATE.replace('{id}', this.authService.driverID);
        path = path.replace('{employment_id}', employment.id);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, employment, this.authService.token)));
    }
    deleteEmployment(id) {
        const path = PATH_EMPLOYMENT_DELETE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.delete(`${this.apiUrl}${path}/${id}`, this.authService.token)));
    }
    createPreferences(preferences) {
        const path = PATH_PREFERENCES_CREATE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, preferences, this.authService.token)));
    }
    updatePreferences(preferences) {
        let path = PATH_PREFERENCES_UPDATE.replace('{id}', this.authService.driverID);
        path = path.replace('{driver_preference_id}', preferences.id);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, preferences, this.authService.token)));
    }
    getProfilePicture() {
        const path = PATH_PROFILE_PICTURE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
    }
    createProfilePicture(profilePictureRequest) {
        const path = PATH_PROFILE_PICTURE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, profilePictureRequest, this.authService.token)));
    }
    createDrivingExperience(drivingExperience) {
        const path = PATH_DRIVING_EXPERIENCE_CREATE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, drivingExperience, this.authService.token)));
    }
    updateDrivingExperience(drivingExperience) {
        let path = PATH_DRIVING_EXPERIENCE_UPDATE.replace('{id}', this.authService.driverID);
        path = path.replace('{driver_experience_id}', drivingExperience.id);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, drivingExperience, this.authService.token)));
    }
    submitLicense(license) {
        const path = PATH_LICENSE.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, license, this.authService.token)));
    }
    getHistory() {
        const path = PATH_HISTORY.replace('{id}', this.authService.driverID);
        return this.authService
            .isAuthenticated()
            .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
    }
}
DriverApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DriverApiService_Factory() { return new DriverApiService(i0.ɵɵinject(i1.FleetoperateApiService), i0.ɵɵinject(i2.AuthService)); }, token: DriverApiService, providedIn: "root" });
