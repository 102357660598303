import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentInit } from '@angular/core';
import { RegistrationService } from '../shared/registration.service';
import { SubSink } from 'subsink';
import { Store } from '@ngxs/store';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { map } from 'rxjs/operators';
import { Notification } from 'src/app/shared/models/notification.model';
import { DRIVER_ABSTRACT, UPLOAD_STATUS } from 'src/app/shared/services/driver-api.service';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-driver-abstract',
  templateUrl: './driver-abstract.component.html',
  styleUrls: ['./driver-abstract.component.scss']
})
export class DriverAbstractComponent implements OnInit {

  public driverAbstract: Notification;
  private subs = new SubSink();

  constructor(private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService) { }

  ngOnInit() {
    this.subs.add(this.registrationService.loadDriver().pipe(
      map(() => this.store.selectSnapshot(RegistrationState.notification))
    ).subscribe((notificationStateModel: NotificationStateModel) => {
      if (notificationStateModel && notificationStateModel.notifications) {
        this.driverAbstract = this.profileService.findNotification([...notificationStateModel.notifications], DRIVER_ABSTRACT, UPLOAD_STATUS);
      }
    }));
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }
}
