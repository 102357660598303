/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./toast.component";
import * as i11 from "@angular/material/snack-bar";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["fxFlexAlign", "center"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(2, 671744, null, 0, i4.DefaultFlexAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.FlexAlignStyleBuilder], i5.MediaMarshaller], { fxFlexAlign: [0, "fxFlexAlign"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "padding-left-sm"], ["fxFlex", ""], ["fxFlexAlign", "center"]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i4.DefaultFlexAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.FlexAlignStyleBuilder], i5.MediaMarshaller], { fxFlexAlign: [0, "fxFlexAlign"] }, null), i1.ɵdid(6, 671744, null, 0, i4.DefaultFlexDirective, [i1.ElementRef, i5.StyleUtils, i5.LAYOUT_CONFIG, i4.FlexStyleBuilder, i5.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.snackBarRef.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "center"; _ck(_v, 2, 0, currVal_2); var currVal_4 = "center"; _ck(_v, 5, 0, currVal_4); var currVal_5 = ""; _ck(_v, 6, 0, currVal_5); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.data.iconType; _ck(_v, 3, 0, currVal_3); var currVal_6 = _co.data.message; _ck(_v, 7, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 9).disabled || null); var currVal_8 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 11).inline; var currVal_10 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_9, currVal_10); }); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fleetoperate-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 114688, null, 0, i10.ToastComponent, [i11.MatSnackBarRef, i11.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastComponentNgFactory = i1.ɵccf("app-fleetoperate-toast", i10.ToastComponent, View_ToastComponent_Host_0, {}, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
