import { HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FleetoperateApiService } from '../services/fleetoperate-api.service';
import { HttpOptions } from '../services/http-options';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Response } from '../services/response';
import { FileDataModule } from './file-data.module';

@Injectable({
  providedIn: FileDataModule
})
export class FileApiService {
  constructor(private readonly api: FleetoperateApiService, private readonly authService: AuthService) {}

  uploadFile(file: object, url: string, httpOptions?: HttpOptions): Observable<Response> {
    const defaultContentType = {
      'Content-Type': 'application/json'
    };

    if (!httpOptions) {
      httpOptions = {
        headers: new HttpHeaders(defaultContentType)
      };
    } else if (httpOptions && !httpOptions.headers) {
      httpOptions.headers = new HttpHeaders(defaultContentType);
    } else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    }

    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${url}`, file, undefined, httpOptions)));
  }
}
