import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { CertificateType } from 'src/app/registration/shared/models/certificate-type.model';
import { Education } from 'src/app/shared/models/education.model';
import { environment } from './../../../environments/environment';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { concatMap } from 'rxjs/operators';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit, OnDestroy {
  schoolGrades: string[];
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  selectedGrade = 0;

  private subs = new SubSink();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly fb: FormBuilder,
    private readonly store: Store
  ) {
    this.loading = false;
    this.errorMessage = undefined;
    this.form = this.createForm();
    this.schoolGrades = Array(14)
      .fill(0)
      .map((x, index) => `${index + 1}`);
  }

  ngOnInit() {
    this.subs.add(
      this.registrationService.selectEducation().subscribe((education: Education) => {
        if (education.id) {
          this.populateForm(education);
          this.selectedGrade = Number(education.highestGradeCompleted);
        }
      })
    );
    this.loadDriver();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();
    if (model.id) {
      this.subs.add(
        this.registrationService.updateEducation(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.loading = false;
            this.onNavigateToProfile();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    } else {
      // TODO: Remove this the removal of id when API ignores it on POST.
      model.id = undefined;
      this.subs.add(
        this.registrationService.saveEducation(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.loading = false;
            this.onNavigateToProfile();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    }
  }

  get highestGradeCompleted() {
    return this.form.get('highestGradeCompleted');
  }
  get lastSchoolAttended() {
    return this.form.get('lastSchoolAttended');
  }
  get certification() {
    return this.form.get('certification');
  }
  get specialCourses() {
    return this.form.get('specialCourses');
  }

  getHighestGradeCompletedErrorMessage(): string {
    return this.highestGradeCompleted.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getLastSchoolAttendedErrorMessage(): string {
    return this.lastSchoolAttended.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getCertificationErrorMessage(): string {
    return this.certification.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getSpecialCoursesErrorMessage(): string {
    return this.specialCourses.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      highestGradeCompleted: ['', Validators.required],
      lastSchoolAttended: ['', Validators.required],
      certification: ['', Validators.required],
      specialCourses: [''],
      id: ['']
    });

    return form;
  }

  private prepareSaveModel(): Education {
    const formModel = this.form.value;
    const model = {
      highestGradeCompleted: formModel.highestGradeCompleted as string,
      lastSchoolAttended: formModel.lastSchoolAttended as string,
      certification: formModel.certification as string,
      specialCourses: formModel.specialCourses as string,
      id: formModel.id as string
    };

    return model as Education;
  }

  private populateForm(model: Education): void {
    this.form.setValue({
      highestGradeCompleted: model.highestGradeCompleted,
      lastSchoolAttended: model.lastSchoolAttended,
      certification: model.certification,
      specialCourses: model.specialCourses,
      id: model.id
    });
  }

  private loadDriver(): void {
    this.subs.add(this.registrationService.loadDriver().subscribe());
  }
}
