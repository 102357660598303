import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/services/logger.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../shared/services/fleetoperate-api.service";
import * as i3 from "../../core/services/auth.service";
import * as i4 from "../../registration/shared/registration.service";
import * as i5 from "../../core/services/logger.service";
const PATH_LOGIN = '/auth/login';
export class AuthenticationService {
    constructor(router, api, authService, registrationService, logger) {
        this.router = router;
        this.api = api;
        this.authService = authService;
        this.registrationService = registrationService;
        this.logger = logger;
        this.apiUrl = environment.apiUrl;
    }
    login(model) {
        return this.api.post(this.apiUrl + PATH_LOGIN, model).pipe(tap((response) => {
            const token = response.data;
            const validToken = this.authService.authenticate(token, model.username);
            validToken ? this.logger.log(`Token is valid`) : this.logger.log(`Token is invalid: ${token}`);
        }), mergeMap(() => {
            return this.registrationService.loadDriver();
        }), tap(() => this.navigateToReturnUrl()), catchError(err => {
            this.logger.error('Caught authentication error, will logout and rethrow error.', err);
            this.authService.unauthenticate();
            this.registrationService.clearDriver();
            return throwError(err);
        }));
    }
    navigateToReturnUrl() {
        const returnUrl = this.authService.returnUrl;
        this.authService.clearReturnUrl();
        returnUrl ? this.router.navigate([`${returnUrl}`]) : this.navigateToProfile();
    }
    navigateToProfile() {
        this.router.navigate([`/`]);
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.FleetoperateApiService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.RegistrationService), i0.ɵɵinject(i5.LoggerService)); }, token: AuthenticationService, providedIn: "root" });
