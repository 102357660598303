import { Injectable, Inject } from '@angular/core';
import { Token } from './token';
import { LoggerService } from './logger.service';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { environment } from 'src/environments/environment';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Response } from 'src/app/shared/services/response';
import { StorageService } from 'src/app/shared/storage/storage.service';

const PATH_REFRESH_TOKEN = '/auth/token/refresh';
const KEY_ACCESS_TOKEN = 'access-token';
const KEY_USERNAME = 'username';
const tokenVerificationTolerance = 5;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl: string;
  returnUrl: string;

  token: Token;
  private username: string;
  private readonly accessTokenKey: string;
  private readonly usernameKey: string;

  constructor(
    private readonly api: FleetoperateApiService,
    private readonly storageService: StorageService,
    private readonly logger: LoggerService,
    @Inject('appName') private readonly appName: string
  ) {
    this.apiUrl = environment.apiUrl;
    this.returnUrl = undefined;
    this.accessTokenKey = `${this.appName}-${KEY_ACCESS_TOKEN}`;
    this.usernameKey = `${this.appName}-${KEY_USERNAME}`;

    const accessToken = this.storageService.find(this.accessTokenKey) as Token;
    const username = this.storageService.find(this.usernameKey) as string;
    if (accessToken && username) {
      this.token = accessToken;
      this.username = username;
    }
  }

  get driverID(): string {
    return this.token ? this.token.id : '';
  }

  isAuthenticated(): Observable<boolean> {
    if (!this.verifyToken(this.token)) {
      return of(false);
    }

    if (this.verifyExpiration(this.token.expiration)) {
      return of(true);
    }

    return this.refreshToken(this.token, this.username).pipe(
      map((refreshedToken: Token) => {
        if (this.verifyExpiration(refreshedToken.expiration)) {
          this.storageService.store(this.accessTokenKey, refreshedToken);
          this.storageService.store(this.usernameKey, this.username);
          this.token = refreshedToken;
          return true;
        }

        this.clearToken();
        return false;
      })
    );
  }

  authenticate(token: Token, username: string): boolean {
    if (!this.verifyToken(token)) {
      return false;
    }

    if (this.verifyExpiration(token.expiration)) {
      this.storageService.store(this.accessTokenKey, token);
      this.storageService.store(this.usernameKey, username);
      this.token = token;
      this.username = username;
      return true;
    }

    this.clearToken();
    return false;
  }

  unauthenticate(): void {
    this.clearToken();
  }

  clearReturnUrl(): void {
    this.returnUrl = undefined;
  }

  private verifyToken(token: Token): boolean {
    if (!token || !token.accessToken || !token.expiration) {
      return false;
    }

    return true;
  }

  private verifyExpiration(expiration: number): boolean {
    const expirationDate = new Date(expiration * 1000);

    const now = new Date();
    now.setMinutes(now.getMinutes() + tokenVerificationTolerance);

    const valid = expirationDate >= now;

    return valid;
  }

  private refreshToken(token: Token, username: string): Observable<Token> {
    const model = { username: username, refreshToken: token.refreshToken };
    // TODO: Need to verify the failure scenarios work. Does the user get taken to the login screen?
    return this.api.post(this.apiUrl + PATH_REFRESH_TOKEN, model).pipe(
      map((response: Response) => {
        const tokenResponse = response.data as Token;
        const validToken = this.authenticate(tokenResponse, model.username);
        validToken
          ? this.logger.log(`Refreshed token is valid`)
          : this.logger.log(`Refreshed token is invalid: ${tokenResponse}`);
        return tokenResponse;
      }),
      catchError(err => {
        this.logger.error('Caught error while refreshing token, will logout and rethrow error.', err);
        this.unauthenticate();
        return throwError(err);
      })
    );
  }

  private clearToken(): void {
    this.storageService.remove(this.accessTokenKey);
    this.storageService.remove(this.usernameKey);
    this.token = undefined;
    this.username = undefined;
  }
}
