import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../services/response';
import { environment } from './../../../environments/environment';
import { FleetoperateApiService } from './fleetoperate-api.service';
import { Education } from 'src/app/shared/models/education.model';
import { Employment } from 'src/app/shared/models/employment.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { License } from '../models/license.model';
import { mergeMap } from 'rxjs/operators';
import { Driver } from 'src/app/shared/models/driver.model';
import { ProfilePictureRequest } from '../models/profile-picture-request.model';
import { HttpOptions } from './http-options';
import { HttpHeaders } from '@angular/common/http';

const PATH_DRIVERS = '/drivers';
const PATH_GET_DRIVER = PATH_DRIVERS + '/{id}';
const PATH_EDUCATION_CREATE = PATH_DRIVERS + '/{id}/education';
const PATH_EDUCATION_UPDATE = PATH_DRIVERS + '/{id}/education/{education_id}';
const PATH_EMPLOYMENT_CREATE = PATH_DRIVERS + '/{id}/employment';
const PATH_EMPLOYMENT_UPDATE = PATH_DRIVERS + '/{id}/employment/{employment_id}';
const PATH_EMPLOYMENT_DELETE = PATH_DRIVERS + '/{id}/employment';
const PATH_PREFERENCES_CREATE = PATH_DRIVERS + '/{id}/preference';
const PATH_PREFERENCES_UPDATE = PATH_DRIVERS + '/{id}/preference/{driver_preference_id}';
const PATH_PROFILE_PICTURE = PATH_DRIVERS + '/{id}/picture';
const PATH_DRIVING_EXPERIENCE_CREATE = PATH_DRIVERS + '/{id}/experience';
const PATH_DRIVING_EXPERIENCE_UPDATE = PATH_DRIVERS + '/{id}/experience/{driver_experience_id}';

const PATH_LICENSE = PATH_DRIVERS + '/{id}/verifications';
const PATH_HISTORY = PATH_DRIVERS + '/{id}/history';

export const DRIVER_ABSTRACT = 'DriverAbstract';
export const MEDICAL_CERTIFICATE = 'MedicalCertificate';
export const CRIMINALBACKGROUNDCHECK = 'CriminalBackgroundCheck';
export const DRIVE_TEST = 'RoadTestCertification';
export const IDENTITY_CHECK = 'KYC';
export const UPLOAD_STATUS = 'uploaded';
export const IDENTITY_CHECK_VERIFIED_STATUS = 'verified';
export const PROFILE_COMPLETE_STATUS = 'complete';
export const IDENTITY_CHECK_PENDING_STATUS = 'pending';

@Injectable({
  providedIn: 'root'
})
export class DriverApiService {
  apiUrl: string;

  constructor(private readonly api: FleetoperateApiService, private readonly authService: AuthService) {
    this.apiUrl = environment.apiUrl;
  }

  getDriver(): Observable<any> {
    const path = PATH_GET_DRIVER.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  updateDriver(driver: Driver): Observable<any> {
    let path = PATH_GET_DRIVER.replace('{id}', this.authService.driverID);
    path = path.replace('{driverid}', driver.id);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, driver, this.authService.token)));
  }

  createDriver(driver: Driver): Observable<any> {
    const path = PATH_DRIVERS.replace('{id}', this.authService.driverID);
    return this.api.post(`${this.apiUrl}${path}`, driver);
  }

  createEducation(education: Education): Observable<any> {
    const path = PATH_EDUCATION_CREATE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, education, this.authService.token)));
  }

  updateEducation(education: Education): Observable<any> {
    let path = PATH_EDUCATION_UPDATE.replace('{id}', this.authService.driverID);
    path = path.replace('{education_id}', education.id);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, education, this.authService.token)));
  }

  createEmployment(employment: Employment): Observable<any> {
    const path = PATH_EMPLOYMENT_CREATE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, employment, this.authService.token)));
  }

  updateEmployment(employment: Employment): Observable<any> {
    let path = PATH_EMPLOYMENT_UPDATE.replace('{id}', this.authService.driverID);
    path = path.replace('{employment_id}', employment.id);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, employment, this.authService.token)));
  }

  deleteEmployment(id: string): Observable<any> {
    const path = PATH_EMPLOYMENT_DELETE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.delete(`${this.apiUrl}${path}/${id}`, this.authService.token)));
  }

  createPreferences(preferences: Preferences): Observable<any> {
    const path = PATH_PREFERENCES_CREATE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, preferences, this.authService.token)));
  }

  updatePreferences(preferences: Preferences): Observable<any> {
    let path = PATH_PREFERENCES_UPDATE.replace('{id}', this.authService.driverID);
    path = path.replace('{driver_preference_id}', preferences.id);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, preferences, this.authService.token)));
  }

  getProfilePicture(): Observable<any> {
    const path = PATH_PROFILE_PICTURE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  createProfilePicture(profilePictureRequest: ProfilePictureRequest): Observable<Response> {
    const path = PATH_PROFILE_PICTURE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, profilePictureRequest, this.authService.token)));
  }

  createDrivingExperience(drivingExperience: DrivingExperience): Observable<any> {
    const path = PATH_DRIVING_EXPERIENCE_CREATE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, drivingExperience, this.authService.token)));
  }

  updateDrivingExperience(drivingExperience: DrivingExperience): Observable<any> {
    let path = PATH_DRIVING_EXPERIENCE_UPDATE.replace('{id}', this.authService.driverID);
    path = path.replace('{driver_experience_id}', drivingExperience.id);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, drivingExperience, this.authService.token)));
  }

  submitLicense(license: License): Observable<Response> {
    const path = PATH_LICENSE.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, license, this.authService.token)));
  }

  getHistory(): Observable<any> {
    const path = PATH_HISTORY.replace('{id}', this.authService.driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }
}
