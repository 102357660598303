<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
    <div fxLayout class="header-navigation-back">
      <mat-icon (click)="onNavigateToProfile();">arrow_back</mat-icon>
      <span fxFlex class="title">Driving Experience</span>
      <button mat-button
              [disabled]="(!form.dirty || !form.valid) && !loading"
              class="header-action-button"
              (click)="onSubmit();">Save</button>
    </div>
    <div class="content">
      <div fxLayout="column" fxLayoutAlign="center stretch" class="component-container padding-top-x2">
        <mat-error *ngIf="errorMessage" class="error">
          {{errorMessage}}
        </mat-error>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
          <div class="whiteBackground">
            <mat-checkbox class="full-width" formControlName="drivingGraduate" labelPosition="after">I am a driving school graduate</mat-checkbox>
          </div>
          <div class="padding-top-x1">
            <label>I am now, a</label>
            <div class="full-width whiteBackground">
              <mat-checkbox formControlName="companyDriver" class="full-width" labelPosition="after">Company Driver</mat-checkbox>
              <mat-checkbox formControlName="studentDriver" class="full-width" labelPosition="after">Student Driver</mat-checkbox>
              <mat-checkbox class="full-width" formControlName="ownerOperator" labelPosition="after">Owner Operator</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" class="padding-bottom-x1 whiteBackground">
              <label class="full-width experience-label">Years of experience</label>
              <mat-form-field class="full-width block experience-field">
                <input matInput type="number" min="1" max="50" placeholder="" formControlName="yearsOfExperience" required><br />
                <mat-error *ngIf="yearsOfExperience.touched && yearsOfExperience.invalid">
                  {{getYearsOfExperienceErrorMessage()}}
                </mat-error>
              </mat-form-field>
          </div>
          <div class="container padding-top-x1">
            <label class="block" (click)="onToggleStraightTruck();">Straight Truck
              <mat-icon *ngIf="!showStraightTruck">expand_more</mat-icon><mat-icon *ngIf="showStraightTruck">expand_less</mat-icon>
            </label>
            <div class="truck-type-container whiteBackground" [ngClass]="{'block': showStraightTruck, 'display-none': !showStraightTruck }">
              <app-truck-type [group]="form.get('straightTruck')"></app-truck-type>
            </div>
            <label class="block" (click)="onToggleTractor();">Tractor and Semi-Trailer
                <mat-icon *ngIf="!showTractor">expand_more</mat-icon><mat-icon *ngIf="showTractor">expand_less</mat-icon>
            </label>
            <div class="truck-type-container whiteBackground" [ngClass]="{'block': showTractor, 'display-none': !showTractor }">
              <app-truck-type [group]="form.get('tractorAndSemiTrailer')"></app-truck-type>
            </div>
            <label class="block" (click)="onToggleTractorTwo();">Tractor-Two Trailers
                <mat-icon *ngIf="!showTractorTwo">expand_more</mat-icon><mat-icon *ngIf="showTractorTwo">expand_less</mat-icon>
            </label>
            <div class="truck-type-container whiteBackground" [ngClass]="{'block': showTractorTwo, 'display-none': !showTractorTwo }">
              <app-truck-type [group]="form.get('tractorTwoTrailers')"></app-truck-type>
            </div>
            <label class="block" (click)="onToggleOthers();">Others
                <mat-icon *ngIf="!showOthers">expand_more</mat-icon><mat-icon *ngIf="showOthers">expand_less</mat-icon>
            </label>
            <div class="truck-type-container whiteBackground" [ngClass]="{'block': showOthers, 'display-none': !showOthers }">
              <app-truck-type [group]="form.get('others')"></app-truck-type>
            </div>
          </div>
        </form>
      </div>
      <br />
    </div>
  </div>
