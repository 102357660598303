import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SubSink } from 'subsink';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { environment } from 'src/environments/environment.dev';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly store: Store
  ) {
    this.form = this.createForm();
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit() {
    this.subs.add(
      this.registrationService.selectPreferences().subscribe((preferences: Preferences) => {
        if (preferences.id) {
          this.populateForm(preferences);
        }
      })
    );
    this.loadDriver();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    if (!model.id) {
      this.subs.add(
        this.registrationService.savePreferences(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.loading = false;
            this.onNavigateToProfile();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    } else {
      this.subs.add(
        this.registrationService.updatePreferences(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.loading = false;
            this.onNavigateToProfile();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    }
  }

  get hhg() {
    return this.form.get('hhg');
  }
  get reefer() {
    return this.form.get('reefer');
  }
  get hazmat() {
    return this.form.get('hazmat');
  }
  get tanker() {
    return this.form.get('tanker');
  }
  get carHauling() {
    return this.form.get('carHauling');
  }
  get flatBed() {
    return this.form.get('flatBed');
  }
  get van() {
    return this.form.get('van');
  }
  get specialized() {
    return this.form.get('specialized');
  }
  get lcv() {
    return this.form.get('lcv');
  }
  get monday() {
    return this.form.get('monday');
  }
  get tuesday() {
    return this.form.get('tuesday');
  }
  get wednesday() {
    return this.form.get('wednesday');
  }
  get thursday() {
    return this.form.get('thursday');
  }
  get friday() {
    return this.form.get('friday');
  }
  get saturday() {
    return this.form.get('saturday');
  }
  get sunday() {
    return this.form.get('sunday');
  }

  onNavigateToProfile() {
    this.registrationService.navigateToProfile();
  }

  onAnyChecked($event) {
    const value: boolean = $event.checked;
    this.form.get('hhg').setValue(value);
    this.form.get('reefer').setValue(value);
    this.form.get('hazmat').setValue(value);
    this.form.get('tanker').setValue(value);
    this.form.get('carHauling').setValue(value);
    this.form.get('flatBed').setValue(value);
    this.form.get('van').setValue(value);
    this.form.get('specialized').setValue(value);
    this.form.get('lcv').setValue(value);
    this.form.markAsDirty();
  }

  onAllChecked($event) {
    const value: boolean = $event.checked;
    this.form.get('monday').setValue(value);
    this.form.get('tuesday').setValue(value);
    this.form.get('wednesday').setValue(value);
    this.form.get('thursday').setValue(value);
    this.form.get('friday').setValue(value);
    this.form.markAsDirty();
  }

  onBothChecked($event) {
    const value: boolean = $event.checked;
    this.form.get('saturday').setValue(value);
    this.form.get('sunday').setValue(value);
    this.form.markAsDirty();
  }

  private prepareSaveModel(): Preferences {
    const formModel = this.form.value;
    const model = {
      id: formModel.id,
      truckType: {
        hhg: formModel.hhg as boolean,
        reefer: formModel.reefer as boolean,
        hazmat: formModel.hazmat as boolean,
        tanker: formModel.tanker as boolean,
        carHauling: formModel.carHauling as boolean,
        flatBed: formModel.flatBed as boolean,
        van: formModel.van as boolean,
        specialized: formModel.specialized as boolean,
        lcv: formModel.lcv as boolean
      },
      day: {
        monday: formModel.monday as boolean,
        tuesday: formModel.tuesday as boolean,
        wednesday: formModel.wednesday as boolean,
        thursday: formModel.thursday as boolean,
        friday: formModel.friday as boolean,
        saturday: formModel.saturday as boolean,
        sunday: formModel.sunday as boolean
      }
    };

    return model as Preferences;
  }

  private populateForm(model: Preferences): void {
    this.form.setValue({
      id: model.id,
      hhg: model.truckType.hhg,
      reefer: model.truckType.reefer,
      hazmat: model.truckType.hazmat,
      tanker: model.truckType.tanker,
      carHauling: model.truckType.carHauling,
      flatBed: model.truckType.flatBed,
      van: model.truckType.van,
      specialized: model.truckType.specialized,
      lcv: model.truckType.lcv,
      monday: model.day.monday,
      tuesday: model.day.tuesday,
      wednesday: model.day.wednesday,
      thursday: model.day.thursday,
      friday: model.day.friday,
      saturday: model.day.saturday,
      sunday: model.day.sunday
    });
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      hhg: [''],
      reefer: [''],
      hazmat: [''],
      tanker: [''],
      carHauling: [''],
      flatBed: [''],
      van: [''],
      specialized: [''],
      lcv: [''],
      monday: [''],
      tuesday: [''],
      wednesday: [''],
      thursday: [''],
      friday: [''],
      saturday: [''],
      sunday: [''],
      id: ['']
    });

    return form;
  }

  private loadDriver(): void {
    this.subs.add(this.registrationService.loadDriver().subscribe());
  }
}
