/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { RegistrationService } from '../shared/registration.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Driver } from 'src/app/shared/models/driver.model';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/profile/profile.service';
import { first } from 'rxjs/operators';

const REQUIRED_FIELD_MESSAGE = 'Required';
const validAddressMessage = 'Please enter a full valid address';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  maxDate: Date = new Date();
  phone: string;
  email: string;
  name: string;
  driverID: string;
  isPersonalInfoCompleted: boolean;
  private driver: Driver;
  @ViewChild('addressText', { static: false }) addressText: any;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly zone: NgZone,
    private readonly fb: FormBuilder,
    private readonly profileService: ProfileService
  ) {
    this.loading = false;
    this.errorMessage = undefined;
    this.form = this.createForm();
  }

  ngOnInit() {
    this.driver = this.registrationService.getDriver();
    if (this.driver) {
      this.driverID = this.driver.driverID;
      this.name = `${this.driver.firstName} ${this.driver.lastName}`;
      this.email = this.driver.email;
      this.phone = this.driver.phoneNumber;
    }

    if (this.profileService.isPersonalInfoCompleted(this.driver)) {
      this.populateForm(this.driver);
    }
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete(this.addressText);
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.errorMessage = undefined;
    const driverModel = this.prepareSaveModel(this.driver, this.form);
    this.registrationService
      .updateDriver(driverModel)
      .pipe(first())
      .subscribe(
        (driver: Driver) => {
          this.driver = driver;
          this.errorMessage = undefined;
          this.loading = false;
          this.onNavigateToProfile();
        },
        (error: string) => {
          this.errorMessage = environment.errorMessage;
          this.loading = false;
        }
      );
  }

  get licenseType() {
    return this.form.get('licenseType');
  }
  get issuingAuthority() {
    return this.form.get('issuingAuthority');
  }
  get dateOfBirth() {
    return this.form.get('dateOfBirth');
  }
  get gender() {
    return this.form.get('gender');
  }
  get address() {
    return this.form.get('address');
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      licenseType: ['', Validators.required],
      issuingAuthority: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      address: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(
            '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
          )
        ]
      ],
      id: ['']
    });

    return form;
  }
  getIssuingAuthorityErrorMessage(): string {
    return this.issuingAuthority.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getLicenseTypeErrorMessage(): string {
    return this.licenseType.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getDateOfBirthErrorMessage(): string {
    return this.dateOfBirth.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getGenderErrorMessage(): string {
    return this.gender.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getAddressErrorMessage(): string {
    return this.address.errors.required
      ? REQUIRED_FIELD_MESSAGE
      : this.address.errors.pattern
      ? validAddressMessage
      : this.address.errors.maxlength
      ? MaximumLengthFieldMessage(this.address.errors.maxlength.requiredLength)
      : '';
  }

  private prepareSaveModel(driver: Driver, form: FormGroup): Driver {
    const formModel = form.value;

    const formData = {
      birthday: formModel.dateOfBirth,
      gender: formModel.gender,
      address: formModel.address,
      licenseType: formModel.licenseType,
      issuingAuthority: formModel.issuingAuthority
    } as Driver;

    const driverToSave = Object.assign({}, driver, formData);

    return driverToSave;
  }
  private populateForm(model: Driver): void {
    this.form.setValue({
      gender: model ? model.gender : '',
      licenseType: model ? model.licenseType : '',
      issuingAuthority: model ? model.issuingAuthority : '',
      dateOfBirth: model ? model.birthday : '',
      address: model ? model.address : '',
      id: model ? model.id : ''
    });
  }

  private getPlaceAutocomplete(addresstext: any): void {
    const autocomplete = new google.maps.places.Autocomplete(addresstext.nativeElement, {
      componentRestrictions: {
        country: ['US', 'CA']
      },
      types: []
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place) {
        this.zone.run(() => {
          this.form.patchValue({ address: place.formatted_address });
        });
      }
    });
  }
}
