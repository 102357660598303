export const environment = {
  production: true,
  // apiUrl: 'http://fleetoperate.mockable.io',
  webcamDefaultWidth: '360',
  webcamDefaultHeight: '640',
  errorMessage: 'There was an error. Please try again.',
  apiUrl: 'https://dev-api.delivery.fleetoperate.com',
  ribbonText: 'Delivery - Dev',
  ribbonColour: 'green'
};
