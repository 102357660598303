import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { SignInModel } from './sign-in-model';
import { AuthService } from 'src/app/core/services/auth.service';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Response } from 'src/app/shared/services/response';
import { Token } from 'src/app/core/services/token';
import { RegistrationService } from 'src/app/registration/shared/registration.service';

const PATH_LOGIN = '/auth/login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl: string;

  constructor(private readonly router: Router,
              private readonly api: FleetoperateApiService,
              private readonly authService: AuthService,
              private readonly registrationService: RegistrationService,
              private readonly logger: LoggerService) {
    this.apiUrl = environment.apiUrl;
  }

  login(model: SignInModel): Observable<any> {
    return this.api.post(this.apiUrl + PATH_LOGIN, model).pipe(
      tap((response: Response) => {
        const token = response.data as Token;
        const validToken = this.authService.authenticate(token, model.username);
        validToken ? this.logger.log(`Token is valid`) : this.logger.log(`Token is invalid: ${token}`);
      }),
      mergeMap(() => {
        return this.registrationService.loadDriver();
      }),
      tap(() => this.navigateToReturnUrl()),
      catchError(err => {
          this.logger.error('Caught authentication error, will logout and rethrow error.', err);
          this.authService.unauthenticate();
          this.registrationService.clearDriver();
          return throwError(err);
      })
    );
  }

  navigateToReturnUrl(): void {
    const returnUrl = this.authService.returnUrl;
    this.authService.clearReturnUrl();

    returnUrl ? this.router.navigate([`${returnUrl}`]) : this.navigateToProfile();
  }

  private navigateToProfile(): void {
    this.router.navigate([`/`]);
  }
}
