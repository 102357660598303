import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, first } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile/profile.service';
import { Notification } from 'src/app/shared/models/notification.model';
import {
  IDENTITY_CHECK,
  IDENTITY_CHECK_VERIFIED_STATUS,
  IDENTITY_CHECK_PENDING_STATUS
} from 'src/app/shared/services/driver-api.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { RegistrationState, NotificationStateModel } from 'src/app/registration/state/registration.state';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-license-status',
  templateUrl: './license-status.component.html',
  styleUrls: ['./license-status.component.scss']
})
export class LicenseStatusComponent implements OnInit {
  html: any;
  contactEmail = 'support@FleetOperate.com';
  driveTest: Notification;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.registrationService
      .loadDriver()
      .pipe(
        first(),
        map(() => this.store.selectSnapshot(RegistrationState.notification))
      )
      .subscribe((notificationStateModel: NotificationStateModel) => {
        if (notificationStateModel && notificationStateModel.notifications) {
          this.checkKYCStatus([...notificationStateModel.notifications]);
        }
      });
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  private checkKYCStatus(notifications: Notification[]): void {
    const supportMessage = `If you need further assistance, please get in touch with one of our support group at <a href="mailto:${this.contactEmail}">${this.contactEmail}</a>`;

    if (this.profileService.findNotification(notifications, IDENTITY_CHECK, IDENTITY_CHECK_VERIFIED_STATUS)) {
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p><b>You have successfully completed the Identity Check for your profile.</b></p><p>${supportMessage}</p>`
      );
    } else if (this.profileService.findNotification(notifications, IDENTITY_CHECK, IDENTITY_CHECK_PENDING_STATUS)) {
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p><b>Your Identity Check verification is in process.</b></p><p>${supportMessage}</p>`
      );
    } else {
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p><b>Oops, something went wrong.</b></p> <p>Please get in touch with one of our support group at <a href="mailto:${this.contactEmail}">${this.contactEmail}</a> to resolve the issue.</p>`
      );
    }
  }
}
