<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-text">
  <div class="header-navigation-back">
    <span fxFlex class="title">Identity Check</span>
  </div>

  <div fxLayoutAlign="center" fxLayout="column" fxFlex>
    <span class="header-title padding-top-lg margin-left-sm"
      >ALL SET! <br /><span>Check back to see the results.</span></span
    >
  </div>
  <div><app-actions [actions]="actions"></app-actions></div>
</div>
