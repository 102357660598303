import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OverflowMenuComponent } from '../overflow-menu/overflow-menu.component';
import { FrameNavigationService } from '../shared/frame-navigation.service';
import { SubSink } from 'subsink';
import { ROUTE_LOADS, ROUTE_ROOT, ROUTE_PROFILE } from 'src/app/shared/routes';


class TabNavLink {
  title: string;
  icon: string;
  link: string;
  clickHandler: () => void;
  svgIcon: boolean;
  active: boolean;
}

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit, OnDestroy {

  links: TabNavLink[] = [];
  activeRoute: string;
  background = '#0B184E';

  private subs: SubSink = new SubSink();

  constructor(private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly bottomSheet: MatBottomSheet,
    private readonly frameNavigationService: FrameNavigationService) {
    this.links.push({ title: 'Dashboard', icon: 'dashboard', link: `${ROUTE_ROOT}`, clickHandler: () => this.navigateToDashboard(), svgIcon: false } as TabNavLink);
    this.links.push({ title: 'Profile', icon: 'person', link: `/${ROUTE_PROFILE}`, clickHandler: () => this.navigateToProfile(), svgIcon: false } as TabNavLink);
    this.links.push({ title: 'Loads', icon: 'loads', link: `/${ROUTE_LOADS}`, clickHandler: () => this.navigateToLoads(), svgIcon: true } as TabNavLink);
  }

  ngOnInit() {
    this.determineActiveLink();
    this.subs.add(this.router.events.subscribe(val => {
      this.determineActiveLink();
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onOpenOverflowMenu(): void {
    this.bottomSheet.open(OverflowMenuComponent);
  }

  private navigateToDashboard(): void {
    this.router.navigate(['/']);
  }

  private navigateToProfile(): void {
    this.frameNavigationService.navigateToProfile();
  }

  private navigateToLoads(): void {
    this.frameNavigationService.navigateToLoads();
  }

  private determineActiveLink(): void {
    this.links.forEach((link: TabNavLink) => link.active = false);
    if (this.location.path() !== ROUTE_ROOT) {
      this.activeRoute = this.location.path();
      this.links.forEach((link: TabNavLink) => {
        if (link.link !== ROUTE_ROOT) {
          this.setActive(link);
        }
      });
    } else {
      this.activeRoute = ROUTE_ROOT;
      this.links[0].active = true;
    }
  }

  private setActive(link: TabNavLink): void {
    const checked = this.activeRoute.startsWith(link.link);
    link.active = checked;
  }
}
