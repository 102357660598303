import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, first } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile/profile.service';
import { Notification } from 'src/app/shared/models/notification.model';
import {
  IDENTITY_CHECK,
  IDENTITY_CHECK_VERIFIED_STATUS,
  IDENTITY_CHECK_PENDING_STATUS,
  CRIMINALBACKGROUNDCHECK,
  UPLOAD_STATUS
} from 'src/app/shared/services/driver-api.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { RegistrationState, NotificationStateModel } from 'src/app/registration/state/registration.state';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-criminal-background-check',
  templateUrl: './criminal-background-check.component.html',
  styleUrls: ['./criminal-background-check.component.scss']
})
export class CriminalBackgroundCheckComponent implements OnInit {
  html: any;
  contactEmail = 'support@FleetOperate.com';

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe
  ) {}
  ngOnInit() {
    this.registrationService
      .loadDriver()
      .pipe(
        first(),
        map(() => this.store.selectSnapshot(RegistrationState.notification))
      )
      .subscribe((notificationStateModel: NotificationStateModel) => {
        if (notificationStateModel && notificationStateModel.notifications) {
          this.checkCriminalBackgroundStatus([...notificationStateModel.notifications]);
        }
      });
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  private checkCriminalBackgroundStatus(notifications: Notification[]): void {
    const supportMessage = `If you need further assistance, please get in touch with one of our support group at <a href="mailto:${this.contactEmail}">${this.contactEmail}</a>`;
    const criminalBackgroundCheck = this.profileService.findNotification(
      notifications,
      CRIMINALBACKGROUNDCHECK,
      UPLOAD_STATUS
    );

    if (criminalBackgroundCheck) {
      const createdDate = this.datePipe.transform(criminalBackgroundCheck.createdDate, 'dd/MM/yyyy') as string;
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p><b>Your background check documents were uploaded to our system on ${createdDate}</b></p><p> ${supportMessage}</p>`
      );
    } else {
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p><b>We are yet to receive/process your background documents.</b></p><p>${supportMessage}</p>`
      );
    }
  }
}
