import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreModule } from '../core.module';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: CoreModule
})
export class DriverGuardService implements CanActivate {

  constructor(private readonly router: Router,
              private readonly registrationService: RegistrationService,
              private readonly authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const driver = this.registrationService.getDriver();

    if (driver) {
      return true;
    }

    this.registrationService.clearDriver();
    this.authService.unauthenticate();
    this.navigateToSignIn();
    return false;
  }

  private navigateToSignIn(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }
}
