import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { WebcamImage } from 'ngx-webcam';
import { DocumentImage } from 'src/app/registration/shared/models/document-image.model';
import { ActivatedRoute } from '@angular/router';
import { Status } from 'src/app/shared/status-banner/models/status.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { Response } from 'src/app/shared/services/response';
import { take } from 'rxjs/operators';

const ReviewSelfieMessage = 'Review your selfie';
declare var EXIF: any;

@Component({
  selector: 'app-license-selfie-image',
  templateUrl: './license-selfie-image.component.html',
  styleUrls: ['./license-selfie-image.component.scss']
})
export class LicenseSelfieImageComponent implements OnInit {
  @ViewChild('selfieImageInput', { static: false }) selfieImageInput: ElementRef<HTMLElement>;
  loading = false;
  selfieImage: any;
  previewImage: any;
  actions: Action[];
  status: Status;
  enabled = true;
  imageOrientationClass: string;
  errorMessage: string;
  private documentImage = new DocumentImage();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly sanitizer: DomSanitizer,
    private readonly logger: LoggerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly commonService: CommonService
  ) {
    this.actions = [];
  }

  ngOnInit() {
    const data = this.registrationService.getKYCVerificationData();
    if (data.livePhoto && data.livePhoto.imageAsDataUrl) {
      this.selfieImage = data.livePhoto ? data.livePhoto.imageAsDataUrl : undefined;
    }

    if (this.selfieImage) {
      this.reviewSelfieImage();
    } else {
      this.actions.push(this.createTakePictureAction());
    }
  }

  navigateToLicenseBackImage(): void {
    this.registrationService.navigateToLicenseBackImage();
  }

  onFileChange(event: any): void {
    const file = event.srcElement.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const takenImage: any = reader.result;

      // Set image proper orientation if device is not IOS
      if (!this.commonService.isIOSdevice()) {
        const image = new Image();
        image.src = takenImage;
        image.onload = () => {
          this.setImageOrientation(image);
        };
      }
      const webImage = new WebcamImage(takenImage, '', file);
      const documentImage = {
        image: webImage
      } as DocumentImage;
      this.selfieImage = webImage.imageAsDataUrl;
      this.reviewSelfieImage();
      this.documentImage = documentImage;
      this.loading = false;
    };
  }

  private saveImage(): void {
    if (this.documentImage) {
      this.registrationService.saveDocumentSelfieImage(this.documentImage);
      this.errorMessage = undefined;

      this.registrationService
        .submitKYCVerification()
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            response
              ? this.registrationService.navigateToLicenseAllset()
              : (this.errorMessage = `There was an error. Please try again or contact us for help.`);
          },
          (error: Response) => {
            this.errorMessage = `${error.message} Please try again or contact us for help.`;
          }
        );
    }
  }

  private setImageOrientation(image: HTMLImageElement) {
    EXIF.getData(image, () => {
      const orientation = EXIF.getTag(image, 'Orientation');
      switch (orientation) {
        case 3:
          this.imageOrientationClass = 'rotate-180';
          break;
        case 6:
          this.imageOrientationClass = 'rotate-90';
          break;
        case 8:
          this.imageOrientationClass = 'rotate-270';
          break;
      }
    });
  }

  private onRetakeClicked(): void {
    this.selfieImage = undefined;
    this.actions = [];
    this.actions.push(this.createTakePictureAction());
  }

  private onSelfieImageInputClicked(): void {
    if (!this.selfieImageInput || !this.selfieImageInput.nativeElement) {
      this.logger.error('input file element not found');
    }
    this.selfieImageInput.nativeElement.click();
    this.loading = true;
  }

  private createTakePictureAction(): Action {
    return {
      name: 'TAKE PICTURE',
      action: (data: any) => this.onSelfieImageInputClicked(),
      disabled: !this.enabled,
      color: 'take_picture'
    } as Action;
  }

  private createRetakePictureAction(): Action {
    return {
      name: 'RETAKE',
      action: (data: any) => this.onRetakeClicked(),
      disabled: !this.enabled,
      color: 'retake_picture'
    } as Action;
  }

  private createConfirmAction(): Action {
    return {
      name: 'FINISH',
      action: (data: any) => this.saveImage(),
      disabled: !this.enabled,
      color: 'confirm_picture'
    } as Action;
  }

  private createReviewPictureMessage(): Status {
    return {
      message: ReviewSelfieMessage,
      color: 'review_picture'
    } as Status;
  }

  private reviewSelfieImage(): void {
    this.actions = [];
    this.status = this.createReviewPictureMessage();
    this.actions.push(this.createRetakePictureAction());
    this.actions.push(this.createConfirmAction());
  }
}
