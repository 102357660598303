import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { Response } from '../../shared/services/response';
import { HttpHeaders } from '@angular/common/http';
import { HttpOptions } from '../services/http-options';
import { FileApiService } from './file-api.service';
import { DriverApiService } from '../services/driver-api.service';
import { FileDataModule } from './file-data.module';

@Injectable({
  providedIn: FileDataModule
})
export class FileDataService {
  constructor(
    private readonly fileApiService: FileApiService,
    private readonly store: Store,
    private readonly driverApi: DriverApiService
  ) {}

  uploadFile(file: any, url: string, contentType: string): Observable<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType
      })
    } as HttpOptions;

    return this.fileApiService.uploadFile(file, url, httpOptions);
  }
}
