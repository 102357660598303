import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { FleetoperateApiService } from './fleetoperate-api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() {}

  isIOSdevice(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
}
