import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { RegistrationState, DriverStateModel } from '../registration/state/registration.state';
import { PROFILE_COMPLETE_STATUS } from '../shared/services/driver-api.service';
import { Notification } from 'src/app/shared/models/notification.model';
import { Driver } from '../shared/models/driver.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private readonly store: Store) {}

  isProfileCompleted(): boolean {
    const driver = this.store.selectSnapshot(RegistrationState.driver);
    if (
      driver &&
      driver.identityStatus &&
      driver.identityStatus.toUpperCase() === PROFILE_COMPLETE_STATUS.toUpperCase()
    ) {
      return true;
    }
    return false;
  }

  findNotification(notifications: Notification[], type: string, status: string): Notification {
    let detailsFound;
    if (notifications) {
      detailsFound = notifications.filter((notification: Notification) => {
        return notification.type === type && notification.status === status;
      });
    }
    return detailsFound && detailsFound.length > 0 ? detailsFound[0] : undefined;
  }

  isPersonalInfoCompleted(driver: DriverStateModel): boolean {
    if (driver && driver.issuingAuthority && driver.licenseType && driver.birthday && driver.gender) {
      return true;
    }
    return false;
  }

  checkIfNotificationTypeIsPresent(notifications: Notification[], type: string): Notification {
    let typeFound: Notification;
    if (notifications) {
      typeFound = notifications.find((notification: Notification) => {
        return notification.type === type;
      });
    }
    return typeFound;
  }
}
