import { Component, OnInit, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { SignUpModel } from '../shared/sign-up-model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Driver } from 'src/app/shared/services/driver';
import { ROUTE_SIGNIN, ROUTE_TERMS_CONDITIONS, ROUTE_PRIVACY_STATEMENT } from '../shared/routes';
import { Response } from 'src/app/shared/services/response';
import { StorageService } from 'src/app/shared/storage/storage.service';

const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';
const SignInForm = 'SignFormDetails';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;
const PhoneNumberPatternMessage = 'Please enter a valid number with the correct format';
const InvalidFirstName = 'Please enter a valid first name';
const InvalidLastName = 'Please enter a valid last name';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  hidePassword: boolean;
  loading: boolean;
  errorMessage: string;
  successMessage: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly driverApi: DriverApiService,
    private readonly elementRef: ElementRef,
    private readonly storageService: StorageService
  ) {
    this.hidePassword = true;
    this.loading = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    this.form = this.createForm();
  }

  ngOnInit() {
    const sign_up = this.storageService.find(SignInForm) as SignUpModel;
    if (sign_up != null) {
      this.form.setValue({
        firstName: sign_up.firstName,
        lastName: sign_up.lastName,
        email: sign_up.email,
        password: sign_up.password,
        mobileNumber: sign_up.phoneNumber
      });
    }
    this.storageService.remove(SignInForm);
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#0B184E';
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
  }

  onSubmit(signupForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    const model = this.prepareSaveModel();

    this.driverApi.createDriver(model).subscribe(
      (driver: Driver) => {
        this.successMessage = `Sign up successful. We've sent you a link to complete registration.`;
        this.form.reset();
        signupForm.resetForm();
        this.loading = false;
      },
      (error: Response) => {
        this.errorMessage = `${error.message} Please try again or contact us for help.`;
        this.loading = false;
      }
    );
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }
  onNavigateToTermsConditions(): void {
    const model = this.prepareSaveModel();
    this.storageService.store(SignInForm, model);
    this.router.navigate([`/${ROUTE_TERMS_CONDITIONS}`]);
  }
  onNavigateToPrivacyStatement(): void {
    const model = this.prepareSaveModel();
    this.storageService.store(SignInForm, model);
    this.router.navigate([`/${ROUTE_PRIVACY_STATEMENT}`]);
  }

  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get email() {
    return this.form.get('email');
  }
  get mobileNumber() {
    return this.form.get('mobileNumber');
  }
  get password() {
    return this.form.get('password');
  }

  getFirstNameErrorMessage() {
    const errors = this.firstName.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : errors.pattern
      ? InvalidFirstName
      : '';
  }

  getLastNameErrorMessage() {
    const errors = this.lastName.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : errors.pattern
      ? InvalidLastName
      : '';
  }
  getEmailErrorMessage() {
    const errors = this.email.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? InvalidEmailAddress : '';
  }

  getMobileNumberErrorMessage() {
    const errors = this.mobileNumber.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? PhoneNumberPatternMessage : '';
  }

  getPasswordErrorMessage() {
    const errors = this.password.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.minlength
      ? MinimumLengthFieldMessage(errors.minlength.requiredLength)
      : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[A-Za-z- \\s]+$')]],
      lastName: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[A-Za-z- \\s]+$')]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

    return form;
  }

  private prepareSaveModel(): SignUpModel {
    const formModel = this.form.value;
    const numbersOnlyPhoneNumber = this.form.value.mobileNumber.replace(/-/g, '');

    const model = {
      firstName: formModel.firstName as string,
      lastName: formModel.lastName as string,
      email: formModel.email as string,
      phoneNumber: numbersOnlyPhoneNumber as string,
      password: formModel.password as string
    };

    return model as SignUpModel;
  }
}
