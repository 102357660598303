/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overflow-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/material/core";
import * as i5 from "@angular/common";
import * as i6 from "./overflow-menu.component";
import * as i7 from "@angular/material/bottom-sheet";
import * as i8 from "../../core/services/auth.service";
import * as i9 from "../../registration/shared/registration.service";
import * as i10 from "@angular/router";
import * as i11 from "../shared/frame-navigation.service";
var styles_OverflowMenuComponent = [i0.styles];
var RenderType_OverflowMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverflowMenuComponent, data: {} });
export { RenderType_OverflowMenuComponent as RenderType_OverflowMenuComponent };
function View_OverflowMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "mat-list-item"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _v.context.$implicit.clickHandler();
        var pd_0 = (_co.onOpenLink($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "span", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, [[1, 4]], 0, i4.MatLine, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 7, 0, currVal_2); }); }
export function View_OverflowMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(1, 704512, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OverflowMenuComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_OverflowMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overflow-menu", [], null, null, null, View_OverflowMenuComponent_0, RenderType_OverflowMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.OverflowMenuComponent, [i7.MatBottomSheetRef, i8.AuthService, i9.RegistrationService, i10.Router, i11.FrameNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverflowMenuComponentNgFactory = i1.ɵccf("app-overflow-menu", i6.OverflowMenuComponent, View_OverflowMenuComponent_Host_0, {}, {}, []);
export { OverflowMenuComponentNgFactory as OverflowMenuComponentNgFactory };
