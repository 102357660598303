/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./environment-ribbon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./environment-ribbon.component";
var styles_EnvironmentRibbonComponent = [i0.styles];
var RenderType_EnvironmentRibbonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnvironmentRibbonComponent, data: {} });
export { RenderType_EnvironmentRibbonComponent as RenderType_EnvironmentRibbonComponent };
export function View_EnvironmentRibbonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(3, 3), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, _co.position, _co.colour, "corner-ribbon"); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hoverText; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.text; _ck(_v, 4, 0, currVal_2); }); }
export function View_EnvironmentRibbonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fleetoperate-environment-ribbon", [], null, null, null, View_EnvironmentRibbonComponent_0, RenderType_EnvironmentRibbonComponent)), i1.ɵdid(1, 114688, null, 0, i3.EnvironmentRibbonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnvironmentRibbonComponentNgFactory = i1.ɵccf("fleetoperate-environment-ribbon", i3.EnvironmentRibbonComponent, View_EnvironmentRibbonComponent_Host_0, { position: "position", colour: "colour", text: "text", hoverText: "hoverText" }, {}, []);
export { EnvironmentRibbonComponentNgFactory as EnvironmentRibbonComponentNgFactory };
