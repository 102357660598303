<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
<div fxFlexAlign="center"
     fxLayout="column">
  <img fxFlexAlign="center"
       class="logo padding-top-x4"
       src="/assets/img/fleetoperate_delivery_white_logo.png"
       width="300px">
       <h2 fxFlexAlign="center" class="padding-top-x2">Forgot Password</h2>
</div>
<div fxLayout="column" fxLayoutAlign="center stretch" fxFlex class="padding-top-x2">

    <div fxFlexAlign="center" class="messageText"><p>{{message}}</p></div>

  <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
    {{errorMessage}}
  </mat-error>
  <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column">
    <mat-form-field>
      <input type="email" matInput formControlName="email">
      <mat-placeholder>Email</mat-placeholder>
      <mat-error *ngIf="email.touched && email.invalid">
        {{getEmailErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <div fxLayout
         fxLayoutAlign="center end"
         class="padding-top-x4">
      <button fxFlex.gt-sm="20%"
              fxFlex.lt-md="100%"
              class="full-width margin-bottom"
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="form.pristine || !form.valid || loading">Submit</button>
    </div>
  </form>
  <span fxFlexAlign="center">
    <a mat-button [routerLink]="" (click)="onNavigateToSignIn()"><i class="material-icons">chevron_left</i> Back to Signin</a>
  </span>
</div>
</div>
