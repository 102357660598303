import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../registration/shared/registration.service";
import * as i3 from "../services/auth.service";
import * as i4 from "../core.module";
export class DriverGuardService {
    constructor(router, registrationService, authService) {
        this.router = router;
        this.registrationService = registrationService;
        this.authService = authService;
    }
    canActivate(next, state) {
        const driver = this.registrationService.getDriver();
        if (driver) {
            return true;
        }
        this.registrationService.clearDriver();
        this.authService.unauthenticate();
        this.navigateToSignIn();
        return false;
    }
    navigateToSignIn() {
        this.router.navigate([ROUTE_SIGNIN]);
    }
}
DriverGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DriverGuardService_Factory() { return new DriverGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.RegistrationService), i0.ɵɵinject(i3.AuthService)); }, token: DriverGuardService, providedIn: i4.CoreModule });
