<div fxFlexAlign="center"
     fxLayout="column">
  <img fxFlexAlign="center"
       class="logo padding-top-x4"
       src="/assets/img/fleetoperate_delivery_white_logo.png"
       width="300px">
  <span fxFlexAlign="center">< <a [routerLink]="" (click)="onNavigateToSignIn()">back to Signin</a></span>
  <h2 fxFlexAlign="center" class="padding-top-x2">Reset Password</h2>
</div>
<div fxFlex="none" fxLayout="column" fxLayoutAlign="center stretch" class="padding-top-x2">
  <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
  {{errorMessage}}
  </mat-error>
  <mat-label *ngIf="successMessage" fxFlexAlign="center">{{successMessage}}</mat-label>
  <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column">
    <mat-form-field>
      <mat-hint align="start">at least 8 characters</mat-hint>
      <input matInput placeholder="New Password" [type]="hidePassword ? 'password' : 'text'" formControlName="password">
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error *ngIf="password.touched && password.invalid">
        {{getPasswordErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-hint align="start">at least 8 characters</mat-hint>
      <input matInput placeholder="Re-enter New Password" [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword" [errorStateMatcher]="matcher">
      <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error *ngIf="confirmPasswordHasError()">
        {{getConfirmPasswordErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <div fxLayout
         fxLayoutAlign="center end"
         class="padding-top-x4">
      <button fxFlex.gt-sm="20%"
              fxFlex.lt-md="100%"
              class="full-width margin-bottom"
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="form.pristine || !form.valid || loading">Save</button>
    </div>
  </form>
</div>
