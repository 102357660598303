import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FrameComponent } from './frame/frame.component';
import { MaterialModule } from '../shared/material/material.module';
import { OverflowMenuComponent } from './overflow-menu/overflow-menu.component';

const routes: Routes = [];

@NgModule({
  declarations: [FrameComponent, OverflowMenuComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild(routes)
  ],
  entryComponents: [OverflowMenuComponent]
})
export class FrameModule { }

export { FrameComponent } from './frame/frame.component';
