import {
  RegistrationKYCVerificationDataStateModel,
  DocumentFrontImageModel,
  DocumentBackImageModel,
  DocumentSelfieImageModel,
  DriverStateModel} from './registration.state';
import { Employment } from 'src/app/shared/models/employment.model';
import { Education } from 'src/app/shared/models/education.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { Notification } from 'src/app/shared/models/notification.model';

export class ClearRegistration {
  static readonly type = '[Registration] ClearRegistration';
  constructor() {}
}

export class SetDriver {
  static readonly type = '[Registration] SetDriver';
  constructor(public payload: DriverStateModel) {}
}

export class UpdateDriver {
  static readonly type = '[Registration] UpdateDriver';
  constructor(public payload: DriverStateModel) {}
}

export class ClearDriver {
  static readonly type = '[Registration] ClearDriver';
  constructor() {}
}

export class SetKYCVerificationData {
    static readonly type = '[Registration] SetKYCVerificationData';
    constructor(public payload: RegistrationKYCVerificationDataStateModel) {}
}

export class UpdateKYCVerificationData {
  static readonly type = '[Registration] UpdateKYCVerificationData';
  constructor(public payload: RegistrationKYCVerificationDataStateModel) {}
}

export class ClearKYCVerificationData {
  static readonly type = '[Registration] ClearKYCVerificationData';
  constructor() {}
}

export class SaveDriverLicenseFrontImage {
  static readonly type = '[Registration] SaveDriverLicenseFrontImage';
  constructor(public payload: DocumentFrontImageModel) {}
}

export class SaveDriverLicenseBackImage {
  static readonly type = '[Registration] SaveDriverLicenseBackImage';
  constructor(public payload: DocumentBackImageModel) {}
}

export class SaveDriverLicenseSelfieImage {
  static readonly type = '[Registration] SaveDriverLicenseSelfieImage';
  constructor(public payload: DocumentSelfieImageModel) {}
}

export class DeleteEmployment {
  static readonly type = '[Registration] DeleteEmployment';
  constructor(public payload: Employment) {}
}

export class SaveEducation {
  static readonly type = '[Registration] SaveEducation';
  constructor(public payload: Education) {}
}

export class UpdateEducation {
  static readonly type = '[Registration] UpdateEducation';
  constructor(public payload: Education) {}
}

export class SaveDrivingExperience {
  static readonly type = '[Registration] SaveDrivingExperience';
  constructor(public payload: DrivingExperience) {}
}

export class SavePreferences {
  static readonly type = '[Registration] SavePreferences';
  constructor(public payload: Preferences) {}
}

export class SetProfilePicture {
  static readonly type = '[Registration] SetProfilePicture';
  constructor(public payload: ProfilePicture) {}
}

export class SetEducation {
  static readonly type = '[Registration] SetEducation';
  constructor(public payload: Education) {}
}

export class SetEmploymentHistory {
  static readonly type = '[Registration] SetEmploymentHistory';
  constructor(public payload: Employment[]) {}
}

export class SetPreferences {
  static readonly type = '[Registration] SetPreferences';
  constructor(public payload: Preferences) {}
}

export class SetDrivingExperience {
  static readonly type = '[Registration] SetDrivingExperience';
  constructor(public payload: DrivingExperience) {}
}

export class SetNotification {
  static readonly type = '[Registration] SetNotification';
  constructor(public payload: Notification[]) {}
}
