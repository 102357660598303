import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SignUpCompletedComponent } from './sign-up-completed/sign-up-completed.component';
import { SignUpErrorComponent } from './sign-up-error/sign-up-error.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';

@NgModule({
  declarations: [
    AuthenticationComponent,
    SignUpComponent,
    SignInComponent,
    SignUpCompletedComponent,
    SignUpErrorComponent,
    TermsConditionsComponent,
    PrivacyStatementComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    AuthenticationRoutingModule
  ]
})
export class AuthenticationModule { }

export { AuthenticationComponent } from './authentication/authentication.component';
export { ROUTE_SIGNIN, ROUTE_SIGNUP } from './shared/routes';
