import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
export class AuthGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(next, state) {
        return this.authService.isAuthenticated().pipe(map((authenticated) => {
            if (authenticated) {
                return true;
            }
            // navigate to login page
            this.authService.returnUrl = state.url;
            this.router.navigate([ROUTE_SIGNIN]);
            return false;
        }), catchError((error) => {
            // navigate to login page
            this.authService.returnUrl = state.url;
            return this.router.navigate([ROUTE_SIGNIN]);
        }));
    }
}
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
