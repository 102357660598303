<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateToProfile();">arrow_back</mat-icon>
    <span fxFlex class="title">Employment History</span>
    <!-- <button mat-button
            [disabled]="(!form.dirty || !form.valid) && (!noExperienceForm.dirty || !noExperienceForm.valid) && !loading"
            class="header-action-button"
            (click)="onSubmit();">Save</button> -->
  </div>
  <div>  
    <mat-error *ngIf="errorMessage" class="error padding-left-md">
      {{errorMessage}}
    </mat-error>
    <form [formGroup]="noExperienceForm"
          (ngSubmit)="onSubmit()"
          fxLayout="column"
          class="no-experience-container">
      <span>No Experience</span>
      <mat-checkbox formControlName="noExperience"></mat-checkbox>
    </form>
  </div>
  <div fxLayout
       class="add-new-container">
    <button mat-button
            [disabled]="isAddingOrEditing"
            (click)="onAddNewEmployment();"><i class="material-icons">add</i> Add Experience</button>

    <button mat-button
            [disabled]="(!form.dirty || !form.valid) && (!noExperienceForm.dirty || !noExperienceForm.valid) && !loading"
            class="header-action-button"
            (click)="onSubmit();">Save</button>
  </div>
  <div class="content padding-sides-1x" fxLayout="column">
    <div *ngIf="isAddingOrEditing" fxLayout="column" class="component-container">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" class="addExperienceForm">
        <mat-form-field>
          <input matInput placeholder="Employer Name" formControlName="employer" required>
          <mat-error *ngIf="employer.touched && employer.invalid">
            {{getEmployerNameErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <!-- <div fxLayout="row"> -->
          <mat-form-field>
            <input matInput placeholder="From Date" [matDatepicker]="fromDate" [errorStateMatcher]="matcher" formControlName="fromDate" [max]="maxDate" required>
            <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true"  #fromDate></mat-datepicker>
            <mat-error *ngIf="fromDate.touched && fromDate.invalid">
              {{getFromDateErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="To Date" [matDatepicker]="toDate" [errorStateMatcher]="matcher" formControlName="toDate" [max]="maxDate" required>
            <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" #toDate></mat-datepicker>  
            <mat-error *ngIf="toDate.touched && toDate.invalid">
              {{getToDateErrorMessage()}}
            </mat-error>  
            <mat-error *ngIf="form.hasError('toDateLessThanFromDate')">{{getDateValidationErrorMessage()}}</mat-error>
          </mat-form-field>
        <!-- </div> -->
        <mat-form-field>
          <input matInput placeholder="Position Held" formControlName="position" required>
          <mat-error *ngIf="position.touched && position.invalid">
            {{getPositionErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </form>
    </div>

    <div *ngIf="employmentHistory.employmentList.length > 0" class="app-employment-view">
      <app-employment-view *ngFor="let employment of employmentHistory.employmentList"
        [model]="employment"
        (updateEmploymentPressed)="onUpdateEmploymentPressed($event)"
        (deleteEmploymentPressed)="onDeleteEmploymentPressed($event)"
        class="app-employment-view">
      </app-employment-view>
    </div>
  </div>
</div>
