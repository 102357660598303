import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { FleetoperateApiService } from '../shared/services/fleetoperate-api.service';
import { ResetPasswordModel } from './shared/reset-password.model';
import { Store } from '@ngxs/store';
import { Driver } from '../shared/services/driver';
import { RegistrationState } from '../registration/state/registration.state';
import { AuthService } from '../core/services/auth.service';
import { mergeMap } from 'rxjs/operators';

const PATH_RESET_PASSWORD = '/auth/resetpassword';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  apiUrl: string;

  constructor(private readonly store: Store,
              private readonly api: FleetoperateApiService,
              private authService: AuthService) {
    this.apiUrl = environment.apiUrl;
  }

  getDriver(): Driver {
    const driverStateModel = this.store.selectSnapshot(RegistrationState.driver);

    return {
      id: driverStateModel.id,
      userName: driverStateModel.email,
      firstName: driverStateModel.firstName,
      lastName: driverStateModel.lastName,
      phoneNumber: driverStateModel.phoneNumber,
      email: driverStateModel.email
    } as Driver;
  }

  resetPassword(model: ResetPasswordModel): Observable<any> {
    return this.authService.isAuthenticated().pipe(
      mergeMap(() => this.api.post(this.apiUrl + PATH_RESET_PASSWORD, model, this.authService.token))
    );
  }
}
