import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SubSink } from 'subsink';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { Store } from '@ngxs/store';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { environment } from 'src/environments/environment.dev';
import { tap, onErrorResumeNext } from 'rxjs/operators';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit, OnDestroy {
  showMenu = false;
  imageChangedEvent: any;
  profilePicture: any;
  loading = false;
  menuOptionSelected = false;
  errorMessage: string;
  private subs = new SubSink();
  private profilePicturePath: ProfilePicture = new ProfilePicture();

  constructor(private readonly registrationService: RegistrationService, private readonly store: Store) {
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit(): void {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(
          onErrorResumeNext(this.registrationService.loadProfilePicture()),
          tap(() => (this.profilePicturePath = this.store.selectSnapshot(RegistrationState.profilePicture)))
        )
        .subscribe(() => {
          if (this.profilePicturePath && this.profilePicturePath.fileUrl) {
            this.profilePicture = this.profilePicturePath.fileUrl;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onFileInputClicked(fileInput: any) {
    fileInput.click();
  }

  onNavigateToProfile() {
    this.registrationService.navigateToProfile();
  }

  onShowMenu() {
    this.showMenu = true;
  }

  onHideMenu() {
    this.showMenu = false;
  }

  onProcessFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePicture = file;
      };

      this.loading = true;
      this.menuOptionSelected = true;
      this.onHideMenu();
      this.imageChangedEvent = event;
    }
  }

  onCancelCrop() {
    this.imageChangedEvent = undefined;
    this.profilePicture = undefined;
    this.menuOptionSelected = false;
  }

  onFinishCrop() {
    if (this.profilePicture && this.profilePicture.name) {
      const extension = this.profilePicture.name.split('.')[1].toLowerCase();
      this.loading = true;
      this.subs.add(
        this.registrationService.saveProfilePicture(this.profilePicture, extension).subscribe(
          () => {
            this.errorMessage = undefined;
            this.loading = false;
            this.imageChangedEvent = undefined;
            this.menuOptionSelected = false;
            this.onNavigateToProfile();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
            this.imageChangedEvent = undefined;
            this.menuOptionSelected = false;
          }
        )
      );
    }
  }

  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.file) {
      const imageFile = new File([event.file], this.profilePicture.name, { type: this.profilePicture.type });
      this.profilePicture = imageFile;
    }
  }

  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  onLoadImageFailed() {}
}
