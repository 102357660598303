import { environment } from 'src/environments/environment';
import { FleetoperateApiService } from '../shared/services/fleetoperate-api.service';
import { Store } from '@ngxs/store';
import { RegistrationState } from '../registration/state/registration.state';
import { AuthService } from '../core/services/auth.service';
import { mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "../shared/services/fleetoperate-api.service";
import * as i3 from "../core/services/auth.service";
const PATH_RESET_PASSWORD = '/auth/resetpassword';
export class ResetPasswordService {
    constructor(store, api, authService) {
        this.store = store;
        this.api = api;
        this.authService = authService;
        this.apiUrl = environment.apiUrl;
    }
    getDriver() {
        const driverStateModel = this.store.selectSnapshot(RegistrationState.driver);
        return {
            id: driverStateModel.id,
            userName: driverStateModel.email,
            firstName: driverStateModel.firstName,
            lastName: driverStateModel.lastName,
            phoneNumber: driverStateModel.phoneNumber,
            email: driverStateModel.email
        };
    }
    resetPassword(model) {
        return this.authService.isAuthenticated().pipe(mergeMap(() => this.api.post(this.apiUrl + PATH_RESET_PASSWORD, model, this.authService.token)));
    }
}
ResetPasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetPasswordService_Factory() { return new ResetPasswordService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.FleetoperateApiService), i0.ɵɵinject(i3.AuthService)); }, token: ResetPasswordService, providedIn: "root" });
