import { Component, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { RegistrationService } from '../shared/registration.service';
import { Store } from '@ngxs/store';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { Notification } from 'src/app/shared/models/notification.model';
import { map } from 'rxjs/operators';
import { MEDICAL_CERTIFICATE, UPLOAD_STATUS } from 'src/app/shared/services/driver-api.service';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-medical-examination',
  templateUrl: './medical-examination.component.html',
  styleUrls: ['./medical-examination.component.scss']
})
export class MedicalExaminationComponent implements OnInit {
  public medicalCertificate: Notification;
  private subs = new SubSink();

  constructor(private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService) { }

  ngOnInit() {
    this.subs.add(this.registrationService.loadDriver().pipe(
      map(() => this.store.selectSnapshot(RegistrationState.notification))
    ).subscribe((notificationStateModel: NotificationStateModel) => {
      if (notificationStateModel && notificationStateModel.notifications) {
        this.medicalCertificate = this.profileService.findNotification([...notificationStateModel.notifications], MEDICAL_CERTIFICATE, UPLOAD_STATUS);
      }
    }));
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }
}
