import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { FrameComponent } from './frame/frame/frame.component';
import { DriverDashboardComponent } from './driver-dashboard/driver-dashboard.module';
import {
  ROUTE_ROOT,
  ROUTE_DRIVER_DASHBOARD,
  ROUTE_NOTIFICATIONS,
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_TELL_A_FRIEND,
  ROUTE_DRIVER_HISTORY,
  ROUTE_LOADS} from './shared/routes';
import { NotificationsComponent } from './notifications/notifications.module';
import { AccountSettingsComponent } from './account-settings/account-settings.module';
import { TellFriendComponent } from './tell-a-friend/tell-a-friend.module';
import { profileRoutes, ROUTE_PROFILE } from './profile/profile-routing.module';
import { ProfileOutletComponent } from './profile/profile-outlet/profile-outlet.component';
import { DriverGuardService } from './core/guards/driver-guard.service';

const routes: Routes = [
  { path: ROUTE_ROOT,
    component: FrameComponent,
    children: [
      {
        path: ROUTE_DRIVER_DASHBOARD,
        component: DriverDashboardComponent,
        canActivate: [AuthGuardService, DriverGuardService]
      },
      {
        path: ROUTE_PROFILE,
        component: ProfileOutletComponent,
        children: [...profileRoutes],
        canActivate: [AuthGuardService, DriverGuardService]
      },
      {
        path: ROUTE_NOTIFICATIONS,
        component: NotificationsComponent,
        canActivate: [AuthGuardService, DriverGuardService]
      },
      {
        path: ROUTE_ACCOUNT_SETTINGS,
        component: AccountSettingsComponent,
        canActivate: [AuthGuardService, DriverGuardService]
      },
      {
        path: ROUTE_TELL_A_FRIEND,
        component: TellFriendComponent,
        canActivate: [AuthGuardService, DriverGuardService]
      },
      {
        path: ROUTE_DRIVER_HISTORY,
        loadChildren: () => import('./driver-history/driver-history.module').then(m => m.DriverHistoryModule),
        canActivate: [AuthGuardService, DriverGuardService]
      },
      {
        path: ROUTE_LOADS,
        loadChildren: () => import('./loads/loads.module').then(m => m.LoadsModule),
        canActivate: [AuthGuardService, DriverGuardService]
      }
    ],
    canActivate: [AuthGuardService, DriverGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
