import { Storage } from './storage';
import * as i0 from "@angular/core";
import * as i1 from "./storage.module";
export class StorageService {
    constructor() { }
    store(key, object) {
        const storage = new Storage(key, object);
        sessionStorage.setItem(key, JSON.stringify(storage));
    }
    find(key) {
        const item = sessionStorage.getItem(key);
        const storage = JSON.parse(item);
        return storage ? storage.object : undefined;
    }
    remove(key) {
        sessionStorage.removeItem(key);
    }
    clearAll() {
        sessionStorage.clear();
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: i1.StorageModule });
