import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { FileApiService } from './file-api.service';
import { DriverApiService } from '../services/driver-api.service';
import * as i0 from "@angular/core";
import * as i1 from "./file-api.service";
import * as i2 from "@ngxs/store";
import * as i3 from "../services/driver-api.service";
import * as i4 from "./file-data.module";
export class FileDataService {
    constructor(fileApiService, store, driverApi) {
        this.fileApiService = fileApiService;
        this.store = store;
        this.driverApi = driverApi;
    }
    uploadFile(file, url, contentType) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': contentType
            })
        };
        return this.fileApiService.uploadFile(file, url, httpOptions);
    }
}
FileDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileDataService_Factory() { return new FileDataService(i0.ɵɵinject(i1.FileApiService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.DriverApiService)); }, token: FileDataService, providedIn: i4.FileDataModule });
