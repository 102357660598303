import { TruckType } from 'src/app/registration/shared/models/truck-type.model';

export class DrivingExperience {
    id: string;
    drivingGraduate: boolean;
    companyDriver: boolean;
    studentDriver: boolean;
    ownerOperator: boolean;
    yearsOfExperience: number;
    truckTypes: TruckType[];
}
