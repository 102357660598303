<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back">
    <span>Profile</span>
  </div>
  <div class="content">
    <div class="card-list">
      <mat-card class="card" (click)="onNavigateToIdentityCheck()">
        <mat-icon *ngIf="identityCheck" class="icon-centered color-success">check_circle </mat-icon>
        <mat-icon *ngIf="!identityCheck" class="icon-centered color-warning">error</mat-icon>
        <span class="profileCardLabel">Identity Check</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card>
      <mat-card class="card" (click)="onNavigateToCriminalBackgroundCheck()">
        <mat-icon *ngIf="criminalBackgroundCheck" class="icon-centered color-success">check_circle </mat-icon>
        <mat-icon *ngIf="!criminalBackgroundCheck" class="icon-centered color-warning">error </mat-icon>
        <span class="profileCardLabel">Criminal background check</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card>
      <mat-card class="card" (click)="onNavigateToProfilePicture()">
        <mat-icon *ngIf="profilePicture.fileUrl" class="icon-centered color-success">check_circle</mat-icon>
        <mat-icon *ngIf="!profilePicture.fileUrl" class="icon-centered color-warning">error</mat-icon>
        <span class="profileCardLabel">Profile Picture</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card>
      <mat-card class="card" (click)="onNavigateToPersonalInformation()">
        <mat-icon *ngIf="personalInfoCompleted" class="icon-centered color-success">check_circle</mat-icon>
        <mat-icon *ngIf="!personalInfoCompleted" class="icon-centered color-warning">error</mat-icon>
        <span class="profileCardLabel">Personal Info</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card>
      <mat-card class="card" (click)="onNavigateToPreferences()">
        <mat-icon *ngIf="preferences.id" class="icon-centered color-success">check_circle</mat-icon>
        <mat-icon *ngIf="!preferences.id" class="icon-centered color-warning">error</mat-icon>
        <span class="profileCardLabel">Preferences</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card>
    </div>
  </div>
</div>
