import { Store } from '@ngxs/store';
import { RegistrationState } from '../registration/state/registration.state';
import { PROFILE_COMPLETE_STATUS } from '../shared/services/driver-api.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class ProfileService {
    constructor(store) {
        this.store = store;
    }
    isProfileCompleted() {
        const driver = this.store.selectSnapshot(RegistrationState.driver);
        if (driver &&
            driver.identityStatus &&
            driver.identityStatus.toUpperCase() === PROFILE_COMPLETE_STATUS.toUpperCase()) {
            return true;
        }
        return false;
    }
    findNotification(notifications, type, status) {
        let detailsFound;
        if (notifications) {
            detailsFound = notifications.filter((notification) => {
                return notification.type === type && notification.status === status;
            });
        }
        return detailsFound && detailsFound.length > 0 ? detailsFound[0] : undefined;
    }
    isPersonalInfoCompleted(driver) {
        if (driver && driver.issuingAuthority && driver.licenseType && driver.birthday && driver.gender) {
            return true;
        }
        return false;
    }
    checkIfNotificationTypeIsPresent(notifications, type) {
        let typeFound;
        if (notifications) {
            typeFound = notifications.find((notification) => {
                return notification.type === type;
            });
        }
        return typeFound;
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.Store)); }, token: ProfileService, providedIn: "root" });
