import { Router } from '@angular/router';
import { ROUTE_LOADS } from 'src/app/shared/routes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const PATH_DRIVER_DASHBOARD = '';
const PATH_PROFILE = 'profile';
const PATH_PROFILE_PICTURE = 'profilePicture';
const PATH_NOTIFICATIONS = 'notifications';
const PATH_ACCOUNT_SETTINGS = 'accountSettings';
const PATH_TELL_FRIEND = 'tellFriend';
const PATH_DRIVER_HISTORY = 'driverHistory';
export class FrameNavigationService {
    constructor(router) {
        this.router = router;
    }
    navigateToDriverDashboard() {
        return this.router.navigate([PATH_DRIVER_DASHBOARD]);
    }
    navigateToProfile() {
        return this.router.navigate([PATH_PROFILE]);
    }
    navigateToProfilePicture() {
        return this.router.navigate([PATH_PROFILE_PICTURE]);
    }
    navigateToLoads() {
        return this.router.navigate([ROUTE_LOADS]);
    }
    navigateToNotifications() {
        return this.router.navigate([PATH_NOTIFICATIONS]);
    }
    navigateToAccountSettings() {
        return this.router.navigate([PATH_ACCOUNT_SETTINGS]);
    }
    navigateToTellFriend() {
        return this.router.navigate([PATH_TELL_FRIEND]);
    }
    navigateToDriverHistory() {
        return this.router.navigate([PATH_DRIVER_HISTORY]);
    }
}
FrameNavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FrameNavigationService_Factory() { return new FrameNavigationService(i0.ɵɵinject(i1.Router)); }, token: FrameNavigationService, providedIn: "root" });
