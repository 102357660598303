<div class="header-navigation-back">
    <mat-icon (click)="onNavigateToProfile()">arrow_back</mat-icon>
    <span fxFlex class="title">Medical Examination</span>
</div>
<div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxFlex fxLayout="column" class="padding-sides-2x">
        <p *ngIf="!medicalCertificate">
            <b> We are yet to
                recieve/process your Medical Examination report.</b></p>
        <p *ngIf="medicalCertificate">
            Your Medical
            Examination report
            was uploaded to our system on {{medicalCertificate.createdDate|date: 'dd/MM/yyyy'}}.</p>
        <p>If you need further assistance, please get in touch with one of our support
            group at <u>support@FleetOperate.com</u>.</p>
    </div>
</div>