import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/authentication.module';
import { FrameNavigationService } from '../shared/frame-navigation.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ROUTE_LOADS, ROUTE_TELL_A_FRIEND, ROUTE_DRIVER_HISTORY, ROUTE_ACCOUNT_SETTINGS } from 'src/app/shared/routes';

class TabNavLink {
  title: string;
  link: string;
  clickHandler: () => void;
}

@Component({
  selector: 'app-overflow-menu',
  templateUrl: './overflow-menu.component.html',
  styleUrls: ['./overflow-menu.component.scss']
})
export class OverflowMenuComponent implements OnInit {

  links: TabNavLink[] = [];

  constructor(private readonly bottomSheetRef: MatBottomSheetRef<OverflowMenuComponent>,
    private readonly authService: AuthService,
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private readonly frameNavigationService: FrameNavigationService) {
    this.links.push({
      title: 'Account settings',
      link: `/${ROUTE_ACCOUNT_SETTINGS}`,
      clickHandler: () => this.navigateToAccountSettings()
    } as TabNavLink);
    this.links.push({ title: 'Tell a friend', link: `/${ROUTE_TELL_A_FRIEND}`, clickHandler: () => this.navigateToTellFriend() } as TabNavLink);
    this.links.push({ title: 'History', link: `/${ROUTE_DRIVER_HISTORY}`, clickHandler: () => this.navigateToDriverHistory() } as TabNavLink);
    this.links.push({ title: 'Logout', link: undefined, clickHandler: () => this.logout() } as TabNavLink);
  }

  ngOnInit() {
  }

  onOpenLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  private navigateToAccountSettings() {
    this.frameNavigationService.navigateToAccountSettings();
  }

  private navigateToTellFriend() {
    this.frameNavigationService.navigateToTellFriend();
  }

  private navigateToDriverHistory() {
    this.frameNavigationService.navigateToDriverHistory();
  }

  private logout(): void {
    this.authService.unauthenticate();
    this.registrationService.clearDriver();
    this.registrationService.clearRegistration();
    this.router.navigate([ROUTE_SIGNIN]);
  }
}
