import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_SIGNUP } from '../shared/routes';

@Component({
  selector: 'app-sign-up-error',
  templateUrl: './sign-up-error.component.html',
  styleUrls: ['./sign-up-error.component.scss']
})
export class SignUpErrorComponent implements OnInit {

  message: string;

  constructor(private readonly router: Router) {
    this.message = `Sorry! There was an error. Please try again or contact us for help.`;
  }

  ngOnInit() {
  }

  onNavigateToSignUp(): void {
    this.router.navigate([`/${ROUTE_SIGNUP}`]);
  }

}
