import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';

import { LicenseFrontImageComponent } from './license-front-image/license-front-image.component';
import { IdentityCheckComponent } from './identity-check/identity-check.component';
import { MaterialModule } from '../shared/material/material.module';
import { ActionsModule } from '../shared/actions/actions.module';
import { StatusBannerModule } from '../shared/status-banner/status-banner.module';
import { LicenseBackImageComponent } from './license-back-image/license-back-image.component';
import { LicenseSelfieImageComponent } from './license-selfie-image/license-selfie-image.component';
import { LicenseAllsetComponent } from './license-allset/license-allset.component';
import { LicenseStatusComponent } from './license-status/license-status.component';

@NgModule({
  declarations: [LicenseFrontImageComponent, IdentityCheckComponent, LicenseBackImageComponent, LicenseSelfieImageComponent, LicenseAllsetComponent, LicenseStatusComponent],
  imports: [CommonModule, RouterModule, WebcamModule, MaterialModule, ActionsModule, StatusBannerModule]
})
export class IdentityCheckModule {}

export { IdentityCheckComponent } from './identity-check/identity-check.component';
