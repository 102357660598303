/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./account-settings.component";
import * as i10 from "@angular/router";
var styles_AccountSettingsComponent = [i0.styles];
var RenderType_AccountSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountSettingsComponent, data: {} });
export { RenderType_AccountSettingsComponent as RenderType_AccountSettingsComponent };
export function View_AccountSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "page gray-font-color font-family"], ["fxLayout", "column"], ["fxLayoutAlign", "start"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "header-navigation"], ["fxLayout", ""], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Account Settings"])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "card-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "mat-card", [["class", "card mat-card"]], [[2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateToResetPassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(11, 49152, null, 0, i5.MatCard, [[2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Password Reset"])), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "mat-icon", [["class", "icon-arrow mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(15, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_right"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center"; _ck(_v, 5, 0, currVal_3); _ck(_v, 15, 0); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 15).inline; var currVal_6 = (((i1.ɵnov(_v, 15).color !== "primary") && (i1.ɵnov(_v, 15).color !== "accent")) && (i1.ɵnov(_v, 15).color !== "warn")); _ck(_v, 14, 0, currVal_5, currVal_6); }); }
export function View_AccountSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-settings", [], null, null, null, View_AccountSettingsComponent_0, RenderType_AccountSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i9.AccountSettingsComponent, [i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountSettingsComponentNgFactory = i1.ɵccf("app-account-settings", i9.AccountSettingsComponent, View_AccountSettingsComponent_Host_0, {}, {}, []);
export { AccountSettingsComponentNgFactory as AccountSettingsComponentNgFactory };
