import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import {
  ROUTE_SIGNUP,
  ROUTE_SIGNIN,
  ROUTE_SIGNUP_COMPLETED,
  ROUTE_SIGNUP_ERROR,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_PRIVACY_STATEMENT} from './shared/routes';
import { SignUpCompletedComponent } from './sign-up-completed/sign-up-completed.component';
import { SignUpErrorComponent } from './sign-up-error/sign-up-error.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';

export const routes: Routes = [
    { path: ROUTE_SIGNUP, component: SignUpComponent},
    { path: ROUTE_SIGNUP_COMPLETED, component: SignUpCompletedComponent},
    { path: ROUTE_SIGNUP_ERROR, component: SignUpErrorComponent},
    { path: ROUTE_SIGNIN, component: SignInComponent},
    {path:ROUTE_TERMS_CONDITIONS,component:TermsConditionsComponent},
    {path:ROUTE_PRIVACY_STATEMENT,component:PrivacyStatementComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
