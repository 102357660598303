<div [formGroup]="group">
  <div>
    <div class="full-width">
      <mat-checkbox formControlName="van" class="full-width" labelPosition="after">Van</mat-checkbox>
      <mat-checkbox formControlName="tank" class="full-width" labelPosition="after">Tank</mat-checkbox>
    </div>
    <div class="full-width">
      <mat-checkbox class="full-width" formControlName="flat" labelPosition="after">Flat</mat-checkbox>
    </div>
  </div>
  <div class="padding-top-x1" fxLayout="row">
    <mat-form-field class="full-width">
      <input matInput type="number" placeholder="Miles driven" formControlName="milesDriven">
    </mat-form-field>
  </div>
  <div class="" fxLayout="row">
      <mat-form-field class="full-width">
          <input matInput type="text" placeholder="Other" formControlName="other">
        </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field class="full-width">
      <input matInput placeholder="From date" [matDatepicker]="fromDate" formControlName="fromDate" [max]="maxDate">
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true"  #fromDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div fxLayout="row">
      <mat-form-field class="full-width">
          <input matInput placeholder="To date" [matDatepicker]="toDate" formControlName="toDate" [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" #toDate></mat-datepicker>
        </mat-form-field>
  </div>
</div>
