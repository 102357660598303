import { formatCurrency, getCurrencySymbol } from '@angular/common';
import * as i0 from "@angular/core";
export class CurrencyService {
    getLocaleCurrency(currency) {
        if (!currency) {
            return;
        }
        let result;
        const locale = 'en-US';
        const currencyCode = 'USD';
        const digitsInfo = '1.2-2';
        result = formatCurrency(currency, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, digitsInfo);
        return result;
    }
}
CurrencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(); }, token: CurrencyService, providedIn: "root" });
