<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family full-height">
  <div class="header-navigation" fxLayout fxLayoutAlign="center">
    <span>FleetOperate - Delivery</span>
  </div>
  <div class="content">
    <div fxLayout="column" fxLayoutAlign="start" class="component-container padding-top-x2 padding-sides-1x">
      <div *ngIf="!driverPicture" class="circle-div">
        <mat-icon>person</mat-icon>
      </div>
      <div *ngIf="driverPicture" class="circle-div">
        <img class="profile-picture" [src]="driverPicture" />
      </div>
      <div class="margin-top-sm margin-bottom-sm">
        <span class="driver-name">{{ driverName }}</span>
      </div>

      <mat-grid-list *ngIf="completed || upcoming || responseRate || earnedThisMonth" cols="2" rowHeight="180px">
        <mat-grid-tile class="tile-border-right tile-border-bottom">
          <div fxFlex fxLayout="column" class="full-height">
            <div *ngIf="completed" fxFlex fxLayout="column" fxLayoutAlign="center" class="dashBoardTileValue">
              {{ completed }}
            </div>
            <div *ngIf="completed" class="dashBoardTileHeading margin-bottom-md">Completed</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="tile-border-bottom">
          <div fxFlex fxLayout="column" class="full-height">
            <div *ngIf="upcoming" fxFlex fxLayout="column" fxLayoutAlign="center" class="dashBoardTileValue">
              {{ upcoming }}
            </div>
            <div *ngIf="upcoming" class="dashBoardTileHeading margin-bottom-md">Up coming</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile class="tile-border-right">
          <div fxFlex fxLayout="column" class="full-height">
            <div *ngIf="responseRate" fxFlex fxLayout="column" fxLayoutAlign="center" class="dashBoardTileValue">
              {{ responseRate }}
            </div>
            <div *ngIf="responseRate" class="dashBoardTileHeading margin-bottom-md">Response rate</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div fxFlex fxLayout="column" class="full-height">
            <div *ngIf="earnedThisMonth" fxFlex fxLayout="column" fxLayoutAlign="center" class="dashBoardTileValue">
              {{ earnedThisMonth }}
            </div>
            <div *ngIf="earnedThisMonth" class="dashBoardTileHeading margin-bottom-md">Earned this month</div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <div *ngIf="!profileCompleted" class="complete-profile" fxLayout="row" fxLayoutAlign="space-around center">
        <span (click)="onNavigateToProfile()">
          *Complete your profile to get your first assignment
        </span>
      </div>
    </div>
  </div>
</div>
