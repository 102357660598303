<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-back-img">
  <div class="header-navigation-back">
    <mat-icon (click)="navigateToLicenseFrontmage()">arrow_back</mat-icon>
    <span fxFlex class="title">Identity Check</span>
  </div>

  <app-status-banner *ngIf="backImage" [status]="status"></app-status-banner>

  <div class="content" fxLayout="column">
    <span *ngIf="!backImage" class="header-title center padding-top-lg"
      >Take picture of the <br /><span>BACK</span><br />
      <span>of your Drivers License</span></span
    >
    <div *ngIf="!backImage" fxFlex class="center padding-top-xlg">
      <img class="driver-license-img" src="assets/images/license-back.png" />
    </div>
    <div *ngIf="backImage" fxFlex class="center padding-top-xlg img-taken-container">
      <img class="imagePreview" [src]="backImage" />
    </div>
    <app-actions [actions]="actions"></app-actions>
  </div>
</div>

<div class="inputs-container">
  <input
    #backImageInput
    type="file"
    name="image"
    capture="environment"
    accept="image/*"
    (click)="backImageInput.value = null"
    (change)="onFileChange(backImageInput)"
  />
</div>
