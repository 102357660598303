import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material/material.module';
import { AppState } from './shared/state/app.state';
import { AuthenticationModule } from './authentication/authentication.module';
import { FrameModule } from './frame/frame.module';
import { environment } from 'src/environments/environment';
import { RegistrationModule } from 'src/app/registration/registration.module';
import { DriverDashboardModule } from './driver-dashboard/driver-dashboard.module';
import { NotificationsModule } from './notifications/notifications.module';
import { AccountSettingsModule } from './account-settings/account-settings.module';
import { TellAFriendModule } from './tell-a-friend/tell-a-friend.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ProfileModule } from './profile/profile.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DocumentsDataModule } from './shared/documents-data/documents-data.module';
import { MatIconRegistry } from '@angular/material/icon';
import { FileDataModule } from './shared/file-data/file-data.module';
import { IdentityCheckModule } from './identity-check/identity-check.module';
import { EnvironmentRibbonModule } from './shared/environment-ribbon/environment-ribbon.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    CoreModule,
    SharedModule,
    MaterialModule,
    FrameModule,
    AppRoutingModule,
    RegistrationModule,
    DriverDashboardModule,
    NotificationsModule,
    AccountSettingsModule,
    TellAFriendModule,
    AuthenticationModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    ProfileModule,
    DocumentsDataModule,
    FileDataModule,
    IdentityCheckModule,
    EnvironmentRibbonModule
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl
    },
    {
      provide: 'appName',
      useValue: 'driver'
    },
    {
      provide: 'environmentData',
      useValue: environment
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('car', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/car.svg'));
    iconRegistry.addSvgIcon('alert', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert.svg'));
    iconRegistry.addSvgIcon('alert-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert-circle.svg'));
    iconRegistry.addSvgIcon('check-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-circle.svg'));
  }
}
