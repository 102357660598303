<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-selfie-img">
  <div class="header-navigation-back">
    <mat-icon (click)="navigateToLicenseBackImage()">arrow_back</mat-icon>
    <span fxFlex class="title">Identity Check</span>
  </div>

  <app-status-banner *ngIf="selfieImage" [status]="status"></app-status-banner>

  <div class="content" fxLayout="column">
    <span *ngIf="!selfieImage" class="header-title center padding-top-lg">Take a selfie</span>
    <div *ngIf="!selfieImage" fxFlex class="center padding-top-xlg">
      <img class="driver-license-img" src="assets/images/license-selfie.png" />
    </div>
    <div *ngIf="selfieImage" fxFlex class="center padding-top-xlg img-taken-container">
      <img
        [ngClass]="{
          'rotate-90': imageOrientationClass == 'rotate-90',
          'rotate-180': imageOrientationClass == 'rotate-180',
          'rotate-270': imageOrientationClass == 'rotate-270'
        }"
        class="imagePreview"
        [src]="selfieImage"
      />
    </div>
    <app-actions [actions]="actions"></app-actions>
  </div>
</div>

<div class="inputs-container">
  <input
    #selfieImageInput
    type="file"
    name="image"
    capture="user"
    accept="image/*"
    (click)="selfieImageInput.value = null"
    (change)="onFileChange($event)"
  />
</div>
