import { Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { FrameComponent } from './frame/frame/frame.component';
import { DriverDashboardComponent } from './driver-dashboard/driver-dashboard.module';
import { ROUTE_ROOT, ROUTE_DRIVER_DASHBOARD, ROUTE_NOTIFICATIONS, ROUTE_ACCOUNT_SETTINGS, ROUTE_TELL_A_FRIEND, ROUTE_DRIVER_HISTORY, ROUTE_LOADS } from './shared/routes';
import { NotificationsComponent } from './notifications/notifications.module';
import { AccountSettingsComponent } from './account-settings/account-settings.module';
import { TellFriendComponent } from './tell-a-friend/tell-a-friend.module';
import { profileRoutes, ROUTE_PROFILE } from './profile/profile-routing.module';
import { ProfileOutletComponent } from './profile/profile-outlet/profile-outlet.component';
import { DriverGuardService } from './core/guards/driver-guard.service';
const ɵ0 = () => import("./driver-history/driver-history.module.ngfactory").then(m => m.DriverHistoryModuleNgFactory), ɵ1 = () => import("./loads/loads.module.ngfactory").then(m => m.LoadsModuleNgFactory);
const routes = [
    { path: ROUTE_ROOT,
        component: FrameComponent,
        children: [
            {
                path: ROUTE_DRIVER_DASHBOARD,
                component: DriverDashboardComponent,
                canActivate: [AuthGuardService, DriverGuardService]
            },
            {
                path: ROUTE_PROFILE,
                component: ProfileOutletComponent,
                children: [...profileRoutes],
                canActivate: [AuthGuardService, DriverGuardService]
            },
            {
                path: ROUTE_NOTIFICATIONS,
                component: NotificationsComponent,
                canActivate: [AuthGuardService, DriverGuardService]
            },
            {
                path: ROUTE_ACCOUNT_SETTINGS,
                component: AccountSettingsComponent,
                canActivate: [AuthGuardService, DriverGuardService]
            },
            {
                path: ROUTE_TELL_A_FRIEND,
                component: TellFriendComponent,
                canActivate: [AuthGuardService, DriverGuardService]
            },
            {
                path: ROUTE_DRIVER_HISTORY,
                loadChildren: ɵ0,
                canActivate: [AuthGuardService, DriverGuardService]
            },
            {
                path: ROUTE_LOADS,
                loadChildren: ɵ1,
                canActivate: [AuthGuardService, DriverGuardService]
            }
        ],
        canActivate: [AuthGuardService, DriverGuardService]
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
