<div fxFlexAlign="center" fxLayout="column" fxLayoutAlign="center center">
  <img class="logo padding-top-x4" src="/assets/img/fleetoperate_delivery_white_logo.png" width="300px" />
  <h2 class="center">Sign Up</h2>
</div>
<div class="padding-top-x2">
  <mat-error *ngIf="errorMessage" fxFlexAlign="center" class="margin-bottom-sm">{{ errorMessage }}</mat-error>
  <mat-label *ngIf="successMessage" fxFlexAlign="center" class="margin-bottom-sm">{{ successMessage }}</mat-label>
  <form [formGroup]="form" #signupForm="ngForm" (ngSubmit)="onSubmit(signupForm)">
    <mat-form-field class="full-width">
      <input matInput formControlName="firstName" maxlength="20" />
      <mat-placeholder>First Name</mat-placeholder>
      <mat-error *ngIf="firstName.touched && firstName.invalid">
        {{ getFirstNameErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput formControlName="lastName" maxlength="20" />
      <mat-placeholder>Last Name</mat-placeholder>
      <mat-error *ngIf="lastName.touched && lastName.invalid">
        {{ getLastNameErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input type="email" matInput formControlName="email" />
      <mat-placeholder>Email</mat-placeholder>
      <mat-error *ngIf="email.touched && email.invalid">
        {{ getEmailErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-hint align="start">###-###-####</mat-hint>
      <input matInput formControlName="mobileNumber" />
      <mat-placeholder>Mobile Number</mat-placeholder>
      <mat-error *ngIf="mobileNumber.touched && mobileNumber.invalid">
        {{ getMobileNumberErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-hint align="start">at least 8 characters</mat-hint>
      <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" />
      <mat-placeholder>Password</mat-placeholder>
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
        hidePassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
      <mat-error *ngIf="password.touched && password.invalid">
        {{ getPasswordErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <div class="align-self-center padding-top-x2">
      By clicking Sign up, you agree to our
      <a [routerLink]="" (click)="onNavigateToTermsConditions()">Terms and Conditions</a> and
      <a [routerLink]="" (click)="onNavigateToPrivacyStatement()">Privacy Statement</a>.
    </div>
    <div class="full-width padding-top-x4">
      <button
        class="full-width margin-bottom"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="form.pristine || !form.valid || loading"
      >
        Sign up
      </button>
    </div>
  </form>
  <div class="center padding-top-md">
    Already have an account? <a [routerLink]="" (click)="onNavigateToSignIn()">Sign in</a>
  </div>
</div>
